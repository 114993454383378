import { string } from "yup";
import getBankSchemasBR from "./banks/BR";
import { translateKey } from "../../utils/translate";
import { buildCharactersMessage } from "../utils/messageBuilders";

const numberLengths = {
  CO: { min: 9, max: 17 },
  AR: { min: 22, max: 22 },
  BR: { min: 2, max: 12 },
  MX: { min: 18, max: 18 },
  CR: { min: 22, max: 22 },
};

const defaultNumberSchema = string()
  .ensure()
  .required("This field is required");

const numberSchemas = {
  CR: () =>
    string()
      .ensure()
      .matches(
        /CR([0-9]{20})/,
        translateKey("form.bankInfoForm.invalidNumber"),
      ),
  BR: schema =>
    schema.when("bankId", value => getBankSchemasBR(value).bankAccountNumber),
  default: (schema, country) => {
    const lengths = numberLengths[country];
    const { min, max } = lengths || {};
    if (!(min && max)) {
      return schema;
    }
    if (min === max) {
      return schema.length(max, buildCharactersMessage(max));
    }
    const message = buildCharactersMessage(`${min} o ${max}`);
    return schema.min(min, message).max(max, message);
  },
};

const getBankAccountNumberSchema = (
  country,
  baseSchema = defaultNumberSchema,
) => {
  const schema = baseSchema.clone();
  const builder = numberSchemas[country];
  return builder ? builder(schema) : numberSchemas.default(schema, country);
};

export { numberLengths as bankAccountNumberLengths };
export default getBankAccountNumberSchema;
