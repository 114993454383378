import * as defaultSchemas from "../default/schemas";
import { buildDigitsMessage } from "../../../../utils/messageBuilders";
import { translateKey } from "../../../../../utils/translate";

const ACCOUNT_LENGTH = { min: 7, max: 7 };
const AGENCY_LENGTH = 4;

const { max } = ACCOUNT_LENGTH;
const requiredMessage = translateKey("form.bankInfoForm.required");

export const bankAccountNumber = defaultSchemas.bankAccountNumber
  .clone()
  .required(requiredMessage)
  .max(max, buildDigitsMessage(max, "max"));

const bankAccountVerificationDigit = defaultSchemas
  .getAccountVerificationDigitSchema()
  .clone();

export const getAccountVerificationDigitSchema = () =>
  bankAccountVerificationDigit;

export const agency = defaultSchemas.agency
  .clone()
  .required(requiredMessage)
  .length(AGENCY_LENGTH, buildDigitsMessage(AGENCY_LENGTH));

export const agencyVerificationDigit = defaultSchemas.agencyVerificationDigit.clone();
