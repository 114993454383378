import requester from "../../requester";
import CustomHttpException, {
  CustomizedError,
} from "../../../utils/CustomHttpException";
import { OCR_ATTEMPTS_NATIONAL_ID } from "../nationalDocument/ocrNationalDocumentStatus";

const OCR_HOST_BR = "https://services.rappi.com.br";
const MAX_ATTEMPTS = 2;

export const OCR_ATTEMPTS_BANK_CERT = {
  /**
   * This flag means that the user tried to change the image or bank account number.
   */
  userChangedTheInfo: true,
  attempts: 0,
  isReachedTheMaxAttempts() {
    return this.attempts >= MAX_ATTEMPTS;
  },
  isValidToTryAgain() {
    return this.userChangedTheInfo && !this.isReachedTheMaxAttempts();
  },
  plus() {
    this.userChangedTheInfo = false;
    this.attempts += 1;
  },
};

export const onChangeIdentificationFile = () => {
  OCR_ATTEMPTS_NATIONAL_ID.userChangedTheInfo = true;
};

const updateOCRStatus = () => {
  OCR_ATTEMPTS_BANK_CERT.userChangedTheInfo = false;
  OCR_ATTEMPTS_BANK_CERT.plus();
};

export const resetOCRStatus = () => {
  OCR_ATTEMPTS_BANK_CERT.userChangedTheInfo = true;
  OCR_ATTEMPTS_BANK_CERT.attempts = 0;
};

const isValidBankAccountCertification = response =>
  response && response.data && response.data.bank_account_number === 1;

const throwBankCertFailedException = response => {
  throw new CustomHttpException({
    customError: CustomizedError.OCR_BANK_CERT_FAILED,
    responseBody: response,
  });
};

const bankCertFailedResponse = request => ({
  ocr_bank_cert_status: "FAILED",
  ocr_bank_cert_description: `The bank certification with bank account number: ${request.data.bank_account_number} is invalid`,
});

const bankCertOKResponse = request => ({
  ocr_bank_cert_status: "OK",
  ocr_bank_cert_description: `The bank certification with bank account number: ${request.data.bank_account_number} is valid`,
});

const handleBankCertOCRResponse = (request, response) => {
  updateOCRStatus();
  if (!isValidBankAccountCertification(response)) {
    if (!OCR_ATTEMPTS_BANK_CERT.isReachedTheMaxAttempts()) {
      throwBankCertFailedException(response);
    } else {
      return bankCertFailedResponse(request);
    }
  }
  resetOCRStatus();
  return bankCertOKResponse(request);
};

export const verifyBankAccountCertification = (body, tokenValue) =>
  requester()
    .post(
      `${OCR_HOST_BR}/api/al-ocr/bank-account-certification/process`,
      body,
      {
        headers: {
          token: tokenValue,
          ContentType: "application/json",
        },
      },
    )
    .then(response => handleBankCertOCRResponse(body, response));
