import baseStyleInjector from "../../../assets/styles/FormBase.style";

const stylizer = theme => {
  const styles = {
    ...baseStyleInjector(theme),
    info: {
      fontFamily: theme.typography.fontFamily.light,
      fontSize: "12px",
      padding: "0 24px 8px 24px",
      letterSpacing: 0.3,
    },
    combinedField: {
      display: "flex",
      "& > div:first-of-type": {
        width: 83,
        borderRight: 0,
        "&:first-of-type + div": {
          display: "none",
        },
      },
    },
  };

  styles.dayIndicator = {
    fontFamiliy: theme.typography.fontFamily.light,
    letterSpacing: 0.3,
    fontSize: 15,
    color: theme.palette.disabled.dark,
    marginTop: "15px",
  };

  styles.dayTip = {
    ...styles.dayIndicator,
    marginTop: 0,
    fontSize: 12,
  };

  styles.helpInfo = {
    ...styles.info,
    padding: 0,
    color: theme.palette.secondary.main,
    fontSize: 12,
  };

  return styles;
};

export default stylizer;
