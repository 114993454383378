import { object, string, number } from "yup";
import { translateKey } from "../../../utils/translate";
import { makeAdditionalSchema } from "../../../fieldsFactory";
import getNumberSchema from "../../../schemasAndConstraints/bankAccount/schemas";
import getIdSchema from "../../../schemasAndConstraints/ids/schemas";
import getPhoneSchema from "../../../schemasAndConstraints/phones/schemas";
import additionalFieldsSchemas from "../../../schemasAndConstraints/additionalFields";

const FIELD_BINDINGS = {
  ownerId: "ownerIdType",
  ownerTelephone: "phonePrefix",
};

const defaultSchema = {
  number: string()
    .required(translateKey("form.bankInfoForm.required"))
    .matches(/^[0-9]+$/g, translateKey("form.bankInfoForm.invalidNumber")),
  typeId: string().required(translateKey("form.bankInfoForm.required")),
  bankId: number().required(translateKey("form.bankInfoForm.required")),
  ownerId: string()
    .required(translateKey("form.bankInfoForm.required"))
    .ensure(),
  ownerIdType: string().required(translateKey("form.bankInfoForm.required")),
  ownerName: string().required(translateKey("form.bankInfoForm.required")),
  ownerLastName: string().required(translateKey("form.bankInfoForm.required")),
  ownerEmail: string()
    .email(translateKey("form.bankInfoForm.invalidEmail"))
    .required(translateKey("form.bankInfoForm.required")),
  ownerTelephone: string().required(translateKey("form.bankInfoForm.required")),
  fiscalAddress: string().required(translateKey("form.bankInfoForm.required")),
  billingAddress: string().required(translateKey("form.bankInfoForm.required")),
  phonePrefix: string().required(translateKey("form.bankInfoForm.required")),
};

const buildValidationSchema = (additionalFields, country) => {
  const {
    ownerId: ownerIdBinding,
    ownerTelephone: ownerPhoneBinding,
  } = FIELD_BINDINGS;

  const additionalFieldsSchema =
    additionalFieldsSchemas[country] ||
    object().shape({
      ...makeAdditionalSchema(additionalFields),
    });

  return object().shape({
    ...defaultSchema,
    additionalFields: additionalFieldsSchema,
    ownerId: getIdSchema(country, ownerIdBinding, defaultSchema.ownerId),
    number: getNumberSchema(country, defaultSchema.number),
    ownerTelephone: getPhoneSchema(
      country,
      ownerPhoneBinding,
      defaultSchema.ownerTelephone,
      true,
    ),
  });
};

export { FIELD_BINDINGS };
export default buildValidationSchema;
