import { translateKey } from "../../utils/translate";

const characterErrorsPath = "form.fieldErrors.characters";
const digitsErrorsPath = "form.fieldErrors.digits";

export const buildInvalidMessage = replacer =>
  translateKey("form.fieldErrors.xInvalid").replace(":name:", replacer);

export const buildCharactersMessage = (
  number,
  type = "default",
  { min, max } = {},
) => {
  if (type === "between") {
    return translateKey(`${characterErrorsPath}.between`)
      .replace(":min:", min)
      .replace(":max:", max);
  }

  return translateKey(`${characterErrorsPath}.${type}`).replace(
    ":number:",
    number,
  );
};

export const buildDigitsMessage = (
  number,
  type = "default",
  { min, max } = {},
) => {
  if (type === "between") {
    return translateKey(`${digitsErrorsPath}.between`)
      .replace(":min:", min)
      .replace(":max:", max);
  }

  return translateKey(`${digitsErrorsPath}.${type}`).replace(
    ":number:",
    number,
  );
};
