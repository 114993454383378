import { useEffect, useRef } from "react";
import { connect } from "formik";

const Effect = ({ formik, onChange }) => {
  const ref = useRef(null);

  useEffect(() => {
    onChange(formik, ref.current);
    ref.current = formik;
    // eslint-disable-next-line
  }, [formik]);

  return null;
};

export default connect(Effect);
