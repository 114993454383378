import React from "react";

const EMPTY_PROMISE = new Promise(resolve => resolve());

class Inbound {
  constructor(form, loadRequirements, title, key) {
    this.form = form;
    this.values = {};
    this.valid = false;
    this.title = title;
    this.key = key;
    this.loadRequirements = loadRequirements;
    this.requirements = null;
    this.restoredValues = null;
    this.submit = () => {};
  }

  getTitle = () => this.title;

  saveInLocalStorage = updater => {
    const values = { ...this.values, ...updater };
    window.localStorage.setItem(this.key, JSON.stringify(values));
  };

  restoreFromLocalStorage = () => {
    let restored;
    try {
      restored = JSON.parse(window.localStorage.getItem(this.key));
      return restored === Object(restored) &&
        Object.prototype.toString.call(restored) !== "[object Array]"
        ? restored
        : {};
    } catch {
      return {};
    }
  };

  load = () =>
    this.loadRequirements
      ? this.loadRequirements().then(requirements => {
          this.requirements = requirements;
        })
      : EMPTY_PROMISE;

  onFormChange = ({ values, isValid, submitForm }) => {
    this.values = values;
    this.valid = isValid;
    this.submit = submitForm;
  };

  render = (initialValues = {}, onSend, restore = true) => {
    const Form = this.form;
    this.restoredValues = this.restoredValues || this.restoreFromLocalStorage();
    const initValues = restore ? this.restoredValues : initialValues;

    return (
      <Form
        onSend={onSend}
        initialValues={initValues}
        onFormChange={form => {
          this.onFormChange(form);
        }}
        {...this.requirements}
      />
    );
  };

  isValid = () => this.valid;

  simulateSubmitForm = () => {
    try {
      this.submit();
    } catch (e) {
      // ERROR
    }
  };
}

export default Inbound;
