import { string } from "yup";
import {
  buildCharactersMessage,
  buildDigitsMessage,
  buildInvalidMessage,
} from "../../../../utils/messageBuilders";
import { translateKey } from "../../../../../utils/translate";

const ACCOUNT_DIGIT_LENGTH = 1;
const AGENCY_LENGTH = 4;
const AGENCY_DIGIT_LENGTH = 1;

const matchers = {
  bankAccountNumber: /^[0-9]{1,12}$/,
  bankAccountVerificationDigit: /^[a-zA-Z0-9]{1}$/,
  agency: /^(?!0000)([0-9]{4})$/,
  agencyVerificationDigit: /^[a-zA-Z0-9]{0,1}$/,
};

const invalidMessage = buildInvalidMessage("Campo");

export const bankAccountNumber = string()
  .ensure()
  .required(translateKey("form.bankInfoForm.required"))
  .matches(matchers.bankAccountNumber, invalidMessage);

const bankAccountVerificationDigit = string()
  .ensure()
  .length(ACCOUNT_DIGIT_LENGTH, buildCharactersMessage(ACCOUNT_DIGIT_LENGTH))
  .matches(matchers.bankAccountVerificationDigit, invalidMessage);

export const getAccountVerificationDigitSchema = () =>
  bankAccountVerificationDigit;

export const agency = string()
  .ensure()
  .length(AGENCY_LENGTH, buildDigitsMessage(AGENCY_LENGTH))
  .matches(matchers.agency, invalidMessage);

export const agencyVerificationDigit = string()
  .ensure()
  .length(AGENCY_DIGIT_LENGTH, buildCharactersMessage(AGENCY_DIGIT_LENGTH))
  .matches(matchers.agencyVerificationDigit, invalidMessage);
