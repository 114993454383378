export const personTypes = {
  CO: [
    { label: "Persona natural", value: "persona natural" },
    { label: "Persona jurídica", value: "persona juridica" },
  ],
  BR: [{ label: "Pessoa jurídica", value: "pessoa juridica" }],
  MX: [
    { label: "Persona física", value: "persona fisica" },
    { label: "Persona moral", value: "persona moral" },
  ],
  AR: [
    { label: "Persona humana", value: "persona humana" },
    { label: "Persona jurídica", value: "persona juridica" },
  ],
  PE: [
    { label: "Persona natural", value: "persona natural" },
    { label: "Persona jurídica", value: "persona juridica" },
  ],
  UY: [
    { label: "Persona física", value: "persona fisica" },
    { label: "Persona jurídica", value: "persona juridica" },
  ],
  CL: [
    { label: "Persona natural", value: "persona natural" },
    { label: "Persona jurídica", value: "persona juridica" },
  ],
  EC: ["Persona natural", "Persona jurídica"],
  CR: [
    { label: "Persona natural", value: "persona natural" },
    { label: "Persona jurídica", value: "persona juridica" },
  ],
};

export const personTypeValues = {
  juridic: ["persona juridica", "persona moral", "pessoa juridica"],
};

export const tributaryTypes = {
  CO: [
    { label: "CC", value: "cc" },
    { label: "NIT", value: "nit" },
  ],
  BR: [{ label: "CNPJ", value: "cnpj" }],
  MX: [{ label: "RFC", value: "rfc" }],
  AR: [
    { label: "CUIT", value: "cuit" },
    { label: "CUIL", value: "cuil" },
  ],
  PE: [{ label: "RUC", value: "ruc" }],
  UY: [{ label: "RUT", value: "rut" }],
  CL: [{ label: "RUT", value: "rut" }],
  EC: [{ label: "RUC", value: "ruc" }],
  CR: [
    { label: "Cédula Física", value: "cedula fisica" },
    { label: "Cédula Jurídica", value: "cedula juridica" },
    { label: "DIMEX", value: "dimex" },
  ],
};

export const civilTypes = {
  CO: [
    { label: "C.C", value: "cc" },
    { label: "C.E", value: "ce" },
    { label: "NIT", value: "nit" },
  ],
  BR: [{ label: "CPF", value: "cpf" }],
  MX: [
    { label: "IFE/INE", value: "ife/ine" },
    { label: "Pasaporte", value: "ps" },
  ],
  AR: [
    { label: "DNI", value: "dni" },
    { label: "DNI Extranjero", value: "dni extranjero" },
    { label: "Pasaporte", value: "ps" },
  ],
  PE: [
    { label: "DNI", value: "dni" },
    { label: "CE", value: "ce" },
    { label: "Pasaporte", value: "ps" },
    { label: "PTP", value: "ptp" },
  ],
  UY: [
    { label: "CI", value: "ci" },
    { label: "Pasaporte", value: "ps" },
  ],
  CL: [
    { label: "Cédula", value: "ci" },
    { label: "Pasaporte", value: "ps" },
  ],
  EC: [
    { label: "Cédula", value: "cedula" },
    { label: "Pasaporte", value: "ps" },
  ],
  CR: [
    { label: "Cédula Física", value: "cedula fisica" },
    { label: "Cédula Jurídica", value: "cedula juridica" },
    { label: "DIMEX", value: "dimex" },
  ],
};

export const businessTypes = {
  CO: [
    "Regimen común",
    "Regimen simplificado",
    "Gran contribuyente",
    "Autorretenedor",
    "Regimen comun + Gran contribuyente",
    "Regimen comun + Autoretenedor",
    "Regimen comun + Autoretenedor + Gran contibuyente",
    "Gran contribuyente + Autoretenedor",
  ],
  AR: ["Acumulacion de ingresos"],
  UY: ["Monotributo", "Pequeña Empresa", "Regimen General"],
  PE: [
    "Nuevo regimen unico simplificado (NRUS)",
    "Regimen especial de impuesto a la renta (RER)",
    "Regimen General (RG)",
    "Regimen MYPE tributario (RMT)",
  ],
};
