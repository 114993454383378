/* eslint-disable camelcase */
import React, { Component } from "react";
import PropTypes from "prop-types";
import TagManager from "react-gtm-module";
import { isEqual } from "lodash";
import qs from "query-string";
import Inbound from "./Inbound";
import InboundPage from "../../components/pages/NewInbound";
import InboundLoadingModal from "../../components/molecules/InboundLoadingModal/InboundLoadingModal";
import Modal from "../../components/pages/NewInbound/PageComponents/Modal/Modal";
import GreenCheck from "../../assets/images/InboundPage/check-material.svg";
import { save } from "../../api/surveys";
import { translateKey } from "../../utils/translate";
import notifyByError from "../../utils/notifyByError";
import logEvent from "../../utils/EventLogger";
import config from "../../config";
import retryRequest from "../../utils/retryRequest";
import requestLink from "../../api/link";
import sendInboundDataLayer from "./sendInboundDataLayer";

class InboundForm extends Component {
  state = {
    country: "CO",
    loader: false,
    showConfirmationModal: false,
    requestProgress: 0,
    showLoadingModal: false,
    link: "",
  };

  retryRequestConfig = {
    retries: 5,
    retryInterval: 45000,
    timeout: 226500,
    timeoutTolerance: 3000,
    progressInterval: 2000,
  };

  componentWillMount() {
    window.location.href = "https://partners.rappi.com/signup";
  }

  componentDidMount() {
    const { loader, form, notifier } = this.props;
    loader.load();
    TagManager.initialize({ gtmId: config.GOOGLE.GTM_ID });
    form
      .load()
      .then(() => {
        this.setState({ loader: true });
        sendInboundDataLayer("SESSION_STARTED");
      })
      .catch(notifyByError(notifier, -1))
      .finally(loader.stop);
  }

  showConfirmationModal = () => this.setState({ showConfirmationModal: true });

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
    window.location.reload();
  };

  showLoadingModal = () => this.setState({ showLoadingModal: true });

  updateProgress = progress => this.setState({ requestProgress: progress });

  compareStoredFormValues = () => {
    const { form } = this.props;
    const { data: current } = form.values;
    const { data: stored } = form.restoreFromLocalStorage() || {};
    current.phone = current.phone.replace(current.phone_prefix, "");

    if (Object.keys(stored || {}).length === 0) {
      return false;
    }

    return isEqual(current, stored);
  };

  getQueryFields = search => {
    // IF THIS EXPLODES IT DOESNT MATTER!
    try {
      return qs.parse(search);
    } catch (e) {
      return {};
    }
  };

  onVideoEnd = () => {
    const { link } = this.state;
    if (link) {
      logEvent("INBOUND_REDIRECT_SUCCESS");
      window.location.href = link;
    }
  };

  onSend = () => {
    const { form, notifier, loader, location } = this.props;
    const { values } = form;
    const { phone } = values.data;
    const queryFields = this.getQueryFields(location.search);

    values.date = new Date().toISOString();
    values.city = values.data.zone;
    values.data = { ...queryFields, ...values.data };
    values.data.phone = values.data.phone_prefix + values.data.phone;

    if (form.isValid()) {
      const storedLink = window.localStorage.getItem("self_onboarding_link");

      if (this.compareStoredFormValues() && storedLink) {
        window.location.href = storedLink;
        return;
      }

      loader.load();
      sendInboundDataLayer("FORM_SUBMITTED");
      save(values, values.country)
        .then(() => {
          const { data, country } = values;
          const { name, email, address } = data;
          const restaurantBasicData = { country, name, email, address };

          logEvent("INBOUND_INFO_SUBMITTED");

          TagManager.dataLayer({
            dataLayer: {
              email,
              phone,
              restaurant_name: name,
            },
          });

          this.setState({
            showLoadingModal: true,
            country: values.country,
          });

          retryRequest(
            () => requestLink(values.data),
            this.updateProgress,
            this.retryRequestConfig,
            [60, 70],
          )
            .then(link => {
              form.saveInLocalStorage({ data: { ...values.data, phone } });
              window.localStorage.setItem("self_onboarding_link", link);
              this.setState({ link });
            })
            .catch(() => {
              logEvent("INBOUND_REDIRECT_FAILED", restaurantBasicData);
              this.setState({
                showLoadingModal: false,
                showConfirmationModal: true,
              });
            });
        })
        .catch(() => {
          notifier.error(translateKey("errors.http.unexpectedError"));
        })
        .finally(() => {
          loader.stop();
        });
    } else {
      notifier.error(translateKey("form.errors.invalidForm"));
    }
  };

  render() {
    const { form } = this.props;
    const {
      country,
      loader,
      showConfirmationModal,
      showLoadingModal,
      requestProgress,
      link,
    } = this.state;

    return (
      <>
        <InboundPage
          form={loader ? form : null}
          onSend={this.onSend}
          loaded={loader}
        />
        <Modal
          open={showConfirmationModal}
          imageWrapper="confirm"
          image={GreenCheck}
          title={translateKey("form.inbound.modal.title")}
          subtitle={translateKey("form.inbound.modal.subtitle")}
          buttonText={translateKey("form.inbound.modal.buttonText")}
          onButtonClick={this.hideConfirmationModal}
        />
        <InboundLoadingModal
          open={showLoadingModal}
          country={country}
          value={requestProgress}
          link={link}
          onVideoEnd={this.onVideoEnd}
        />
      </>
    );
  }
}

InboundForm.defaultProps = {
  form: null,
};

InboundForm.propTypes = {
  loader: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  notifier: PropTypes.object.isRequired,
  form: (props, propName, component) => {
    const { form } = props;
    if (!form || !(form instanceof Inbound)) {
      const type = typeof props[propName];
      return new Error(
        `Invalid prop ${propName} supplied to ${component}.
            Expected an instance of \`Inbound\` but received ${type}. Validation failed.`,
      );
    }
    return null;
  },
};

export default InboundForm;
