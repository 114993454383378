import { makeStyles } from "@material-ui/core";
import { fontSizes } from "../../InboundPage.style";
import baseStyleInjector from "../../../../../assets/styles/FormBase.style";

const useStyles = makeStyles(
  theme => ({
    marginBottom_12: {
      marginBottom: 12,
    },
    halfGrid: {
      [theme.breakpoints.down("lg")]: {
        height: "auto",
      },
    },
    heroContent: {
      position: "relative",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        height: 500,
        maxHeight: 500,
        overflow: "hidden",
      },
      [theme.breakpoints.down("xs")]: {
        height: 375,
        maxHeight: 375,
      },
    },
    heroImage: {
      width: "100%",
      display: "block",
      filter: `
      brightness(0.75) 
      saturate(1.5)
      contrast(3)
    `,
      [theme.breakpoints.between("sm", 1680)]: {
        width: "auto",
        height: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "auto",
        position: "absolute",
        bottom: 0,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    heroText: {
      fontSize: 70,
      height: "100%",
      backgroundColor: "rgba(0,0,0,0.6)",
      color: "white",
      padding: "25% 10%",
      position: "absolute",
      top: 0,
      [theme.breakpoints.down("lg")]: {
        fontSize: 60,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 50,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 50,
        padding: "12.5%",
      },
      [theme.breakpoints.down("xs")]: {
        ...fontSizes.sub,
        padding: "10%",
      },
    },
    header: {
      fontFamily: theme.typography.fontFamily.bold,
    },
    onboardingTitle: {
      fontSize: "1em",
      marginBottom: 10,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 25,
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: 0,
      },
    },
    onboardingSubtitle: {
      fontSize: "0.75em",
      color: theme.palette.primary.main,
      marginBottom: 75,
    },
    onboardingCaption: {
      color: "white !important",
      fontSize: "0.5em",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    formHeader: {
      ...fontSizes.sub,
      color: theme.typography.palette.main,
      marginBottom: 28,
      [theme.breakpoints.down("md")]: {
        ...fontSizes.sub4,
      },
    },
    formWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "content-box",
      padding: "30px 0",
    },
    form: {
      ...baseStyleInjector(theme).formWrapper,
      margin: "0 auto",
      [theme.breakpoints.down("sm")]: {
        padding: "30px 0 0 0",
        maxWidth: "90%",
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 460,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 600,
      },
    },
    termsAndConditions: {
      ...fontSizes.detail,
      display: "block",
      color: theme.typography.palette.content,
      marginBottom: 20,
      "& a": {
        color: theme.palette.primary.main,
      },
    },
    animationContainer: {
      width: 200,
    },
  }),
  { name: "headerAndForm", index: 1 },
);

export default useStyles;
