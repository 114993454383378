import React from "react";
import { Typography } from "@material-ui/core/";
import PropTypes from "prop-types";
import classNames from "classnames";
import brandImages from "./BrandsBanner.constants";
import { translateKeyWithHtml } from "../../../../../utils/translate";

import useStyles from "./BrandsBanner.style";

const Steps = ({ pageClasses }) => {
  const classes = useStyles();

  return (
    <>
      <div className={pageClasses.bounded}>
        <Typography
          variant="h3"
          align="center"
          className={classNames(
            pageClasses.sub2,
            pageClasses.headerBottomMargin,
            pageClasses.strongMain,
          )}
        >
          {translateKeyWithHtml("inboundPage.restaurantsBanner.title")}
        </Typography>
        <Typography variant="h6" align="center" className={classes.subtitle}>
          {translateKeyWithHtml("inboundPage.restaurantsBanner.subtitle")}
          {"."}
        </Typography>
      </div>
      <div className={classes.gradientSides}>
        <div className={classes.brandImageWrapper}>
          {brandImages.map(({ id, imgSrc, imgAlt }) => (
            <div key={id} className={classes.brandImageContainer}>
              <img src={imgSrc} alt={imgAlt} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

Steps.propTypes = {
  pageClasses: PropTypes.object.isRequired,
};

export default Steps;
