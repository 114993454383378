import React from "react";
import SurveyForm from "./SurveyForm";
import withLoader from "../../hocs/loader/withLoader";
import withNotifier from "../../hocs/notifications/withNotifier";
import Inbound from "./Inbound";
import NewLeadForm, {
  loadRequirements,
} from "../../components/organisms/NewLeadForm";

const form = new Inbound(NewLeadForm, loadRequirements, "form.titles.newLead");

const ConfiguredComponent = props => <SurveyForm {...props} form={form} />;

export default withNotifier(withLoader(ConfiguredComponent));
