import React from "react";
import { isValidElementType } from "react-is";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import * as PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "../../atoms/TextField";
import { translateKey } from "../../../utils/translate";
import placeMapper from "./PlaceField.mapper";

class PlaceField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.preservedValue,
      error: false,
    };
  }

  handleChange = value => {
    this.setState({ address: value, error: false });
    if (
      this.props.editable &&
      !this.props.onlyAddresses &&
      typeof value === "string"
    ) {
      this.props.onChange({ name: value });
      return;
    }
    if (typeof value === "object") {
      this.props.onChange(value);
    }
  };

  handleSelect = address => {
    Promise.all([geocodeByAddress(address), this.findPlaceName(address)])
      .then(result => {
        const placeInfoMapped = placeMapper(
          this.props.country,
          result[0][0].address_components,
        );
        const [, r] = result;
        const payload = {
          ...placeInfoMapped,
          fullAddress: r.formatted_address,
          lat: r.geometry.location.lat(),
          lng: r.geometry.location.lng(),
          country: this.props.country,
          type: r.types.join(", "),
        };
        if (!this.props.onlyAddresses) {
          payload.placeId = r.place_id;
          payload.name = r.name;
          payload.avg = r.rating;
          payload.reviews = r.user_ratings_total;
          payload.priceLevel = r.price_level;
        }
        this.handleChange(payload);
      })
      .catch(this.handleError);
  };

  handleError = err => {
    if (err !== "ZERO_RESULTS") this.setState({ error: true });
  };

  findPlaceName = address =>
    new Promise((resolve, reject) => {
      const { google } = this.props;
      const map = new google.maps.Map(document.getElementById("fake-map"));
      const service = new google.maps.places.PlacesService(map);
      const request = {
        query: address,
        fields: [
          "name",
          "geometry",
          "types",
          "place_id",
          "rating",
          "user_ratings_total",
          "price_level",
          "formatted_address",
        ],
      };
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(results[0]);
        } else {
          reject();
        }
      });
    });

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ preservedValue }) {
    const { address } = this.state;
    if (address !== preservedValue) {
      this.setState({ address: preservedValue });
    }
  }

  render() {
    const {
      name,
      classes,
      onChange,
      country,
      onlyAddresses,
      textFieldComponent: TextField,
      ...props
    } = this.props;
    const { error, address } = this.state;
    const types = onlyAddresses ? ["address"] : ["establishment", "geocode"];
    return (
      <PlacesAutocomplete
        highlightFirstSuggestion
        value={address.fullAddress || address}
        searchOptions={{
          fields: "placeId, address_component, address, location, title",
          componentRestrictions: {
            country,
          },
          types,
        }}
        debounce={500}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onError={this.handleError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <TextField
              inputProps={{ ...getInputProps(props), autoComplete: "off" }}
              name={name}
              {...props}
            />
            {error && (
              <div className={classes.error}>
                {translateKey("google.places.error")}
              </div>
            )}
            {!error && (
              <Paper className={classes.container}>
                {loading && (
                  <Grid container justify="center">
                    <CircularProgress
                      color="secondary"
                      className={classes.loader}
                    />
                  </Grid>
                )}
                {suggestions.map(suggestion => (
                  <MenuItem
                    {...getSuggestionItemProps(suggestion, {})}
                    key={suggestion.placeId}
                    className={classes.item}
                  >
                    {suggestion.description}
                  </MenuItem>
                ))}
              </Paper>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

PlaceField.propTypes = {
  classes: PropTypes.object.isRequired,
  textFieldComponent: props => {
    const { textFieldComponent: value } = props;
    if (!value || !isValidElementType(value)) {
      return new Error(
        `Invalid prop 'textFieldComponent' supplied to 'PlaceField': the prop is not a valid React component`,
      );
    }
    return null;
  },
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  google: PropTypes.object.isRequired,
  preservedValue: PropTypes.string,
  country: PropTypes.string,
  onlyAddresses: PropTypes.bool,
  editable: PropTypes.bool,
};

PlaceField.defaultProps = {
  textFieldComponent: TextField,
  preservedValue: "",
  country: null,
  onlyAddresses: false,
  editable: false,
};

export default PlaceField;
