/* eslint camelcase: 0 */
import React, { useMemo as memo } from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import {
  fileConfiguration,
  fileUploadNames,
  idFileTypes,
  juridicSocialReasonFileTypes,
  legalAgentPowerFileType,
  personTypesToJuridicFile,
  tributaryFileTypes,
  bankCertificationFileTypes,
} from "./AttachFilesForm.constants";
import AttachFilesForm from "./AttachFilesForm";
import withNotifier from "../../../hocs/notifications/withNotifier";
import validationSchema from "./AttachFilesSchema";
import runImageChecks from "../../../utils/runFileChecks";
import { uploadFileRequest } from "../../../api/steps";
import { mergeWithDefault } from "../../../utils/functions";
import { translateKey } from "../../../utils/translate";
import logEvent from "../../../utils/EventLogger";

const fileUploaderCreator = (
  token,
  fieldName,
  fieldUploadName,
  setFieldValue,
  notifier,
  checks = ["blurryness"],
) => async (evt, fileInputManager) => {
  const { file } = evt;

  if (file == null) {
    setFieldValue(fieldName, null);
    return;
  }

  const checksLen = checks.length;
  const noOfChecks = checksLen > 0 ? checksLen + 1 : 1;
  const pctOfChecks = checksLen > 0 ? (100 / noOfChecks) * checks.length : 0;
  const data = new window.FormData();
  data.append("file", file);

  const onUploadProgress = progressEvent => {
    const percentCompleted = Math.round(
      pctOfChecks +
        (progressEvent.loaded * 100) / progressEvent.total / noOfChecks,
    );
    fileInputManager.updateProgress(percentCompleted);
  };

  const onSuccess = url => {
    fileInputManager.updateProgress(100);
    setFieldValue(fieldName, url);
  };

  const onFail = () => {
    fileInputManager.removeFile();
    setFieldValue(fieldName, null);
  };

  try {
    const results = await runImageChecks(
      file,
      checks,
      fileInputManager.updateProgress,
    );

    const unapproved = results.find(({ status }) => status !== "APPROVED");
    if (unapproved) {
      onFail();
      const name = translateKey(
        `form.attachFilesForm.fileChecks.${unapproved.name}`,
      );
      const messageError = translateKey("form.attachFilesForm.rejectedFile")
        .replace("':filename'", file.name)
        .replace("':checks'", name);
      notifier.error(messageError);
      logEvent("INVALID_IMAGE", { reason: "BLURRY" });
      return;
    }
  } catch (error) {
    onFail();
    notifier.error(translateKey("errors.http.unexpectedError"));
    return;
  }

  await uploadFileRequest(
    fieldUploadName
      .replace("additionalFields.", "")
      .replace("certification_br", "certification"),
    data,
    token,
    { onUploadProgress, onSuccess, onFail },
  );
};

function buildUploaders(
  token,
  { fileDistribution, fileUploadNames, additionalFields },
  setFieldValue,
  notifier,
) {
  const additionalDistribution = Object.fromEntries(
    additionalFields.map(({ name }) => [`additionalFields.${name}`, name]),
  );
  return Object.keys({ ...fileDistribution, ...additionalDistribution }).reduce(
    (acum, key) => ({
      ...acum,
      [key]: fileUploaderCreator(
        token,
        key,
        fileUploadNames[key] || key,
        setFieldValue,
        notifier,
      ),
    }),
    {},
  );
}

export const loadRequirements = async (
  {
    social_reason: {
      legal_agent: { person_type },
    },
  },
  { countryCode, token, steps },
) => {
  const country = countryCode || "CO";
  const fileDistribution = {
    ...(fileConfiguration[country] || fileConfiguration.default),
    juridicSocialReason:
      personTypesToJuridicFile.includes(person_type.toLowerCase()) &&
      juridicSocialReasonFileTypes[country],
    legalAgentPower:
      personTypesToJuridicFile.includes(person_type.toLowerCase()) &&
      legalAgentPowerFileType[country],
  };
  const additionalFields = steps[3] || [];
  const schema = validationSchema(fileDistribution, additionalFields);

  return {
    idFileType: idFileTypes[country],
    tributaryFileType: tributaryFileTypes[country],
    juridicSocialReasonFileType: juridicSocialReasonFileTypes[country],
    legalAgentPowerFileType: legalAgentPowerFileType[country],
    bankCertificationFileType:
      bankCertificationFileTypes[country] || bankCertificationFileTypes.default,
    fileUploadNames,
    fileDistribution,
    token,
    schema,
    additionalFields,
  };
};

const defaultValues = {
  bankCertification: null,
  socialReason: null,
  alreadyOnDelivery: null,
  menuUrl: null,
  identification: null,
  legalAgentPower: null,
  additionalFields: {},
};

const defaultValuesByCountry = {
  BR: {
    bankCertification: "http://the-file-does-not-apply.com/unknow-file.pdf",
    socialReason: "http://the-file-does-not-apply.com/unknow-file.pdf",
    identification: null,
    legalAgentPower: "http://the-file-does-not-apply.com/unknow-file.pdf",
    juridicSocialReason: "http://the-file-does-not-apply.com/unknow-file.pdf",
    rappiMenu: "http://the-file-does-not-apply.com/rappi-menu.pdf",
    additionalFields: {},
  },
  get(country) {
    if (this[country]) {
      return this[country];
    }
    return null;
  },
};

const createInitValuesByCountry = (initialValues, defaultValues, country) => {
  const values = isEmpty(initialValues) ? defaultValues : initialValues;
  const valuesByCountry = defaultValuesByCountry.get(country);

  if (valuesByCountry) {
    if (!values.bankCertification) {
      values.bankCertification = valuesByCountry.bankCertification;
    }

    if (!values.socialReason) {
      values.socialReason = valuesByCountry.socialReason;
    }

    if (!values.legalAgentPower) {
      values.legalAgentPower = valuesByCountry.legalAgentPower;
    }

    if (!values.juridicSocialReason) {
      values.juridicSocialReason = valuesByCountry.juridicSocialReason;
    }

    if (!values.rappiMenu) {
      values.rappiMenu = valuesByCountry.rappiMenu;
    }
  }
  return values;
};

export const getInitialValues = ({
  bank_certification_url = null,
  social_reason = {},
  catalog_info = {},
  additional_data = {},
  rappi_menu = null, // TODO: check what initial value corresponds to the rappi_menu
}) => {
  const initialValues = {
    bankCertification: bank_certification_url,
    socialReason: social_reason.file,
    juridicSocialReason: social_reason.juridic_file,
    alreadyOnDelivery: !!catalog_info.menu_url,
    menuUrl: catalog_info.menu_url,
    rappiMenu: rappi_menu,
    identification: social_reason.legal_agent
      ? social_reason.legal_agent.identification_file
      : null,
    legalAgentPower: social_reason.legal_agent
      ? social_reason.legal_agent.power_file
      : null,
    additionalFields: {
      ...additional_data,
    },
  };
  return mergeWithDefault(initialValues, defaultValues);
};

const ConfiguredForm = ({
  userInfo = {},
  onFormChange,
  idFileType,
  tributaryFileType,
  juridicSocialReasonFileType,
  legalAgentPowerFileType,
  bankCertificationFileType,
  fileDistribution = fileConfiguration.default,
  fileUploadNames = {},
  token,
  additionalFields,
  schema,
  notifier,
  setStepAdditionalData,
}) => {
  let initialValues = getInitialValues(userInfo);
  initialValues = createInitValuesByCountry(
    initialValues,
    defaultValues,
    userInfo.country,
  );

  const uploadersConfig = {
    fileDistribution,
    fileUploadNames,
    additionalFields,
  };
  const onFileError = (file, message) => {
    const errorMessage =
      message ||
      translateKey("form.attachFilesForm.invalidFile").replace(
        ":filename",
        file.name,
      );
    notifier.error(errorMessage);
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={() => {}}
    >
      {({ setFieldValue, ...props }) => {
        onFormChange(props);
        const formProps = { setFieldValue, ...props };
        const uploaderArgs = [token, uploadersConfig, setFieldValue, notifier];
        const uploaders = memo(() => buildUploaders(...uploaderArgs), [
          ...uploaderArgs,
        ]);

        return (
          <AttachFilesForm
            {...formProps}
            userInfo={userInfo}
            uploaders={uploaders}
            setStepAdditionalData={setStepAdditionalData}
            idFileType={idFileType}
            tributaryFileType={tributaryFileType}
            juridicSocialReasonFileType={juridicSocialReasonFileType}
            legalAgentPowerFileType={legalAgentPowerFileType}
            bankCertificationFileType={bankCertificationFileType}
            fileDistribution={fileDistribution}
            additionalFields={additionalFields}
            onFileError={onFileError}
          />
        );
      }}
    </Formik>
  );
};

ConfiguredForm.defaultProps = {
  userInfo: {},
  additionalFields: [],
  legalAgentPowerFileType: {},
  juridicSocialReasonFileType: {},
  bankCertificationFileType: {},
  idFileType: {},
};

ConfiguredForm.propTypes = {
  userInfo: PropTypes.object,
  onFormChange: PropTypes.func.isRequired,
  idFileType: PropTypes.object,
  tributaryFileType: PropTypes.object.isRequired,
  juridicSocialReasonFileType: PropTypes.object,
  legalAgentPowerFileType: PropTypes.object,
  bankCertificationFileType: PropTypes.object,
  fileDistribution: PropTypes.object.isRequired,
  fileUploadNames: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  additionalFields: PropTypes.array,
  schema: PropTypes.object.isRequired,
  notifier: PropTypes.object.isRequired,
  setStepAdditionalData: PropTypes.func.isRequired,
};

export default withNotifier(ConfiguredForm);
