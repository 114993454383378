import React from "react";
import { Typography, Grid, useMediaQuery } from "@material-ui/core/";
import PropTypes from "prop-types";
import classNames from "classnames";
import { GridFull } from "../../../../../utils/Grids";
import { translateKey } from "../../../../../utils/translate";
import { categories, interestingInfo } from "./Footer.constants";
import GooglePlay from "../../../../../assets/images/InboundPage/google-play.svg";
import AppStore from "../../../../../assets/images/InboundPage/apple-app-store.svg";
import FacebookIcon from "../../../../../assets/images/InboundPage/facebook.svg";
import InstagramIcon from "../../../../../assets/images/InboundPage/instagram.svg";
import TwitterIcon from "../../../../../assets/images/InboundPage/twitter.svg";
import useStyles from "./Footer.style";

const Footer = ({ pageClasses }) => {
  const classes = useStyles();
  const isBelowSm = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const InterestingInfoColumn = (
    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
      <div className={classes.footerColumn}>
        <Typography variant="h5" className={classes.footerSubtitle}>
          {translateKey("inboundPage.footer.interestingInfo.title")}
        </Typography>
        <Grid container>
          {(isBelowSm
            ? interestingInfo
            : [interestingInfo[6], interestingInfo[7]]
          ).map(({ id, text, url }) => (
            <GridFull item xs={6} className={classes.footerLink} key={id}>
              <a className={classes.footerLink} href={url}>
                {text}
              </a>
            </GridFull>
          ))}
        </Grid>
      </div>
    </Grid>
  );

  const CategoriesColumn = (
    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
      <div className={classes.footerColumn}>
        <Typography variant="h5" className={classes.footerSubtitle}>
          {translateKey("inboundPage.footer.categories.title")}
        </Typography>
        <Grid container>
          {categories.map(({ id, text, url }) => (
            <GridFull item xs={6} className={classes.footerLink} key={id}>
              <a className={classes.footerLink} href={url}>
                {text}
              </a>
            </GridFull>
          ))}
        </Grid>
      </div>
    </Grid>
  );

  const DownloadAndContactColumn = (
    <Grid item container xs={12} sm={4} md={6} lg={8} xl={8}>
      <Grid item xs={6} sm={12} md={6} lg={6} xl={6}>
        <div className={classes.footerColumn}>
          <Typography variant="h5" className={classes.footerSubtitle}>
            {translateKey("inboundPage.footer.downloadApp.title")}
          </Typography>
          <Grid container className={classes.downloadLinks}>
            <a href="https://itunes.apple.com/co/app/rappi/id984044296?mt=8">
              <img src={AppStore} alt="Apple App Store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.grability.rappi">
              <img src={GooglePlay} alt="Google Play" />
            </a>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={6} sm={12} md={6} lg={6} xl={6}>
        <div className={classes.footerColumn}>
          <Typography variant="h5" className={classes.footerSubtitle}>
            {translateKey("inboundPage.footer.contactUs.title")}
          </Typography>
          <Grid container className={classes.socialMediaLinks}>
            <a href="https://www.facebook.com/RappiColombia/">
              <img src={FacebookIcon} alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/rappicolombia/?hl=es">
              <img src={InstagramIcon} alt="Instagram" />
            </a>
            <a href="https://twitter.com/rappicolombia?lang=es">
              <img src={TwitterIcon} alt="Twitter" />
            </a>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );

  const CopyrightBottom = (
    <GridFull item container className={classes.copyRightBottom}>
      <div className={classNames(pageClasses.dim, pageClasses.fullWidth)}>
        {isBelowSm ? (
          <strong>
            {translateKey("inboundPage.footer.copyright.copyright")}
            {"."}
          </strong>
        ) : (
          <>
            <Typography variant="caption" className={classes.left}>
              {translateKey("inboundPage.footer.copyright.copyright")}
              {". "}
              {translateKey("inboundPage.footer.copyright.allRightsReserved")}
              {"."}
            </Typography>
          </>
        )}
      </div>
    </GridFull>
  );

  return (
    <Grid container component="footer">
      {isBelowSm ? CategoriesColumn : null}
      {InterestingInfoColumn}
      {DownloadAndContactColumn}
      {CopyrightBottom}
    </Grid>
  );
};

Footer.propTypes = {
  pageClasses: PropTypes.object.isRequired,
};

export default Footer;
