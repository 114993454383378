import { boolean, string, mixed, object } from "yup";

import { translateKey } from "../../../utils/translate";
import { makeAdditionalSchema } from "../../../fieldsFactory";

const buildSchema = (fileDistribution, additionalFields) => {
  const shape = Object.keys(fileDistribution).reduce(
    (shape, key) =>
      fileDistribution[key]
        ? {
            ...shape,
            [key]: string().required(
              translateKey("form.attachFilesForm.required"),
            ),
          }
        : { ...shape, [key]: mixed() },
    {},
  );

  return object().shape({
    ...shape,
    identificationBack: string().notRequired(),
    additionalFields: object().shape({
      ...makeAdditionalSchema(additionalFields),
    }),
    alreadyOnDelivery: boolean().required(),
    menuUrl: string()
      .optional()
      .nullable(),
  });
};

export default buildSchema;
