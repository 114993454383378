/* eslint-disable camelcase */
// TODO: change validators' messages
// CO
const nitValidationSequence = [41, 37, 29, 23, 19, 17, 13, 7, 3];
export const validateNit = nit => {
  if (nit.length !== 10) {
    return false;
  }

  const nitSequence = [...nit].map(n => parseInt(n, 10));
  const [verifier] = nitSequence.slice(-1);
  let verificator = nitSequence
    .slice(0, -1)
    .reduce(
      (acc, digit, index) => acc + digit * nitValidationSequence[index],
      0,
    );

  verificator %= 11;

  if (verificator >= 2) {
    verificator = 11 - verificator;
  }

  return verificator === verifier;
};

// MX
export const rfcRegexFormatter = /^([A-ZÑ\x26]?[A-ZÑ\x26]?[A-ZÑ\x26]?[A-ZÑ\x26]?)?(([0-9]?[0-9]?)?(0[1-9]?|1[0-2]?)?(0[1-9]?|1[0-9]?|2[0-9]?|3[0-1]?)?)([A-Z\d]?[A-Z\d]?[A-Z\d]?)?$/;
export const validateRfc = rfc =>
  /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})$/.test(
    rfc,
  );

// BR
export const validateCpf = inputCpf => {
  const cpf = inputCpf.replace(/[^\d]+/g, "");
  let sum = 0;
  let rest;

  if (cpf.length !== 11 || cpf === "00000000000") return false;

  const cpfSquence = [...cpf].map(n => parseInt(n, 10));
  for (let i = 1; i <= 9; i += 1) {
    sum += cpfSquence[i - 1] * (11 - i);
  }
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== cpfSquence[9]) return false;

  sum = 0;
  for (let i = 1; i <= 10; i += 1) {
    sum += cpf[i - 1] * (12 - i);
  }
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== cpfSquence[10]) return false;

  return true;
};

export const validateCnpj = inputCnpj => {
  const cnpj = inputCnpj.replace(/[^\d]+/g, "");

  if (cnpj.length !== 14) return false;

  const cnpjSequence = [...cnpj].map(n => parseInt(n, 10));
  let result;
  let sum = 0;
  let length = cnpj.length - 2;
  let pos = length - 7;
  let numbers = cnpjSequence.slice(0, length);
  const digits = cnpjSequence.slice(length);

  for (let i = length; i >= 1; i -= 1) {
    sum += numbers[length - i] * pos;
    pos -= 1;
    if (pos < 2) pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== digits[0]) return false;

  length += 1;
  numbers = cnpjSequence.slice(0, length);
  sum = 0;
  pos = length - 7;
  for (let i = length; i >= 1; i -= 1) {
    sum += numbers[length - i] * pos;
    pos -= 1;
    if (pos < 2) pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== digits[1]) return false;

  return true;
};
