import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  theme => ({
    appRestPhone: {
      width: "120%",
      top: "10%",
      left: "-27.5%",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
        left: 0,
      },
    },
  }),
  { name: "BrandGrowth", index: 1 },
);

export default useStyles;
