import { mixed, object } from "yup";
import getBankSchemasBR from "../bankAccount/banks/BR";

const additionalFieldsSchemas = {
  BR: mixed().when(["bankId", "number"], (bankId, number) => {
    const {
      agency,
      agencyVerificationDigit,
      getAccountVerificationDigitSchema,
    } = getBankSchemasBR(bankId);

    return object().shape({
      agency,
      verification_code: agencyVerificationDigit,
      account_digit: getAccountVerificationDigitSchema(number),
    });
  }),
};

export default additionalFieldsSchemas;
