import ArgentinianFlag from "../../../assets/images/flags/ar.svg";
import BrazilianFlag from "../../../assets/images/flags/br.svg";
import ChileanFlag from "../../../assets/images/flags/cl.svg";
import ColombianFlag from "../../../assets/images/flags/co.svg";
import MexicanFlag from "../../../assets/images/flags/mx.svg";
import PeruvianFlag from "../../../assets/images/flags/pe.svg";
import UruguayanFlag from "../../../assets/images/flags/uy.svg";
import CostaRicaFlag from "../../../assets/images/flags/cr.svg";
import EcuatorianFlag from "../../../assets/images/flags/ec.svg";
import { translateKey } from "../../../utils/translate";

export const teamOptions = {
  BR: ["Regular Hunting Team", "Inside Sales Team"],
  other: ["AAA", "Masivo"],
};

export const roleOptions = {
  BR: [
    "National Sales Manager",
    "Regional Manager",
    "VIP Leader",
    "Hunter Leader",
    "Hunter VIP",
    "Hunter",
  ],
  other: ["Líder", "Hunter"],
};

export const leaderOptions = [
  "National Sales Manager",
  "Regional Manager",
  "VIP Leader",
  "Líder",
];

export const answerOptions = {
  BR: [
    { label: "Sim", value: true },
    {
      label: "Não",
      value: false,
    },
  ],
  other: [
    { label: "Si", value: true },
    { label: "No", value: false },
  ],
};

export const salePointsOptions = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  {
    label: translateKey("form.newLeadsForm.options.numbers.tenOrMore"),
    value: "+10",
  },
];

export const countriesTaxonomies = {
  CO: [
    { label: "Alitas", value: "Alitas" },
    { label: "Alta Cocina", value: "Alta Cocina" },
    { label: "Árabe", value: "Árabe" },
    { label: "Asiática", value: "Asiática" },
    { label: "Café", value: "Café" },
    { label: "China", value: "China" },
    { label: "Comida Rápida", value: "Comida Rápida" },
    { label: "Crepes", value: "Crepes" },
    { label: "Desayunos", value: "Desayunos" },
    { label: "Empanadas", value: "Empanadas" },
    { label: "Hamburguesas", value: "Hamburguesas" },
    { label: "Helados", value: "Helados" },
    { label: "India", value: "India" },
    { label: "Internacional", value: "Internacional" },
    { label: "Italiana", value: "Italiana" },
    { label: "Jugos y Batidos", value: "Jugos y Batidos" },
    { label: "Mexicana", value: "Mexicana" },
    { label: "Panadería", value: "Panadería" },
    { label: "Parrilla", value: "Parrilla" },
    { label: "Perros Calientes", value: "Perros Calientes" },
    { label: "Peruana", value: "Peruana" },
    { label: "Pescados y Mariscos", value: "Pescados y Mariscos" },
    { label: "Pizza", value: "Pizza" },
    { label: "Poke", value: "Poke" },
    { label: "Pollo", value: "Pollo" },
    { label: "Postres", value: "Postres" },
    { label: "Saludable", value: "Saludable" },
    { label: "Sánduches", value: "Sánduches" },
    { label: "Sushi", value: "Sushi" },
    { label: "Típica", value: "Típica" },
    { label: "Vegetariana", value: "Vegetariana" },
  ],
  BR: [
    { label: "Açaí", value: "Açaí" },
    { label: "Árabe", value: "Árabe" },
    { label: "Asiática", value: "Asiática" },
    { label: "Brasileira", value: "Brasileira" },
    { label: "Burgers", value: "Burgers" },
    { label: "Café", value: "Café" },
    { label: "Café da Manhã", value: "Café da Manhã" },
    { label: "Chefs", value: "Chefs" },
    { label: "Congelados", value: "Congelados" },
    { label: "Crepes", value: "Crepes" },
    { label: "Francês", value: "Francês" },
    { label: "Grelhado", value: "Grelhado" },
    { label: "Italiana", value: "Italiana" },
    { label: "Japonesa", value: "Japonesa" },
    { label: "Mediterrânea", value: "Mediterrânea" },
    { label: "Mexicana", value: "Mexicana" },
    { label: "Padaria", value: "Padaria" },
    { label: "Peruana", value: "Peruana" },
    { label: "Pizza", value: "Pizza" },
    { label: "Sanduíches", value: "Sanduíches" },
    { label: "Saudável", value: "Saudável" },
    { label: "Sobremesas", value: "Sobremesas" },
    { label: "Sopas", value: "Sopas" },
    { label: "Sorvetes", value: "Sorvetes" },
    { label: "Sucos", value: "Sucos" },
    { label: "Vegetariana", value: "Vegetariana" },
    { label: "Xis", value: "Xis" },
  ],
  MX: [
    { label: "Alta Cocina", value: "Alta Cocina" },
    { label: "Árabe", value: "Árabe" },
    { label: "Asiática", value: "Asiática" },
    { label: "Café", value: "Café" },
    { label: "China", value: "China" },
    { label: "Comida Rápida", value: "Comida Rápida" },
    { label: "Desayunos", value: "Desayunos" },
    { label: "Hamburguesas", value: "Hamburguesas" },
    { label: "Helados", value: "Helados" },
    { label: "India", value: "India" },
    { label: "Internacional", value: "Internacional" },
    { label: "Italiana", value: "Italiana" },
    { label: "Jugos y Batidos", value: "Jugos y Batidos" },
    { label: "Mexicana", value: "Mexicana" },
    { label: "Panadería", value: "Panadería" },
    { label: "Pescados y Mariscos", value: "Pescados y Mariscos" },
    { label: "Pizza", value: "Pizza" },
    { label: "Poke", value: "Poke" },
    { label: "Pollo", value: "Pollo" },
    { label: "Postres", value: "Postres" },
    { label: "Saludables", value: "Saludables" },
    { label: "Sándwiches", value: "Sándwiches" },
    { label: "Sushi", value: "Sushi" },
    { label: "Tacos", value: "Tacos" },
    { label: "Vegetariana", value: "Vegetariana" },
    { label: "Wings", value: "Wings" },
  ],
  AR: [
    { label: "Árabe", value: "Árabe" },
    { label: "Argentina", value: "Argentina" },
    { label: "Asado", value: "Asado" },
    { label: "Asiática", value: "Asiática" },
    { label: "Café", value: "Café" },
    { label: "Desayunos y Meriendas", value: "Desayunos y Meriendas" },
    { label: "Empanadas", value: "Empanadas" },
    { label: "Hamburguesas", value: "Hamburguesas" },
    { label: "Helados", value: "Helados" },
    { label: "Hot Dogs", value: "Hot Dogs" },
    { label: "Internacional", value: "Internacional" },
    { label: "Italiana", value: "Italiana" },
    { label: "Jugos", value: "Jugos" },
    { label: "Mexicana", value: "Mexicana" },
    { label: "Milanesas", value: "Milanesas" },
    { label: "Panadería", value: "Panadería" },
    { label: "Peruana", value: "Peruana" },
    { label: "Pescados", value: "Pescados" },
    { label: "Pizza", value: "Pizza" },
    { label: "Pokes", value: "Pokes" },
    { label: "Pollo", value: "Pollo" },
    { label: "Postres", value: "Postres" },
    { label: "Saludable", value: "Saludable" },
    { label: "Sándwiches", value: "Sándwiches" },
    { label: "Sushi", value: "Sushi" },
    { label: "Vegetariana", value: "Vegetariana" },
  ],
  CL: [
    { label: "Árabe", value: "Árabe" },
    { label: "Asiática", value: "Asiática" },
    { label: "Café", value: "Café" },
    { label: "Carnes", value: "Carnes" },
    { label: "Comida Casera", value: "Comida Casera" },
    { label: "De Autor", value: "De Autor" },
    { label: "Desayunos", value: "Desayunos" },
    { label: "Hamburguesas", value: "Hamburguesas" },
    { label: "Helados", value: "Helados" },
    { label: "Hot Dogs", value: "Hot Dogs" },
    { label: "Internacional", value: "Internacional" },
    { label: "Italiana", value: "Italiana" },
    { label: "Jugos y Batidos", value: "Jugos y Batidos" },
    { label: "Mexicana", value: "Mexicana" },
    { label: "Pastelería y Postres", value: "Pastelería y Postres" },
    { label: "Peruana", value: "Peruana" },
    { label: "Pescados y Mariscos", value: "Pescados y Mariscos" },
    { label: "Pizzas", value: "Pizzas" },
    { label: "Pollo", value: "Pollo" },
    { label: "Saludable", value: "Saludable" },
    { label: "Sándwiches", value: "Sándwiches" },
    { label: "Sushi", value: "Sushi" },
    { label: "Vegetariana", value: "Vegetariana" },
  ],
  UY: [
    { label: "Alta Cocina", value: "Alta Cocina" },
    { label: "Árabe", value: "Árabe" },
    { label: "Asiática", value: "Asiática" },
    { label: "Cafetería", value: "Cafetería" },
    { label: "Chivitos", value: "Chivitos" },
    { label: "Comida Rápida", value: "Comida Rápida" },
    { label: "Desayunos y Meriendas", value: "Desayunos y Meriendas" },
    { label: "Empanadas", value: "Empanadas" },
    { label: "Hamburguesas", value: "Hamburguesas" },
    { label: "Helados", value: "Helados" },
    { label: "Internacional", value: "Internacional" },
    { label: "Italiana", value: "Italiana" },
    { label: "Milanesas", value: "Milanesas" },
    { label: "Panadería", value: "Panadería" },
    { label: "Panchos", value: "Panchos" },
    { label: "Parrilla", value: "Parrilla" },
    { label: "Pescados y Mariscos", value: "Pescados y Mariscos" },
    { label: "Pizza", value: "Pizza" },
    { label: "Postres", value: "Postres" },
    { label: "Saludable", value: "Saludable" },
    { label: "Sándwiches", value: "Sándwiches" },
    { label: "Sushi", value: "Sushi" },
    { label: "Uruguaya", value: "Uruguaya" },
    { label: "Vegetariana", value: "Vegetariana" },
  ],
  CR: [
    { label: "Alitas", value: "Alitas" },
    { label: "Árabe", value: "Árabe" },
    { label: "Asiática", value: "Asiática" },
    { label: "Café", value: "Café" },
    { label: "Carnes", value: "Carnes" },
    { label: "Del Mar", value: "Del Mar" },
    { label: "Desayuno", value: "Desayuno" },
    { label: "Ensaladas", value: "Ensaladas" },
    { label: "Hamburguesas", value: "Hamburguesas" },
    { label: "Helados", value: "Helados" },
    { label: "India", value: "India" },
    { label: "Italiana", value: "Italiana" },
    { label: "Jugos", value: "Jugos" },
    { label: "Mexicana", value: "Mexicana" },
    { label: "Panadería", value: "Panadería" },
    { label: "Pizza", value: "Pizza" },
    { label: "Poke", value: "Poke" },
    { label: "Postres", value: "Postres" },
    { label: "Saludable", value: "Saludable" },
    { label: "Sándwiches", value: "Sándwiches" },
    { label: "Sushi", value: "Sushi" },
    { label: "Típica", value: "Típica" },
    { label: "Vegetariana", value: "Vegetariana" },
  ],
  EC: [
    { label: "Alitas", value: "Alitas" },
    { label: "Alta Cocina", value: "Alta Cocina" },
    { label: "Árabe", value: "Árabe" },
    { label: "Asiática", value: "Asiática" },
    { label: "Café", value: "Café" },
    { label: "Ceviches", value: "Ceviches" },
    { label: "Comida Rápida", value: "Comida Rápida" },
    { label: "Desayuno", value: "Desayuno" },
    { label: "Ecuatoriana", value: "Ecuatoriana" },
    { label: "Hamburguesas", value: "Hamburguesas" },
    { label: "Helados", value: "Helados" },
    { label: "Hot Dog", value: "Hot Dog" },
    { label: "India", value: "India" },
    { label: "Internacional", value: "Internacional" },
    { label: "Italiana", value: "Italiana" },
    { label: "Jugos y Batidos", value: "Jugos y Batidos" },
    { label: "Mexicana", value: "Mexicana" },
    { label: "Panadería", value: "Panadería" },
    { label: "Parrilla", value: "Parrilla" },
    { label: "Peruana", value: "Peruana" },
    { label: "Pescados y Mariscos", value: "Pescados y Mariscos" },
    { label: "Pizza", value: "Pizza" },
    { label: "Poke", value: "Poke" },
    { label: "Pollo", value: "Pollo" },
    { label: "Postres", value: "Postres" },
    { label: "Saludable", value: "Saludable" },
    { label: "Sánduches", value: "Sánduches" },
    { label: "Sushi", value: "Sushi" },
    { label: "Vegetariana", value: "Vegetariana" },
  ],
  PE: [
    { label: "Alitas", value: "Alitas" },
    { label: "Árabe", value: "Árabe" },
    { label: "Asiática", value: "Asiática" },
    { label: "Café", value: "Café" },
    { label: "Carnes", value: "Carnes" },
    { label: "Chifa", value: "Chifa" },
    { label: "Criolla", value: "Criolla" },
    { label: "Desayunos", value: "Desayunos" },
    { label: "Experiencias", value: "Experiencias" },
    { label: "Hamburguesas", value: "Hamburguesas" },
    { label: "Helados", value: "Helados" },
    { label: "India", value: "India" },
    { label: "Internacional", value: "Internacional" },
    { label: "Jugos y Batidos", value: "Jugos y Batidos" },
    { label: "Mexicana", value: "Mexicana" },
    { label: "Panadería", value: "Panadería" },
    { label: "Pastas", value: "Pastas" },
    { label: "Pescados y Mariscos", value: "Pescados y Mariscos" },
    { label: "Pizzas", value: "Pizzas" },
    { label: "Poke", value: "Poke" },
    { label: "Pollería", value: "Pollería" },
    { label: "Postres", value: "Postres" },
    { label: "Salchipapas", value: "Salchipapas" },
    { label: "Saludable", value: "Saludable" },
    { label: "Sándwiches", value: "Sándwiches" },
    { label: "Sopas y Cremas", value: "Sopas y Cremas" },
    { label: "Sushi", value: "Sushi" },
    { label: "Vegetariana", value: "Vegetariana" },
  ],
};

export const countries = [
  { label: "Argentina", value: "AR", icon: ArgentinianFlag },
  { label: "Brasil", value: "BR", icon: BrazilianFlag },
  { label: "Chile", value: "CL", icon: ChileanFlag },
  { label: "Colombia", value: "CO", icon: ColombianFlag },
  { label: "México", value: "MX", icon: MexicanFlag },
  { label: "Perú", value: "PE", icon: PeruvianFlag },
  { label: "Uruguay", value: "UY", icon: UruguayanFlag },
  { label: "Costa Rica", value: "CR", icon: CostaRicaFlag },
  { label: "Ecuador", value: "EC", icon: EcuatorianFlag },
];

export const invalidHunterException = "unauthorized_hunter";

export const availableHuntersMx = [
  "anegrete@rappi.com",
  "enrique.quintero@rappi.com",
  "irving.arzapalo@rappi.com",
  "conny.murillo@rappi.com",
  "sebastian.bonnett@rappi.com",
  "liliana.gonzalez@rappi.com",
  "angel.gloria@rappi.com",
  "adriana.godoy@rappi.com",
  "alisma.ortiz@rappi.com",
  "juan.deleo@rappi.com",
  "andres.avila@rappi.com",
  "tiffany.arrazola@rappi.com",
  "andrea.brito@rappi.com",
  "alexis.solano@rappi.com",
  "luis.zepeda@rappi.com",
  "miriam.salinas@rappi.com",
  "alan.romero@rappi.com",
  "ivan.mendoza@rappi.com",
  "teresita.ojeda@rappi.com",
  "carlos.becerril@rappi.com",
  "alfonso.mendoza@rappi.com",
  "alan.ayala@rappi.com",
  "monica.reyes@rappi.com",
  "omar.favila@rappi.com",
  "pablo.feregrino@rappi.com",
  "katherine.juarez@rappi.com",
  "adlai.garcia@rappi.com",
  "julio.rosas@rappi.com",
  "luis.larez@rappi.com",
  "horacio.jimenez@rappi.com",
  "paulina.ramirez@rappi.com",
  "amir.jarrah@rappi.com",
  "emmy.nunez@rappi.com",
  "joanna.maldonado@rappi.com",
  "maria.melgoza@rappi.com",
  "francisco.camacho@rappi.com",
  "alejandro.m@rappi.com",
  "paola.ochoa@rappi.com",
  "mauricio.wernicky@rappi.com",
  "javier.palacios@rappi.com",
  "diego.zaldivar@rappi.com",
  "martha.lopez@rappi.com",
  "andrea.alvarez@rappi.com",
  "grecia.hermosillo@rappi.com",
  "paulina.juarez@rappi.com",
  "cristian.estrella@rappi.com",
  "julio.cadena@rappi.com",
  "alejandro.pina@rappi.com",
  "elena.martinez@rappi.com",
  "katherine.juarez@rappi.com",
  "jorge.ledon@rappi.com",
];
