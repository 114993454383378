const EMPTY_PROMISE = new Promise(resolve => resolve());

class Inbound {
  constructor(form, loadRequirements, title) {
    this.form = form;
    this.values = {};
    this.valid = false;
    this.title = title;
    this.loadRequirements = loadRequirements;
    this.requirements = null;
    this.submit = () => {};
  }

  getTitle = () => this.title;

  load = () =>
    this.loadRequirements
      ? this.loadRequirements().then(requirements => {
          this.requirements = requirements;
        })
      : EMPTY_PROMISE;

  onFormChange = ({ values, isValid, submitForm }) => {
    this.values = values;
    this.valid = isValid;
    this.submit = submitForm;
  };

  render = (initialValues, onValidityChanges) =>
    initialValues
      ? this.form({
          onFormChange: form => {
            this.onFormChange(form);
            onValidityChanges(form.isValid);
          },
          initialValues,
          ...this.requirements,
        })
      : this.form({
          onFormChange: form => {
            this.onFormChange(form);
            onValidityChanges(form.isValid);
          },
          ...this.requirements,
        });

  isValid = () => this.valid;

  simulateSubmitForm = () => {
    try {
      this.submit();
    } catch (e) {
      // ERROR
    }
  };
}

export default Inbound;
