import React from "react";
import * as PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./GeneralInformationForm.style";
import { translateKey } from "../../../utils/translate";
import TextField from "../../atoms/TextField";
import Select from "../../atoms/Select";
import FormSectionTitle from "../../atoms/FormSectionTitle";
import CombinedField from "../../molecules/CombinedField";
import { getAdditionalComponents } from "../../../fieldsFactory";
import { onChangeIdentificationFile } from "../../../api/ocr/nationalDocument/ocrNationalDocumentStatus";

const GeneralInformationForm = ({
  values,
  classes,
  handleChange,
  status,
  setFieldTouched,
  touched,
  errors,
  tributaryTypes,
  civilTypes,
  personTypes,
  businessTypes,
  additionalFields,
}) => (
  <Grid container>
    <FormSectionTitle title={translateKey("form.infoForm.generalData")} />
    <Grid container item xs={12}>
      <Grid item sm={6} xs={12} className={classes.field}>
        <TextField
          showError
          name="commercialName"
          title={translateKey("form.infoForm.commercialName")}
          value={values.commercialName}
          placeholder={translateKey("form.writeHere")}
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </Grid>
      <Grid item sm={6} xs={12} className={classes.field}>
        <Select
          name="legalType"
          value={values.legalType || "none"}
          onChange={handleChange}
          onBlur={() => setFieldTouched("legalType", true)}
          title={translateKey("form.infoForm.personType")}
          placeholder={translateKey("form.select")}
          options={personTypes}
          error={touched.legalType && errors.legalType ? errors.legalType : ""}
        />
      </Grid>
    </Grid>
    <Grid container item xs={12}>
      <Grid item sm={6} xs={12} className={classes.field}>
        <TextField
          showError
          name="legalAgentName"
          value={values.legalAgentName}
          title={translateKey("form.infoForm.legalAgentName")}
          placeholder={translateKey("form.writeHere")}
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </Grid>
      <Grid item sm={6} xs={12} className={classes.field}>
        <TextField
          showError
          name="legalAgentLastName"
          value={values.legalAgentLastName}
          title={translateKey("form.infoForm.legalAgentLastName")}
          placeholder={translateKey("form.writeHere")}
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </Grid>
    </Grid>
    <Grid container item xs={12}>
      <Grid item sm={6} xs={12} className={classes.field}>
        <CombinedField
          showErrors
          title={translateKey("form.infoForm.legalAgentId")}
          fieldNames={["legalAgentIdType", "legalAgentId"]}
          leftField={
            <Select
              name="legalAgentIdType"
              value={values.legalAgentIdType || civilTypes[0].value}
              onChange={handleChange}
              onBlur={() => setFieldTouched("legalAgentIdType", true)}
              placeholder={translateKey("form.select")}
              options={civilTypes}
            />
          }
          rightField={
            <TextField
              name="legalAgentId"
              value={values.legalAgentId}
              placeholder={translateKey("form.writeHere")}
              handleChange={handleChange}
              onInput={onChangeIdentificationFile}
              setFieldTouched={setFieldTouched}
              showError={false}
            />
          }
        />
      </Grid>
      <Grid item sm={6} xs={12} className={classes.field}>
        <TextField
          showError
          name="legalAgentEmail"
          value={values.legalAgentEmail}
          title={translateKey("form.infoForm.legalAgentEmail")}
          placeholder={translateKey("form.writeHere")}
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </Grid>
    </Grid>
    <Grid container item xs={12}>
      <Grid item sm={6} xs={12} className={classes.field}>
        <CombinedField
          showErrors
          title={translateKey("form.infoForm.socialReasonId").replace(
            ":ID",
            tributaryTypes.map(c => c.label).join("/"),
          )}
          fieldNames={["socialReasonTributaryType", "socialReasonTributaryId"]}
          leftField={
            <Select
              name="socialReasonTributaryType"
              value={
                values.socialReasonTributaryType || tributaryTypes[0].value
              }
              placeholder={translateKey("form.select")}
              onChange={handleChange}
              onBlur={() => setFieldTouched("socialReasonTributaryType", true)}
              options={tributaryTypes}
              error={
                touched.socialReasonTributaryType &&
                errors.socialReasonTributaryType
                  ? errors.socialReasonTributaryType
                  : ""
              }
            />
          }
          rightField={
            <TextField
              name="socialReasonTributaryId"
              value={values.socialReasonTributaryId}
              placeholder={translateKey("form.writeHere")}
              handleChange={handleChange}
              setFieldTouched={setFieldTouched}
            />
          }
        />
      </Grid>
      <Grid item sm={6} xs={12} className={classes.field}>
        <TextField
          showError
          disabled={
            status.socialReasonTributaryId &&
            status.socialReasonTributaryId.data
          }
          name="socialReasonName"
          value={values.socialReasonName}
          title={translateKey("form.infoForm.socialReasonName")}
          placeholder={translateKey("form.writeHere")}
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </Grid>
    </Grid>
    <Grid container item xs={12}>
      {businessTypes.length > 0 && (
        <Grid item sm={6} xs={12} className={classes.field}>
          <Select
            name="businessType"
            value={values.businessType || "none"}
            onChange={handleChange}
            onBlur={() => setFieldTouched("businessType", true)}
            title={translateKey("form.infoForm.businessType")}
            placeholder={translateKey("form.select")}
            options={businessTypes}
            error={
              touched.businessType && errors.businessType
                ? errors.businessType
                : ""
            }
          />
        </Grid>
      )}
    </Grid>
    <Grid container item xs={12} className={classes.lastField}>
      {additionalFields &&
        getAdditionalComponents(
          additionalFields,
          values,
          handleChange,
          setFieldTouched,
        ).map((field, index) => (
          <Grid item sm={6} xs={12} className={classes.field} key={index}>
            {field}
          </Grid>
        ))}
    </Grid>
  </Grid>
);

GeneralInformationForm.defaultProps = {
  additionalFields: [],
};

GeneralInformationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  status: PropTypes.object.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  tributaryTypes: PropTypes.array.isRequired,
  civilTypes: PropTypes.array.isRequired,
  personTypes: PropTypes.array.isRequired,
  businessTypes: PropTypes.array.isRequired,
  additionalFields: PropTypes.array,
};

export default withStyles(styles)(GeneralInformationForm);
