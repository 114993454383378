import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      fontFamily: theme.typography.fontFamily.regular,
      fontSize: 16,
      padding: "70px 90px 85px 90px",
      borderRadius: 15,
      textAlign: "center",
      "& > *:not(:last-child)": {
        marginBottom: 45,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        padding: "5%",
      },
    },
    title: {
      fontSize: "2.5em",
      fontWeight: "bold",
    },
    subtitle: {
      fontSize: "1em",
    },
    imageWrapper: {
      width: 156,
      height: 156,
      lineHeight: "156px",
      borderWidth: 4,
      borderStyle: "solid",
      borderRadius: "50%",
    },
    confirm: {
      borderColor: "#0cc665",
    },
  }),
  { name: "modal", classNamePrefix: "modal", index: 1 },
);

export default useStyles;
