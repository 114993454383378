import React from "react";
import { render } from "react-dom";
import { I18nextProvider } from "react-i18next";
import { pdfjs } from "react-pdf";
import { MuiThemeProvider } from "@material-ui/core/styles";
import i18n from "./utils/i18n";
import registerServiceWorker from "./registerServiceWorker";
import App from "./containers/App";
import theme from "./theme";
import NotificationsProvider from "./hocs/notifications/NotificationsProvider";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

render(
  <MuiThemeProvider theme={theme}>
    <I18nextProvider i18n={i18n}>
      <NotificationsProvider>
        <App />
      </NotificationsProvider>
    </I18nextProvider>
  </MuiThemeProvider>,
  // eslint-disable-next-line no-undef
  document.getElementById("root"),
);
registerServiceWorker();
