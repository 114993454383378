import moment from "moment";

export const toSchedules = schedules => {
  const result = Object.keys(schedules).reduce((acum, key) => {
    schedules[key].forEach(value => {
      const generatedKey = value.starts + value.ends;
      if (acum[generatedKey]) {
        acum[generatedKey].days.push(key);
      } else {
        // eslint-disable-next-line no-param-reassign
        acum[generatedKey] = {
          start_time: value.starts,
          ends_time: value.ends,
          days: [key],
        };
      }
    });
    return acum;
  }, {});
  return Object.values(result);
};

export const toTerms = (ip, info) => ({
  version: info.version,
  ip,
  date: moment().format("YYYY-MM-DDTHH:mm:ss"),
});

export const fromSchedules = ({ schedules }) =>
  schedules.reduce((scheduleMap, schedule) => {
    schedule.days.forEach(day => {
      if (scheduleMap[day]) {
        scheduleMap[day].push({
          starts: schedule.start_time,
          ends: schedule.ends_time,
        });
      } else {
        // eslint-disable-next-line no-param-reassign
        scheduleMap[day] = [
          { starts: schedule.start_time, ends: schedule.ends_time },
        ];
      }
    });
    return scheduleMap;
  }, {});
