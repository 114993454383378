import axios from "axios";
import config from "../config";

const requester = () => {
  // Interceptor
  const baseURL = config.API.BASE_URL;
  const requester = axios.create({ baseURL });
  return {
    ...requester,
    baseURL,
  };
};

export default requester;
