import {
  toCCEntities,
  verifyColombiaNationalDocument,
} from "./colombia/nationalDocument";
import {
  toIFEOrINEEntities,
  verifyMexicoNationalDocument,
} from "./mexico/nationalDocument";
import {
  toCPFEntities,
  verifyBrasilNationalDocument,
} from "./brasil/nationalDocument";
import {
  toPEDNIEntities,
  verifyPeruNationalDocument,
} from "./peru/nationalDocument";
import {
  toARDNIEntities,
  verifyArgentinaNationalDocument,
} from "./argentina/nationalDocument";
import {
  toCLCIEntities,
  verifyChileNationalDocument,
} from "./chile/nationalDocument";
import {
  toECCedulaEntities,
  verifyEcuadorNationalDocument,
} from "./ecuador/nationalDocument";
import {
  toCRCedulaEntities,
  verifyCostaNationalDocument,
} from "./costarica/nationalDocument";

export const EntitiesNationalDocument = {
  CO: {
    cc: toCCEntities,
  },
  MX: {
    ifeine: toIFEOrINEEntities,
  },
  BR: {
    cpf: toCPFEntities,
  },
  PE: {
    dni: toPEDNIEntities,
  },
  AR: {
    dni: toARDNIEntities,
  },
  CL: {
    ci: toCLCIEntities,
  },
  EC: {
    cedula: toECCedulaEntities,
  },
  CR: {
    cedulafisica: toCRCedulaEntities,
  },
  get(country, typeDocument) {
    const type = typeDocument.replace("/", "").replace(" ", "");

    if (this[country] && this[country][type]) {
      return this[country] && this[country][type];
    }
    return null;
  },
};

export const IdValidationAction = {
  CO: {
    cc: verifyColombiaNationalDocument,
  },
  MX: {
    ifeine: verifyMexicoNationalDocument,
  },
  BR: {
    cpf: verifyBrasilNationalDocument,
  },
  PE: {
    dni: verifyPeruNationalDocument,
  },
  AR: {
    dni: verifyArgentinaNationalDocument,
  },
  CL: {
    ci: verifyChileNationalDocument,
  },
  EC: {
    cedula: verifyEcuadorNationalDocument,
  },
  CR: {
    cedulafisica: verifyCostaNationalDocument,
  },
  get(country, typeDocument) {
    const type = typeDocument.replace("/", "").replace(" ", "");

    if (this[country] && this[country][type]) {
      return this[country] && this[country][type];
    }
    return null;
  },
};
