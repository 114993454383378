const multiplyOddEven = function multiplyOddEven(number, index) {
  return number * (index % 2 === 0 ? 2 : 1);
};

const getModTen = sum => {
  const rem = sum % 10;
  return (rem === 0 ? "0" : 10 - rem).toString();
};

const adjustAccordingLength = seq => {
  let sequence = seq;

  if (seq > 9) {
    const numbers = seq.toString().split("");
    sequence = 0;

    for (let i = 0; i < numbers.length; i += 1) {
      sequence += parseInt(numbers[i], 10);
    }
  }

  return sequence;
};

const getAccountVerificationDigit = (account, agency) => {
  let sum = 0;
  let result = 0;
  const numbers = (agency + account).split("");

  for (let i = 0; i < numbers.length; i += 1) {
    const number = parseInt(numbers[i], 10);
    result = multiplyOddEven(number, i);
    result = adjustAccordingLength(result);
    sum += result;
  }

  return getModTen(sum);
};

export default getAccountVerificationDigit;
