import baseStyleInjector from "../../../assets/styles/FormBase.style";

const stylizer = theme => ({
  ...baseStyleInjector(theme),
  inboundWrapper: {
    ...baseStyleInjector.formWrapper,
    padding: 20,
    [theme.breakpoints.up("md")]: {
      maxHeight: "100vh",
      paddingTop: 10,
    },
  },
  buttonWrapper: {
    paddingLeft: 40,
    paddingRight: 40,
  },
});

export default stylizer;
