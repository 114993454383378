const WEIGHTS = [3, 2, 4, 7, 6, 5, 4, 3, 2];

export const multiplyByWeight = (number, index) => number * WEIGHTS[index];

export const getModEleven = sumSeq => {
  const rem = sumSeq % 11;

  if (rem === 0) {
    return 0;
  }
  if (rem === 1) {
    return 6;
  }

  return 11 - rem;
};

export const getAccountVerificationDigit = accountNumber => {
  let sum = 0;
  const numbers = accountNumber.split("");

  for (let i = 0; i < numbers.length; i += 1) {
    const number = parseInt(numbers[i], 10);
    sum += multiplyByWeight(number, i);
  }

  return getModEleven(sum).toString();
};
