import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import OnboardingForm from "../OnboardingForm";
import Inbound from "../InboundForm";
import withLoader from "../../hocs/loader/withLoader";
import SurveyForm from "../SurveyForm";
import "../../theme/fonts.css";

const App = () => (
  <Router>
    <Switch>
      <Redirect exact from="/" to="/inbound" />
      <Route exact path="/inbound" component={Inbound} />
      <Route exact path="/new-lead-survey" component={SurveyForm} />
      <Route exact path="/:token" component={OnboardingForm} />
    </Switch>
  </Router>
);

App.propTypes = {};

export default withLoader(App);
