import React from "react";
import {
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from "@material-ui/core/";
import PropTypes from "prop-types";
import classNames from "classnames";
import { translateKeyWithHtml } from "../../../../../utils/translate";
import RestaurantIcon from "../../../../../assets/images/InboundPage/icon-rest.png";
import BurgerIcon from "../../../../../assets/images/InboundPage/icon-rest-burger.png";
import MotorcycleIcon from "../../../../../assets/images/InboundPage/icon-motorcycle-rt.png";
import useStyles from "./Steps.style";

const Steps = ({ pageClasses }) => {
  const classes = useStyles();

  const steps = [
    {
      id: 1,
      imgSrc: RestaurantIcon,
      imgAlt: "",
      text: translateKeyWithHtml("inboundPage.steps.list.step_1"),
    },
    {
      id: 2,
      imgSrc: BurgerIcon,
      imgAlt: "",
      text: translateKeyWithHtml("inboundPage.steps.list.step_2"),
    },
    {
      id: 3,
      imgSrc: MotorcycleIcon,
      imgAlt: "",
      text: translateKeyWithHtml("inboundPage.steps.list.step_3"),
    },
  ];

  return (
    <>
      <Typography
        variant="h3"
        align="right"
        className={classNames(
          pageClasses.sub2,
          pageClasses.headerBottomMargin,
          pageClasses.strongMain,
        )}
      >
        {translateKeyWithHtml("inboundPage.steps.title")}
      </Typography>
      <Grid
        container
        spacing={2}
        justify="space-between"
        className={classes.stepsContainer}
      >
        {steps.map(({ id, imgSrc, imgAlt, text }, index) => (
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            key={id}
            className={pageClasses.fullHeight}
          >
            <Card
              variant="outlined"
              className={classNames(
                pageClasses.fullHeight,
                pageClasses.perkCard,
              )}
            >
              <div className={pageClasses.flexCentering}>
                <div className={classes.number}>{index + 1}</div>
              </div>
              <CardMedia className={classes.stepImageContainer}>
                <img src={imgSrc} alt={imgAlt} />
              </CardMedia>
              <CardContent className={classes.stepSummary}>{text}</CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

Steps.propTypes = {
  pageClasses: PropTypes.object.isRequired,
};

export default Steps;
