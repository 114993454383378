/* eslint-disable func-names */
import * as defaultSchemas from "../default/schemas";
import { getAccountVerificationDigit } from "./calculators";
import { buildDigitsMessage } from "../../../../utils/messageBuilders";
import { translateKey } from "../../../../../utils/translate";

const ACCOUNT_LENGTH = { min: 9, max: 9 };
const AGENCY_LENGTH = 4;
const AGENCY_DIGIT_LENGTH = 1;

const requiredMessage = translateKey("form.bankInfoForm.required");

export const bankAccountNumber = defaultSchemas.bankAccountNumber
  .clone()
  .required(requiredMessage)
  .length(
    ACCOUNT_LENGTH.max,
    buildDigitsMessage(ACCOUNT_LENGTH.max, "exactly"),
  );

const bankAccountVerificationDigit = defaultSchemas
  .getAccountVerificationDigitSchema()
  .clone();

export const getAccountVerificationDigitSchema = accountNumber =>
  bankAccountVerificationDigit.test(
    "accountAndDigitMatch",
    "Dígito não corresponde à conta",
    function() {
      return (
        getAccountVerificationDigit(accountNumber) ===
        this.parent.account_digit.toUpperCase()
      );
    },
  );

export const agency = defaultSchemas.agency
  .clone()
  .required(requiredMessage)
  .length(AGENCY_LENGTH, buildDigitsMessage(AGENCY_LENGTH));

export const agencyVerificationDigit = defaultSchemas.agencyVerificationDigit
  .clone()
  .length(AGENCY_DIGIT_LENGTH, buildDigitsMessage(AGENCY_DIGIT_LENGTH));
