import React, { useMemo } from "react";
import { Grid, Typography, Card } from "@material-ui/core/";
import { Carousel } from "react-responsive-carousel";
import PropTypes from "prop-types";
import classNames from "classnames";
import { GridHalfAtMd } from "../../../../../utils/Grids";
import {
  translateKey,
  translateKeyWithHtml,
} from "../../../../../utils/translate";
import AppHomePageAngle from "../../../../../assets/images/InboundPage/mockup-home-rest.png";
import useStyles from "./ClientQuotes.style";

const images = [];

const ClientQuotes = ({ pageClasses = {} }) => {
  const classes = useStyles();

  const quoteCards = useMemo(
    () =>
      Array(4)
        .fill(0)
        .map((_, index) => {
          const basePath = `inboundPage.clientQuotes.list.quote_${index + 1}`;
          const image = images[index];
          const quoteSizes = image
            ? { md: 12, lg: 8, xl: 8 }
            : { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 };

          return (
            <Card
              key={basePath}
              variant="outlined"
              className={classNames(pageClasses.perkCard, classes.quoteCard)}
            >
              <Grid container>
                {image && (
                  <Grid item md={12} lg={4} xl={4}>
                    <div className={classes.quoteCardImageContainer}>
                      <img src={image} alt="" className="quoteSrcImage" />
                    </div>
                  </Grid>
                )}
                <Grid item {...quoteSizes}>
                  <div className={pageClasses.flexCentering}>
                    <div>
                      <blockquote>
                        {'"'}
                        {translateKey(`${basePath}.quote`)}
                        <span className={classes.endingQuoteMark}>&quot;</span>
                      </blockquote>
                      <cite>
                        <span className={pageClasses.highlighted}>
                          {translateKey(`${basePath}.author`)}
                        </span>
                        <br />
                        <span
                          className={classNames(
                            pageClasses.detail,
                            pageClasses.dim,
                          )}
                        >
                          {translateKey(`${basePath}.location`)}
                        </span>
                      </cite>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Card>
          );
        }),
    [classes, pageClasses],
  );

  return (
    <Grid container>
      <GridHalfAtMd item md={8} lg={8} xl={8} className={pageClasses.elevated1}>
        <Typography
          variant="h3"
          className={classNames(
            pageClasses.sub2,
            pageClasses.headerBottomMargin,
            pageClasses.strongMain,
          )}
        >
          {translateKeyWithHtml("inboundPage.clientQuotes.title")}
        </Typography>
        <Typography paragraph>
          {translateKey("inboundPage.clientQuotes.textContent.paragraph_1")}
          .
          <br />
          <br />
          {translateKey("inboundPage.clientQuotes.textContent.paragraph_2")}
          .
          <br />
          <br />
        </Typography>
        <div className={classes.quoteWrapper}>
          <Carousel
            autoPlay
            swipeable
            infiniteLoop
            interval={9000}
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            className={pageClasses.cardCarousel}
          >
            {quoteCards}
          </Carousel>
        </div>
      </GridHalfAtMd>
      <GridHalfAtMd item md={4} lg={4} xl={4}>
        <div
          className={classNames(
            pageClasses.overflowVisible,
            classes.limitHeight,
          )}
        >
          <div className={pageClasses.backgroundCircleWrapper}>
            <div
              className={classNames(
                pageClasses.aspectRatio_1x1,
                pageClasses.backgroundCircle,
                pageClasses.circleRight,
              )}
            />
          </div>
          <img
            src={AppHomePageAngle}
            alt=""
            className={classes.appMockupPhone}
          />
        </div>
      </GridHalfAtMd>
    </Grid>
  );
};

ClientQuotes.propTypes = {
  pageClasses: PropTypes.object.isRequired,
};

export default ClientQuotes;
