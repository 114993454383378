import axios from "axios";
import config from "../config";

const COUNTRY_LOCATIONS = {
  CO: {
    lat: 4.985335,
    lng: -74.732809,
  },
  MX: {
    lat: 25.985335,
    lng: -111.732809,
  },
  BR: {
    lat: -23.533773,
    lng: -46.62529,
  },
  AR: {
    lat: -34.588263,
    lng: -58.439125,
  },
  CL: {
    lat: -33.5,
    lng: -70.0,
  },
  PE: {
    lat: -12.0552493,
    lng: -77.0627323,
  },
  UY: {
    lat: -34.90903,
    lng: -56.16585,
  },
  EC: {
    lat: -0.1862504,
    lng: -78.5709654,
  },
  CR: {
    lat: 9.935697,
    lng: -84.1484503,
  },
};

const requester = async country => {
  const location = COUNTRY_LOCATIONS[country];
  return axios
    .get(
      `${config.API.RESOLVE_COUNTRY_URL}?lat=${location.lat}&lng=${location.lng}`,
    )
    .then(({ data }) =>
      axios.create({
        baseURL: data.centralized_server_host.replace("http:", "https:"),
      }),
    );
};

export default requester;
