const DEFAULT_FONT_SIZE = 14;

const stylizer = theme => ({
  root: {
    border: `solid 1px ${theme.palette.disabled.main}`,
    maxHeight: "40vh",
    padding: 20,
    overflowY: "scroll",
    position: "relative",
    "&:hover": {
      "& > #controls": {
        opacity: 1,
      },
    },
  },
  controls: {
    fontFamily: theme.typography.fontFamily.regular,
    color: theme.typography.palette.main,
    fontSize: DEFAULT_FONT_SIZE,
    position: "sticky",
    bottom: "4%",
    left: "50%",
    background: "white",
    opacity: 0,
    transform: "translateX(-50%)",
    transition: "opacity ease-in-out 0.2s",
    boxShadow: "0 30px 40px 0 rgba(16, 36, 94, 0.2)",
    borderRadius: 4,
    width: 230,
  },
  button: {
    width: 44,
    height: 44,
    background: "white",
    border: 0,
    font: "inherit",
    fontSize: "0.8em",
    borderRadius: 4,
    "&:focus": {
      outline: "none",
    },
  },
  doc: {
    minHeight: "40vh",
  },
});

export default stylizer;
