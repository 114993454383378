import { makeStyles } from "@material-ui/core";
import { fontSizes } from "../../InboundPage.style";

const useStyles = makeStyles(
  theme => ({
    limitHeight: {
      height: "fit-content",
      [theme.breakpoints.down("sm")]: {
        maxHeight: 750,
      },
      [theme.breakpoints.down("xs")]: {
        maxHeight: 300,
      },
      [theme.breakpoints.between(600, 850)]: {
        maxHeight: 680,
      },
    },
    quoteWrapper: {
      maxWidth: "85%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "none",
      },
    },
    quoteCard: {
      padding: 30,
      marginBottom: 40,
      textAlign: "left",
      "& blockquote": {
        margin: 0,
        marginBottom: 35,
      },
      "& cite": {
        fontStyle: "normal",
      },
      "& span:not([class*=endingQuoteMark])": {
        display: "inline",
      },
      "& [class*=quoteCardImageContainer] img.quoteSrcImage": {
        width: "80%",
        borderRadius: "50%",
        boxShadow: "0 6px 10px 0 rgba(128, 98, 96, 0.16)",
        [theme.breakpoints.down("md")]: {
          width: "25%",
          minWidth: 60,
        },
      },
      [theme.breakpoints.down("xs")]: {
        ...fontSizes.detail,
        padding: 20,
      },
    },
    quoteCardImageContainer: {
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        textAlign: "left",
        marginBottom: 15,
      },
    },
    endingQuoteMark: {
      display: "inline-block",
      height: 0,
      fontSize: 60,
      lineHeight: "60px",
      verticalAlign: "top",
    },
    appMockupPhone: {
      top: "20%",
      right: "-40%",
      [theme.breakpoints.up("lg")]: {
        width: "150%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "80%",
        top: "10%",
        right: "-10%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        right: "-25%",
      },
    },
  }),
  { name: "ClientQuotes", index: 1 },
);

export default useStyles;
