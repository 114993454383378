import React, { Component } from "react";
import * as PropTypes from "prop-types";
import notifyByError from "../../utils/notifyByError";
import { translateKey, translateKeyWithHtml } from "../../utils/translate";
import { save } from "../../api/surveys";
import Modal from "../../components/molecules/Modal";
import Request from "../../assets/images/img-request.png";
import InboundPage from "../../components/pages/Inbound";
import {
  availableHuntersMx,
  invalidHunterException,
} from "../../components/organisms/NewLeadForm/NewLead.constants";

class SurveyForm extends Component {
  constructor(props) {
    super(props);
    this.state = { loader: false, showModal: false, buttonDisabled: false };
  }

  componentWillMount() {
    const { loader, form, notifier } = this.props;
    loader.load();
    form
      .load()
      .then(() => this.setState({ loader: true }))
      .catch(notifyByError(notifier, -1))
      .finally(loader.stop);
  }

  showModal = () => this.setState({ showModal: true });

  onButtonClick = () => {
    this.setState({ buttonDisabled: true }, () => this.submit());
  };

  isInvalidHunter = (country, emailHunter) => {
    const isValid =
      country === "MX" && !availableHuntersMx.includes(emailHunter);
    return isValid;
  };

  submit = async () => {
    const { form, notifier } = this.props;
    form.values.date = new Date().toISOString();
    form.values.city = form.values.data.city;
    const { email_hunter: emailHunter, country } = form.values.data;

    if (form.isValid()) {
      if ("email" in form.values) {
        form.values.data.push({ email: form.values.email });
      }
      delete form.values.email;
      delete form.values.mapLng;
      delete form.values.mapLat;
      try {
        if (this.isInvalidHunter(country, emailHunter))
          throw new Error(invalidHunterException);
        await save(form.values, form.values.country);

        this.showModal();
      } catch (e) {
        if (e.message.includes(invalidHunterException)) {
          notifier.error(
            translateKey("form.newLeadsForm.errors.invalidHunter"),
          );
        } else {
          notifier.error(
            translateKey("form.newLeadsForm.errors.sendingSurvey"),
          );
        }
        this.setState({ buttonDisabled: false });
      }
    } else {
      notifier.error(translateKey("form.newLeadsForm.errors.invalidForm"));
      this.setState({ buttonDisabled: false });
    }
  };

  render() {
    const { form } = this.props;
    const { loader, showModal, buttonDisabled } = this.state;

    return [
      <InboundPage
        form={loader ? form : null}
        onButtonClick={this.onButtonClick}
        loaded={loader}
        buttonDisabled={buttonDisabled}
      />,
      <Modal
        open={showModal}
        title={translateKey("form.inbound.modal.title")}
        subtitle={translateKey("form.newLeadsForm.sent")}
        image={Request}
        buttonText={translateKey("form.inbound.modal.buttonText")}
        onClick={() => window.location.reload()}
      />,

      <Modal
        open
        title={translateKey("form.newLeadsForm.deactivatedModal.title")}
        subtitle={translateKeyWithHtml(
          "form.newLeadsForm.deactivatedModal.subtitle",
        )}
        video
        buttonText={translateKey("form.newLeadsForm.deactivatedModal.btn")}
        onClick={() => {
          window.location.href = "https://crm.zoho.com";
        }}
      />,
    ];
  }
}

SurveyForm.propTypes = {
  loader: PropTypes.object.isRequired,
  notifier: PropTypes.object.isRequired,
  form: PropTypes.func.isRequired,
};

export default SurveyForm;
