import React from "react";
import { Grid, Typography } from "@material-ui/core/";
import PropTypes from "prop-types";
import classNames from "classnames";
import { translateKey } from "../../../../../utils/translate";
import { GridHalfAtMd } from "../../../../../utils/Grids";
import HeroImage from "../../../../../assets/images/InboundPage/image-hero-rest.jpg";
import useStyles from "./HeaderAndForm.style";

const HeaderAndForm = ({ pageClasses, form }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <GridHalfAtMd
        item
        className={classNames(pageClasses.pageHeader, classes.halfGrid)}
      >
        <div
          className={classNames(classes.heroContent, pageClasses.fullHeight)}
        >
          <img
            src={HeroImage}
            alt="Rappitendero en restaurante"
            className={classes.heroImage}
          />
          <div className={classes.heroText}>
            <Typography
              variant="h1"
              className={classNames(classes.header, classes.onboardingTitle)}
            >
              {translateKey("inboundPage.hero.title")}
            </Typography>
            <Typography
              variant="h2"
              className={classNames(classes.header, classes.onboardingSubtitle)}
            >
              {translateKey("inboundPage.hero.subtitle")}
            </Typography>
            <Typography
              variant="h4"
              className={classNames(classes.header, classes.onboardingCaption)}
            >
              {translateKey("inboundPage.hero.caption")}
              {` `}
              &gt;
            </Typography>
          </div>
        </div>
      </GridHalfAtMd>
      <GridHalfAtMd
        item
        className={classNames(classes.formWrapper, classes.halfGrid)}
      >
        <div className={classes.form}>{form}</div>
      </GridHalfAtMd>
    </Grid>
  );
};

HeaderAndForm.propTypes = {
  pageClasses: PropTypes.object.isRequired,
  form: PropTypes.node,
};

HeaderAndForm.defaultProps = {
  form: null,
};

export default HeaderAndForm;
