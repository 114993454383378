import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const FieldDescriptor = ({
  classes,
  className,
  title,
  children,
  noWrap,
  ...props
}) => (
  <span
    className={classnames(
      classes.root,
      className,
      noWrap ? classes.noWrap : "",
    )}
    {...props}
    title={title}
  >
    {title || children}
  </span>
);

FieldDescriptor.defaultProps = {
  title: null,
  children: null,
  className: "",
  noWrap: false,
};

FieldDescriptor.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  noWrap: PropTypes.bool,
};

export default FieldDescriptor;
