import requester from "../requester";

export const toSearchEntities = (imagesToAnalise, entitiesToSearch) => ({
  images: imagesToAnalise,
  entities: entitiesToSearch,
});

export const verifyEntities = (body, tokenValue) =>
  requester()
    .post(`/api/al-ocr/search-entities/process`, body, {
      headers: { token: tokenValue, ContentType: "application/json" },
    })
    .then(response => response);
