import React, { Component } from "react";
import { Document, Page } from "react-pdf";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import * as PropTypes from "prop-types";

export default class PDFViewer extends Component {
  state = {
    numPages: null,
  };

  onDocumentLoadSuccess = document => {
    const { numPages } = document;
    this.setState({
      numPages,
    });
  };

  handleScroll = evt => {
    const node = evt.target;
    const bottom =
      node.scrollHeight - node.scrollTop - node.clientHeight * 1.45 <= 0;
    if (bottom) {
      this.props.onReadFinished();
    }
  };

  addScrollHandler = node => {
    if (node) {
      node.addEventListener("scroll", this.handleScroll);
    }
  };

  render() {
    const { numPages } = this.state;
    const { classes, src } = this.props;

    return (
      <div className={classes.root} ref={this.addScrollHandler}>
        <Grid container justify="center">
          <Document
            file={src}
            onLoadSuccess={this.onDocumentLoadSuccess}
            loading={<CircularProgress color="secondary" />}
            className={classes.doc}
          >
            {[...Array(numPages).keys()].map(pageNumber => (
              <Page
                key={pageNumber}
                scale={1.2}
                pageNumber={pageNumber + 1}
                loading={<CircularProgress color="secondary" />}
              />
            ))}
          </Document>
        </Grid>
      </div>
    );
  }
}

PDFViewer.defaultProps = {
  onReadFinished: () => {},
};

PDFViewer.propTypes = {
  classes: PropTypes.object.isRequired,
  src: PropTypes.string.isRequired,
  onReadFinished: PropTypes.func,
};
