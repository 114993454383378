import React, { useRef } from "react";
import { isEmpty } from "lodash";
import { Formik } from "formik";
import PropTypes from "prop-types";
import Effect from "../../atoms/FormikEffect";
import LandingForm from "./LandingForm.Wrapper";
import validationSchema from "./InboundSchema";
import withNotifier from "../../../hocs/notifications/withNotifier";
import {
  countriesTaxonomies,
  countries,
  phonePrefixes,
} from "./Inbound.constants";
import sendInboundDataLayer from "../../../containers/InboundForm/sendInboundDataLayer";

export const loadRequirements = async () => {
  const country = window.localStorage.getItem("countryCode") || "CO";
  const taxonomies = countriesTaxonomies;
  return {
    taxonomies,
    country,
    countries,
    phonePrefixes,
  };
};

const defaultValues = {
  date: "",
  classification: "inbound",
  city: "",
  country: "CO",
  data: {
    address: "",
    name: "",
    email: "",
    source: "inbound",
    phone_prefix: "+57",
    phone: "",
    total_sales_point: "",
    owners_name: "",
    owners_last_name: "",
    trade_type: "restaurant",
    categories: "",
    preferred_contact: [],
    referral_code: "",
    sales_estimate: "0",
    instagram_account: "",
  },
  contact_agreement: false,
  whatsapp_agreement: false,
};

const ConfiguredForm = ({
  onSend,
  onFormChange,
  initialValues,
  taxonomies,
  notifier,
  countries,
  phonePrefixes,
}) => {
  const modifiedFields = useRef({});
  const lastModified = useRef("");
  const initValues = isEmpty(initialValues) ? defaultValues : initialValues;

  return (
    <Formik
      validateOnMount
      initialValues={initValues}
      validationSchema={validationSchema}
    >
      {({ handleChange, ...formProps }) => {
        const onChange = evt => {
          lastModified.current = evt.target.name.replace("data.", "");
          handleChange(evt);
        };

        const formikProps = { handleChange, ...formProps };
        onFormChange(formikProps);

        return (
          <>
            <Effect
              onChange={state => {
                const { touched } = state;
                const { current: field } = lastModified;

                if (modifiedFields.current[field]) {
                  return;
                }

                if (touched.data && touched.data[field]) {
                  modifiedFields.current[field] = true;
                  const value = state.values.data[field];
                  sendInboundDataLayer("FIELD_MODIFIED", { field, value });
                }
              }}
            />
            <LandingForm
              {...formikProps}
              handleChange={onChange}
              onSend={onSend}
              countries={countries}
              phonePrefixes={phonePrefixes}
              taxonomies={taxonomies}
              notifier={notifier}
            />
          </>
        );
      }}
    </Formik>
  );
};

ConfiguredForm.defaultProps = {
  city: [],
  onSend: () => {},
};

ConfiguredForm.propTypes = {
  onSend: PropTypes.func,
  initialValues: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  phonePrefixes: PropTypes.array.isRequired,
  onFormChange: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  notifier: PropTypes.object.isRequired,
  city: PropTypes.array,
  taxonomies: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
};

export default withNotifier(ConfiguredForm);
