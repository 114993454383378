/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import BankInfoForm from "./BankInfoForm";
import withNotifier from "../../../hocs/notifications/withNotifier";
import withLoader from "../../../hocs/loader/withLoader";
import buildValidationSchema, { FIELD_BINDINGS } from "./BankInfoSchema";
import {
  bankAccountNameByCountry,
  banksPerCountry,
  phonePrefixes,
  civilTypes,
  accountTypes,
  alertItems,
  companySizes,
  civilAndPersonTypesRel,
} from "./BankInfoForm.constants";
import getCnpj from "../../../api/cnpj";
import { translateKey } from "../../../utils/translate";
import { mergeWithDefault } from "../../../utils/functions";
import { fromBankInfo } from "../../../mappers/BankInfo";
import bankAccConstraints from "../../../schemasAndConstraints/bankAccount/constraints";
import idConstraints from "../../../schemasAndConstraints/ids/constraints";
import { useFieldConstraints as constrainFields } from "../../../hooks/useFieldConstraints";
import { personTypeValues } from "../GeneralInformationForm/GeneralInformationForm.constants";

const defaultValues = {
  number: "",
  typeId: "",
  bankId: 0,
  billingAddress: "",
  ownerId: "",
  ownerIdType: "",
  ownerName: "",
  ownerLastName: "",
  ownerEmail: "",
  ownerTelephone: "",
  fiscalAddress: "",
  billingAddres: "",
  phonePrefix: "+57",
  additionalFields: {},
};

const getCompanySize = (country, ownerIdData = {}) => {
  const { regime } = ownerIdData;
  const countrySizes = companySizes[country] || {};
  switch (country) {
    case "BR": {
      const { legal_nature } = ownerIdData;
      return countrySizes[legal_nature] || ownerIdData.regime;
    }
    default:
      return countrySizes[regime] || countrySizes.default;
  }
};

export const loadRequirements = async (
  {
    social_reason: {
      legal_agent: { person_type },
    },
  },
  { countryCode, steps },
) => {
  const country = countryCode || "CO";
  const isJuridic = personTypeValues.juridic.includes(
    person_type.toLowerCase(),
  );
  const banks = banksPerCountry[country];
  const bankAccountNumberName =
    bankAccountNameByCountry[country] ||
    translateKey("form.bankInfoForm.accountNumber");
  const additionalFields = steps[4] || [];
  const validationSchema = buildValidationSchema(additionalFields, country);
  return {
    banks,
    country,
    isJuridic,
    civilTypes: civilTypes[country],
    civilAndPersonTypesRel: civilAndPersonTypesRel[country] || {},
    accountTypes: accountTypes[country],
    alertItems: alertItems[country],
    bankAccountNumberName,
    phonePrefixes,
    additionalFields,
    validationSchema,
  };
};

export const getInitialValues = ({ bank_info = {}, additional_data = {} }) => {
  const initialValues = {
    ...fromBankInfo(bank_info),
    additionalFields: additional_data,
  };
  return mergeWithDefault(initialValues, defaultValues);
};

const ConfiguredForm = ({
  country,
  userInfo,
  stepsValues,
  setStepAdditionalData,
  onFormChange,
  civilTypes,
  civilAndPersonTypesRel,
  banks,
  accountTypes,
  bankAccountNumberName,
  phonePrefixes,
  alertItems,
  additionalFields,
  validationSchema,
  loader,
  notifier,
}) => {
  const isBR = country === "BR";
  const { socialReasonTributaryId } = stepsValues.generalInfo;
  const {
    generalInfo: { socialReasonTributaryId: ownerIdData } = {},
  } = stepsValues.additionalData;

  const companySize = getCompanySize(country, ownerIdData);

  const combinedConstraints = {
    ...idConstraints[country],
    ...bankAccConstraints[country],
  };
  let initialValues = getInitialValues(userInfo, stepsValues);

  defaultValues.ownerIdType = civilTypes ? civilTypes[0].value : "";
  defaultValues.phonePrefix = (
    phonePrefixes.find(prefix => prefix.country === country) ||
    defaultValues.phonePrefix
  ).value;

  initialValues = isEmpty(initialValues) ? defaultValues : initialValues;

  useEffect(() => {
    function getCnpjData() {
      loader.load();
      getCnpj(socialReasonTributaryId)
        .then(data => {
          setStepAdditionalData("generalInfo", {
            socialReasonTributaryId: data,
          });
        })
        .catch(() => {
          notifier.error(translateKey("form.infoForm.cnpjValidationError"));
        })
        .finally(loader.stop);
    }

    if (isBR && !ownerIdData) getCnpjData();
    // eslint-disable-next-line
  }, []);

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={() => {}}
    >
      {({ values, handleChange, ...props }) => {
        const formValues = { ...values, companySize };

        const { onChange: onChangeFn } = constrainFields(
          handleChange,
          combinedConstraints,
          FIELD_BINDINGS,
        );

        const onChange = evt => {
          onChangeFn(evt, values);
        };

        const formProps = { values, ...props };
        onFormChange(formProps);

        return (
          <BankInfoForm
            {...formProps}
            country={country}
            values={formValues}
            stepsValues={stepsValues}
            handleChange={onChange}
            civilTypes={civilTypes}
            civilAndPersonTypesRel={civilAndPersonTypesRel}
            banks={banks}
            accountTypes={accountTypes}
            bankAccountNumberName={bankAccountNumberName}
            phonePrefixes={phonePrefixes}
            additionalFields={additionalFields}
            alertItems={alertItems}
          />
        );
      }}
    </Formik>
  );
};

ConfiguredForm.defaultProps = {
  userInfo: {},
  additionalFields: [],
  alertItems: [],
};

ConfiguredForm.propTypes = {
  country: PropTypes.string.isRequired,
  userInfo: PropTypes.object,
  onFormChange: PropTypes.func.isRequired,
  stepsValues: PropTypes.object.isRequired,
  setStepAdditionalData: PropTypes.func.isRequired,
  civilTypes: PropTypes.array.isRequired,
  civilAndPersonTypesRel: PropTypes.object.isRequired,
  alertItems: PropTypes.array,
  banks: PropTypes.array.isRequired,
  accountTypes: PropTypes.array.isRequired,
  phonePrefixes: PropTypes.array.isRequired,
  bankAccountNumberName: PropTypes.string.isRequired,
  additionalFields: PropTypes.array,
  validationSchema: PropTypes.object.isRequired,
  notifier: PropTypes.object.isRequired,
  loader: PropTypes.object.isRequired,
};

export default withLoader(withNotifier(ConfiguredForm));
