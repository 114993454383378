import React from "react";

const EMPTY_PROMISE = new Promise(resolve => resolve());

class OnboardingStep {
  constructor(form, title, name, loadRequirements, getInitialValues) {
    this.form = form;
    this.values = {};
    this.valid = false;
    this.title = title;
    this.name = name;
    this.loadRequirements = loadRequirements;
    this.getInitialValues = getInitialValues;
    this.requirements = null;
    this.submit = () => {};
  }

  getTitle = () => this.title;

  getName = () => this.name;

  load = (userInfo, processInfo) =>
    this.loadRequirements
      ? this.loadRequirements(userInfo, processInfo).then(requirements => {
          this.requirements = requirements;
        })
      : EMPTY_PROMISE;

  onFormChange = ({ values, isValid, submitForm }) => {
    this.values = values;
    this.valid = isValid;
    this.submit = submitForm;
  };

  render = (
    userInfo,
    stepsValues,
    setStepAdditionalData,
    onValidityChanges,
  ) => {
    const Form = this.form;
    return (
      <Form
        onFormChange={formProps => {
          this.onFormChange(formProps);
          onValidityChanges(formProps.isValid);
        }}
        name={this.getName()}
        userInfo={userInfo}
        stepsValues={stepsValues}
        setStepAdditionalData={setStepAdditionalData}
        {...this.requirements}
      />
    );
  };

  isValid = () => this.valid;

  simulateSubmitForm = () => {
    try {
      this.submit();
    } catch (e) {
      //
    }
  };
}

export default OnboardingStep;
