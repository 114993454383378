import requester from "./requester";
import normalizeAddress from "../utils/normalizeAddress";

// eslint-disable-next-line camelcase
const requestLink = ({ email, address, google_place_id: place_id }) =>
  requester()
    .get("/api/rs-self-onboarding/store-process/link", {
      params: {
        email,
        place_id,
        address: normalizeAddress(address),
      },
    })
    .then(res => res.data.link);

export default requestLink;
