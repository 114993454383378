const stylizer = theme => ({
  root: {
    display: "block !important",
    flexGrow: 1,
    minHeight: "100vh",
    position: "relative",
    zIndex: 1,
  },
  content: {
    width: "100%",
    margin: "6vh 0",
    padding: "0 25vw",

    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    [theme.breakpoints.down("lg")]: {
      padding: "0 20vw",
    },
  },
  title: {
    display: "block",
    fontSize: 18,
    textAlign: "center",
    fontFamily: theme.typography.fontFamily.regular,
    color: theme.typography.palette.main,
    marginBottom: 10,
  },
  subTitle: {
    display: "block",
    fontSize: 26,
    textAlign: "center",
    fontFamily: theme.typography.fontFamily.bold,
    color: theme.typography.palette.main,
    margin: 0,
    marginBottom: "5vh",
  },
  paragraph: {
    display: "block",
    fontSize: 16,
    textAlign: "center",
    fontFamily: theme.typography.fontFamily.regular,
    color: theme.typography.palette.main,
    margin: 0,
    marginBottom: "5vh",
    padding: "0 16px",
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  bottomImage: {
    position: "fixed",
    bottom: 0,
    height: "40vh",
    justifySelf: "center",
    "@media screen and (max-height: 600px)": {
      display: "none",
    },

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

export default stylizer;
