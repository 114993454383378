import React from "react";
import { Formik } from "formik";
import * as PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import ServiceModelForm from "./ServiceModelForm";
import buildValidationSchema from "./ServiceModelSchema";
import {
  deliveryTypes as countryDeliveryTypes,
  contractsPerCountry,
  rappiSocialReasonNamePerCountry,
} from "./ServiceModelForm.constants";
import { mergeWithDefault } from "../../../utils/functions";
import withNotifier from "../../../hocs/notifications/withNotifier";
import { getDeliveryTypesByPolygon } from "../../../api/coverage";
import logEvent from "../../../utils/EventLogger";

const getDeliveryTypes = async (country, store) => {
  try {
    if (store && store.latitude && store.longitude) {
      return await getDeliveryTypesByPolygon(
        country,
        store.latitude,
        store.longitude,
      );
    }
    return [];
  } catch (error) {
    logEvent("STEP_ERROR", error);
    return [];
  }
};

const getFilteredDeliveryTypes = async (
  country,
  store,
  availableDeliveryTypes,
  dlvryTypes,
) => {
  const deliveryTypesAvailableByPolygon = await getDeliveryTypes(
    country,
    store,
  );

  return deliveryTypesAvailableByPolygon.length > 0
    ? deliveryTypesAvailableByPolygon.map(value => value.toLowerCase())
    : dlvryTypes;
};

export const loadRequirements = async (
  store,
  { countryCode, deliveryTypes: dlvryTypes, steps },
) => {
  const country = countryCode || "CO";
  let availableDeliveryTypes = dlvryTypes.map(type => type.toLowerCase());

  if (country === "BR") {
    availableDeliveryTypes = await getFilteredDeliveryTypes(
      country,
      store,
      availableDeliveryTypes,
      dlvryTypes,
    );
  }

  const additionalFields = steps[2] || [];
  const termsVersionPerDeliveryType =
    contractsPerCountry[country] || contractsPerCountry.CO;
  const rappiSocialReason =
    rappiSocialReasonNamePerCountry[country] ||
    rappiSocialReasonNamePerCountry.CO;
  const deliveryTypes = (
    countryDeliveryTypes[country] || countryDeliveryTypes.default
  ).filter(({ value }) => availableDeliveryTypes.includes(value));
  const validationSchema = buildValidationSchema(additionalFields);

  return {
    deliveryTypes,
    additionalFields,
    validationSchema,
    termsVersionPerDeliveryType,
    rappiSocialReason,
  };
};

const defaultValues = {
  deliveryMethod: "",
  version: "",
  isTermsChecked: false,
  isDataChecked: false,
  areTermsRead: false,
  additionalFields: {},
};

// eslint-disable-next-line camelcase
export const getInitialValues = ({ terms = null, catalog_info = {} }) => {
  const initialValues = {
    ...terms,
    isTermsChecked: !!terms,
    isDataChecked: !!terms,
    areTermsRead: !!terms,
    deliveryMethod: catalog_info.delivery_method
      ? catalog_info.delivery_method.toLowerCase()
      : null,
  };
  return mergeWithDefault(initialValues, defaultValues);
};

const ServiceModelFormWithNotifier = withNotifier(ServiceModelForm);
const ConfiguredForm = ({
  userInfo = {},
  onFormChange,
  deliveryTypes,
  additionalFields,
  termsVersionPerDeliveryType,
  validationSchema,
  rappiSocialReason,
}) => {
  let initialValues = getInitialValues(userInfo);
  initialValues = isEmpty(initialValues) ? defaultValues : initialValues;

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        onFormChange(props);
        return (
          <ServiceModelFormWithNotifier
            {...props}
            additionalFields={additionalFields}
            deliveryTypes={deliveryTypes}
            termsVersionPerDeliveryType={termsVersionPerDeliveryType}
            rappiSocialReason={rappiSocialReason}
          />
        );
      }}
    </Formik>
  );
};

ConfiguredForm.defaultProps = {
  userInfo: {},
  additionalFields: [],
};

ConfiguredForm.propTypes = {
  userInfo: PropTypes.object,
  onFormChange: PropTypes.func.isRequired,
  deliveryTypes: PropTypes.array.isRequired,
  additionalFields: PropTypes.array,
  termsVersionPerDeliveryType: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  rappiSocialReason: PropTypes.string.isRequired,
};

export default ConfiguredForm;
