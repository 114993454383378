import { bool, object, string } from "yup";
import { makeAdditionalSchema } from "../../../fieldsFactory";

const validationDefaultShape = {
  isTermsChecked: bool()
    .required()
    .oneOf([true]),
  isDataChecked: bool()
    .required()
    .oneOf([true]),
  areTermsRead: bool()
    .required()
    .oneOf([true]),
  version: string().required(),
  deliveryMethod: string().required(),
};

const buildSchema = additionalFields =>
  object().shape({
    ...validationDefaultShape,
    additionalFields: object().shape({
      ...makeAdditionalSchema(additionalFields),
    }),
  });

export default buildSchema;
