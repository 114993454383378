import { translateKey } from "../../../utils/translate";
import ArgentinianFlag from "../../../assets/images/flags/ar.svg";
import BrazilianFlag from "../../../assets/images/flags/br.svg";
import ChileanFlag from "../../../assets/images/flags/cl.svg";
import ColombianFlag from "../../../assets/images/flags/co.svg";
import MexicanFlag from "../../../assets/images/flags/mx.svg";
import PeruvianFlag from "../../../assets/images/flags/pe.svg";
import UruguayanFlag from "../../../assets/images/flags/uy.svg";
import EcuatorianFlag from "../../../assets/images/flags/ec.svg";
import CostaRicaFlag from "../../../assets/images/flags/cr.svg";

export const countries = [
  { label: "Argentina", value: "AR", icon: ArgentinianFlag },
  { label: "Brasil", value: "BR", icon: BrazilianFlag },
  { label: "Chile", value: "CL", icon: ChileanFlag },
  { label: "Colombia", value: "CO", icon: ColombianFlag },
  { label: "México", value: "MX", icon: MexicanFlag },
  { label: "Perú", value: "PE", icon: PeruvianFlag },
  { label: "Uruguay", value: "UY", icon: UruguayanFlag },
  { label: "Costa Rica", value: "CR", icon: CostaRicaFlag },
  { label: "Ecuador", value: "EC", icon: EcuatorianFlag },
];

export const phonePrefixes = [
  { label: "+54", value: "+54", icon: ArgentinianFlag, country: "AR" },
  { label: "+55", value: "+55", icon: BrazilianFlag, country: "BR" },
  { label: "+56", value: "+56", icon: ChileanFlag, country: "CL" },
  { label: "+57", value: "+57", icon: ColombianFlag, country: "CO" },
  { label: "+52", value: "+52", icon: MexicanFlag, country: "MX" },
  { label: "+51", value: "+51", icon: PeruvianFlag, country: "PE" },
  { label: "+598", value: "+598", icon: UruguayanFlag, country: "UY" },
  { label: "+506", value: "+506", icon: CostaRicaFlag, country: "CR" },
  { label: "+593", value: "+593", icon: EcuatorianFlag, country: "EC" },
];

export const preferredContactOptions = [
  { label: translateKey("form.inbound.whatsApp"), value: "whatsapp" },
  { label: translateKey("form.inbound.email"), value: "email" },
  { label: translateKey("form.inbound.sms"), value: "sms" },
  {
    label: translateKey("form.inbound.phoneCall"),
    value: "phoneCall",
  },
];

export const currencies = {
  CO: "COP",
  MX: "MXN",
  AR: "Pesos",
  CL: "Pesos",
  BR: "REAIS",
  UY: "Pesos",
  CR: "Colon",
  EC: "USD",
  PE: "Soles",
};

export const countriesTaxonomies = {
  CO: [
    { label: "Alitas", value: "Alitas" },
    { label: "Alta Cocina", value: "Alta Cocina" },
    { label: "Árabe", value: "Árabe" },
    { label: "Asiática", value: "Asiática" },
    { label: "Café", value: "Café" },
    { label: "China", value: "China" },
    { label: "Comida Rápida", value: "Comida Rápida" },
    { label: "Crepes", value: "Crepes" },
    { label: "Desayunos", value: "Desayunos" },
    { label: "Empanadas", value: "Empanadas" },
    { label: "Hamburguesas", value: "Hamburguesas" },
    { label: "Helados", value: "Helados" },
    { label: "India", value: "India" },
    { label: "Internacional", value: "Internacional" },
    { label: "Italiana", value: "Italiana" },
    { label: "Jugos y Batidos", value: "Jugos y Batidos" },
    { label: "Mexicana", value: "Mexicana" },
    { label: "Panadería", value: "Panadería" },
    { label: "Parrilla", value: "Parrilla" },
    { label: "Perros Calientes", value: "Perros Calientes" },
    { label: "Peruana", value: "Peruana" },
    { label: "Pescados y Mariscos", value: "Pescados y Mariscos" },
    { label: "Pizza", value: "Pizza" },
    { label: "Poke", value: "Poke" },
    { label: "Pollo", value: "Pollo" },
    { label: "Postres", value: "Postres" },
    { label: "Saludable", value: "Saludable" },
    { label: "Sánduches", value: "Sánduches" },
    { label: "Sushi", value: "Sushi" },
    { label: "Típica", value: "Típica" },
    { label: "Vegetariana", value: "Vegetariana" },
  ],
  BR: [
    { label: "Açaí", value: "Açaí" },
    { label: "Árabe", value: "Árabe" },
    { label: "Asiática", value: "Asiática" },
    { label: "Brasileira", value: "Brasileira" },
    { label: "Burgers", value: "Burgers" },
    { label: "Café", value: "Café" },
    { label: "Café da Manhã", value: "Café da Manhã" },
    { label: "Chefs", value: "Chefs" },
    { label: "Congelados", value: "Congelados" },
    { label: "Crepes", value: "Crepes" },
    { label: "Francês", value: "Francês" },
    { label: "Grelhado", value: "Grelhado" },
    { label: "Italiana", value: "Italiana" },
    { label: "Japonesa", value: "Japonesa" },
    { label: "Mediterrânea", value: "Mediterrânea" },
    { label: "Mexicana", value: "Mexicana" },
    { label: "Padaria", value: "Padaria" },
    { label: "Peruana", value: "Peruana" },
    { label: "Pizza", value: "Pizza" },
    { label: "Sanduíches", value: "Sanduíches" },
    { label: "Saudável", value: "Saudável" },
    { label: "Sobremesas", value: "Sobremesas" },
    { label: "Sopas", value: "Sopas" },
    { label: "Sorvetes", value: "Sorvetes" },
    { label: "Sucos", value: "Sucos" },
    { label: "Vegetariana", value: "Vegetariana" },
    { label: "Xis", value: "Xis" },
  ],
  MX: [
    { label: "Alta Cocina", value: "Alta Cocina" },
    { label: "Árabe", value: "Árabe" },
    { label: "Asiática", value: "Asiática" },
    { label: "Café", value: "Café" },
    { label: "China", value: "China" },
    { label: "Comida Rápida", value: "Comida Rápida" },
    { label: "Desayunos", value: "Desayunos" },
    { label: "Hamburguesas", value: "Hamburguesas" },
    { label: "Helados", value: "Helados" },
    { label: "India", value: "India" },
    { label: "Internacional", value: "Internacional" },
    { label: "Italiana", value: "Italiana" },
    { label: "Jugos y Batidos", value: "Jugos y Batidos" },
    { label: "Mexicana", value: "Mexicana" },
    { label: "Panadería", value: "Panadería" },
    { label: "Pescados y Mariscos", value: "Pescados y Mariscos" },
    { label: "Pizza", value: "Pizza" },
    { label: "Poke", value: "Poke" },
    { label: "Pollo", value: "Pollo" },
    { label: "Postres", value: "Postres" },
    { label: "Saludables", value: "Saludables" },
    { label: "Sándwiches", value: "Sándwiches" },
    { label: "Sushi", value: "Sushi" },
    { label: "Tacos", value: "Tacos" },
    { label: "Vegetariana", value: "Vegetariana" },
    { label: "Wings", value: "Wings" },
  ],
  AR: [
    { label: "Árabe", value: "Árabe" },
    { label: "Argentina", value: "Argentina" },
    { label: "Asado", value: "Asado" },
    { label: "Asiática", value: "Asiática" },
    { label: "Café", value: "Café" },
    { label: "Desayunos y Meriendas", value: "Desayunos y Meriendas" },
    { label: "Empanadas", value: "Empanadas" },
    { label: "Hamburguesas", value: "Hamburguesas" },
    { label: "Helados", value: "Helados" },
    { label: "Hot Dogs", value: "Hot Dogs" },
    { label: "Internacional", value: "Internacional" },
    { label: "Italiana", value: "Italiana" },
    { label: "Jugos", value: "Jugos" },
    { label: "Mexicana", value: "Mexicana" },
    { label: "Milanesas", value: "Milanesas" },
    { label: "Panadería", value: "Panadería" },
    { label: "Peruana", value: "Peruana" },
    { label: "Pescados", value: "Pescados" },
    { label: "Pizza", value: "Pizza" },
    { label: "Pokes", value: "Pokes" },
    { label: "Pollo", value: "Pollo" },
    { label: "Postres", value: "Postres" },
    { label: "Saludable", value: "Saludable" },
    { label: "Sándwiches", value: "Sándwiches" },
    { label: "Sushi", value: "Sushi" },
    { label: "Vegetariana", value: "Vegetariana" },
  ],
  CL: [
    { label: "Árabe", value: "Árabe" },
    { label: "Asiática", value: "Asiática" },
    { label: "Café", value: "Café" },
    { label: "Carnes", value: "Carnes" },
    { label: "Comida Casera", value: "Comida Casera" },
    { label: "De Autor", value: "De Autor" },
    { label: "Desayunos", value: "Desayunos" },
    { label: "Hamburguesas", value: "Hamburguesas" },
    { label: "Helados", value: "Helados" },
    { label: "Hot Dogs", value: "Hot Dogs" },
    { label: "Internacional", value: "Internacional" },
    { label: "Italiana", value: "Italiana" },
    { label: "Jugos y Batidos", value: "Jugos y Batidos" },
    { label: "Mexicana", value: "Mexicana" },
    { label: "Pastelería y Postres", value: "Pastelería y Postres" },
    { label: "Peruana", value: "Peruana" },
    { label: "Pescados y Mariscos", value: "Pescados y Mariscos" },
    { label: "Pizzas", value: "Pizzas" },
    { label: "Pollo", value: "Pollo" },
    { label: "Saludable", value: "Saludable" },
    { label: "Sándwiches", value: "Sándwiches" },
    { label: "Sushi", value: "Sushi" },
    { label: "Vegetariana", value: "Vegetariana" },
  ],
  UY: [
    { label: "Alta Cocina", value: "Alta Cocina" },
    { label: "Árabe", value: "Árabe" },
    { label: "Asiática", value: "Asiática" },
    { label: "Cafetería", value: "Cafetería" },
    { label: "Chivitos", value: "Chivitos" },
    { label: "Comida Rápida", value: "Comida Rápida" },
    { label: "Desayunos y Meriendas", value: "Desayunos y Meriendas" },
    { label: "Empanadas", value: "Empanadas" },
    { label: "Hamburguesas", value: "Hamburguesas" },
    { label: "Helados", value: "Helados" },
    { label: "Internacional", value: "Internacional" },
    { label: "Italiana", value: "Italiana" },
    { label: "Milanesas", value: "Milanesas" },
    { label: "Panadería", value: "Panadería" },
    { label: "Panchos", value: "Panchos" },
    { label: "Parrilla", value: "Parrilla" },
    { label: "Pescados y Mariscos", value: "Pescados y Mariscos" },
    { label: "Pizza", value: "Pizza" },
    { label: "Postres", value: "Postres" },
    { label: "Saludable", value: "Saludable" },
    { label: "Sándwiches", value: "Sándwiches" },
    { label: "Sushi", value: "Sushi" },
    { label: "Uruguaya", value: "Uruguaya" },
    { label: "Vegetariana", value: "Vegetariana" },
  ],
  CR: [
    { label: "Alitas", value: "Alitas" },
    { label: "Árabe", value: "Árabe" },
    { label: "Asiática", value: "Asiática" },
    { label: "Café", value: "Café" },
    { label: "Carnes", value: "Carnes" },
    { label: "Del Mar", value: "Del Mar" },
    { label: "Desayuno", value: "Desayuno" },
    { label: "Ensaladas", value: "Ensaladas" },
    { label: "Hamburguesas", value: "Hamburguesas" },
    { label: "Helados", value: "Helados" },
    { label: "India", value: "India" },
    { label: "Italiana", value: "Italiana" },
    { label: "Jugos", value: "Jugos" },
    { label: "Mexicana", value: "Mexicana" },
    { label: "Panadería", value: "Panadería" },
    { label: "Pizza", value: "Pizza" },
    { label: "Poke", value: "Poke" },
    { label: "Postres", value: "Postres" },
    { label: "Saludable", value: "Saludable" },
    { label: "Sándwiches", value: "Sándwiches" },
    { label: "Sushi", value: "Sushi" },
    { label: "Típica", value: "Típica" },
    { label: "Vegetariana", value: "Vegetariana" },
  ],
  EC: [
    { label: "Alitas", value: "Alitas" },
    { label: "Alta Cocina", value: "Alta Cocina" },
    { label: "Árabe", value: "Árabe" },
    { label: "Asiática", value: "Asiática" },
    { label: "Café", value: "Café" },
    { label: "Ceviches", value: "Ceviches" },
    { label: "Comida Rápida", value: "Comida Rápida" },
    { label: "Desayuno", value: "Desayuno" },
    { label: "Ecuatoriana", value: "Ecuatoriana" },
    { label: "Hamburguesas", value: "Hamburguesas" },
    { label: "Helados", value: "Helados" },
    { label: "Hot Dog", value: "Hot Dog" },
    { label: "India", value: "India" },
    { label: "Internacional", value: "Internacional" },
    { label: "Italiana", value: "Italiana" },
    { label: "Jugos y Batidos", value: "Jugos y Batidos" },
    { label: "Mexicana", value: "Mexicana" },
    { label: "Panadería", value: "Panadería" },
    { label: "Parrilla", value: "Parrilla" },
    { label: "Peruana", value: "Peruana" },
    { label: "Pescados y Mariscos", value: "Pescados y Mariscos" },
    { label: "Pizza", value: "Pizza" },
    { label: "Poke", value: "Poke" },
    { label: "Pollo", value: "Pollo" },
    { label: "Postres", value: "Postres" },
    { label: "Saludable", value: "Saludable" },
    { label: "Sánduches", value: "Sánduches" },
    { label: "Sushi", value: "Sushi" },
    { label: "Vegetariana", value: "Vegetariana" },
  ],
  PE: [
    { label: "Alitas", value: "Alitas" },
    { label: "Árabe", value: "Árabe" },
    { label: "Asiática", value: "Asiática" },
    { label: "Café", value: "Café" },
    { label: "Carnes", value: "Carnes" },
    { label: "Chifa", value: "Chifa" },
    { label: "Criolla", value: "Criolla" },
    { label: "Desayunos", value: "Desayunos" },
    { label: "Experiencias", value: "Experiencias" },
    { label: "Hamburguesas", value: "Hamburguesas" },
    { label: "Helados", value: "Helados" },
    { label: "India", value: "India" },
    { label: "Internacional", value: "Internacional" },
    { label: "Jugos y Batidos", value: "Jugos y Batidos" },
    { label: "Mexicana", value: "Mexicana" },
    { label: "Panadería", value: "Panadería" },
    { label: "Pastas", value: "Pastas" },
    { label: "Pescados y Mariscos", value: "Pescados y Mariscos" },
    { label: "Pizzas", value: "Pizzas" },
    { label: "Poke", value: "Poke" },
    { label: "Pollería", value: "Pollería" },
    { label: "Postres", value: "Postres" },
    { label: "Salchipapas", value: "Salchipapas" },
    { label: "Saludable", value: "Saludable" },
    { label: "Sándwiches", value: "Sándwiches" },
    { label: "Sopas y Cremas", value: "Sopas y Cremas" },
    { label: "Sushi", value: "Sushi" },
    { label: "Vegetariana", value: "Vegetariana" },
  ],
};

export const tradeTypes = [
  {
    label: translateKey("form.inbound.restaurant"),
    value: "restaurant",
  },
  {
    label: translateKey("form.inbound.supermarket"),
    value: "supermarket",
  },
  { label: translateKey("form.inbound.liqueurs"), value: "liqueurs" },
  {
    label: translateKey("form.inbound.technology"),
    value: "technology",
  },
  { label: translateKey("form.inbound.fashion"), value: "fashion" },
  { label: translateKey("form.inbound.beauty"), value: "beauty" },
  { label: translateKey("form.inbound.pets"), value: "pets" },
  {
    label: translateKey("form.inbound.gardening"),
    value: "gardening",
  },
  {
    label: translateKey("form.inbound.corporateBrands"),
    value: "corporateBrands",
  },
];

export const salesPoints = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  { label: translateKey("form.inbound.tenOrMore"), value: "+10" },
];

const salesRanges = {
  CO: [0, 2000000, 4000000, 7000000, 9000000],
  MX: [0, 10000, 20000, 40000, 60000],
  AR: [0, 40000, 70000, 140000, 180000],
  CL: [0, 400000, 800000, 1600000, 2000000],
  BR: [0, 3000, 5000, 11000, 13000],
  UY: [0, 20000, 40000, 90000, 110000],
  CR: [0, 300000, 600000, 1200000, 1500000],
  EC: [0, 500, 1000, 2000, 2500],
  PE: [0, 2000, 4000, 7000, 9000],
};

const numberWithSeparators = (number, separator = ".") =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);

const createSaleRangesOptions = ranges => {
  let label;
  let value;
  let upper;
  const values = {};
  Object.keys(ranges).forEach(country => {
    const options = ranges[country].map((lower, index) => {
      upper = ranges[country][index + 1];
      value = `${numberWithSeparators(lower)} ${
        upper ? `- ${numberWithSeparators(upper)}` : "+"
      }`;
      label = `${currencies[country]} ${value}`;
      return { value, label };
    });

    values[country] = options;
  });

  return values;
};

export const salesRangesPerMonth = createSaleRangesOptions(salesRanges);
