import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import classnames from "classnames";
import Dropzone from "react-dropzone";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFileOutlined";
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffSharp";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import { BrowserView, MobileView } from "react-device-detect";
import Link from "@material-ui/core/Link";
import FieldDescriptor from "../FieldDescriptor";
import { translateKey } from "../../../utils/translate";
import ModalDocumentExample from "../../pages/NewInbound/PageComponents/Modal/DocumentExample/ModalDocumentExample";

const MAX_FILE_SIZE = 2 * 1000 * 1000; // MAX 2 MB :D
const ALLOWED_EXTENSIONS = [".png", ".jpg", ".pdf", ".jpeg"];
const DEFAULT_ACCEPT_DIMENSIONS = [400, 400];

class FileInput extends Component {
  state = {
    file: null,
    filePath: "",
    progress: null,
    showModal: false,
  };

  componentDidMount() {
    const { shareManager, name } = this.props;
    shareManager(name, {
      updateProgress: this.progressUpdater,
      removeFile: this.removeFile,
    });
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (
      nextProps.imageExample &&
      this.state.showModal !== nextState.showModal
    ) {
      return true;
    }
    const { file } = this.state;
    if (file === null && nextProps.value) {
      const index = nextProps.value.lastIndexOf("/") + 1;
      this.setState({
        file: {
          size: 2 * 1024 * 1024,
          name: nextProps.value.substring(index),
        },
        filePath: nextProps.value,
        progress: 100,
      });
      return true;
    }

    if (nextState.file == null && file != null) {
      return true;
    }
    if (nextProps.disabled !== this.props.disabled) {
      return true;
    }

    return !!nextState.file;
  };

  getImageDimensions = file => {
    const image = new Image();

    return new Promise(resolve => {
      image.onload = () => {
        resolve([image.naturalWidth, image.naturalHeight]);
      };

      image.src = window.URL.createObjectURL(file);
    });
  };

  onFiles = async files => {
    const [file] = files;

    if (!file) return;

    const { name, size } = file;
    const extension = `.${name.split(".").pop()}`.toLowerCase();
    const { minSizes, name: fieldName, onChange, onError } = this.props;

    if (size <= MAX_FILE_SIZE && ALLOWED_EXTENSIONS.includes(extension)) {
      const isImage = [".jpg", ".jpeg", ".png"].includes(extension);
      const [minWidth, minHeight] = minSizes;
      const [width, height] = isImage
        ? await this.getImageDimensions(file)
        : minSizes;

      if (width >= minWidth && height >= minHeight) {
        this.setState({ file: files[0], filePath: file, progress: 0 }, () => {
          onChange(
            { name: fieldName, file },
            {
              updateProgress: this.progressUpdater,
              removeFile: this.removeFile,
            },
          );
        });
      } else {
        const errorMessage = translateKey("form.attachFilesForm.invalidFile")
          .replace("':filename'", name)
          .replace("':width'", minWidth)
          .replace("':height'", minHeight);
        onError(file, errorMessage);
      }
    } else {
      onError(file);
    }
  };

  removeFile = () => {
    this.setState({ file: null, filePath: null, progress: null }, () =>
      this.props.onChange(
        { name: this.props.name, file: null },
        {
          updateProgress: () => {},
          removeFile: this.removeFile,
        },
      ),
    );
  };

  progressUpdater = progress => this.setState({ progress });

  toKiloBytes = size => size / 1024;

  handleShowModal = event => {
    this.setState({ showModal: true });
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  getTitle = (image, tooltip, accept) => {
    if (image) {
      return translateKey("fileInput.seeDocumentExample");
    }
    return (
      tooltip ||
      `${translateKey("fileInput.fileFormat")} ${accept}. ${translateKey(
        "fileInput.lessThan2Mb",
      )}`
    );
  };

  renderFileDropZone = () => {
    const { filePath } = this.state;
    const {
      classes,
      name,
      accept,
      tooltip,
      disabled,
      imageExample,
      minSizes,
    } = this.props;

    const [width, height] = minSizes;

    const title =
      tooltip ||
      `${translateKey("fileInput.fileFormat")} ${accept}. 
      ${translateKey("fileInput.lessThan2Mb")}.
      ${translateKey("fileInput.minimumSizes")
        .replace("':width'", width)
        .replace("':height'", height)}.`;

    const rootClass = classnames(
      classes.dropzone,
      disabled ? classes.disabled : "",
    );

    return (
      <Dropzone onDrop={this.onFiles} disabled={disabled}>
        {dropManager => (
          <Grid
            container
            justify="center"
            alignContent="center"
            {...dropManager.getRootProps()}
            className={rootClass}
          >
            <Grid item sm={12} md={11} container>
              <input
                type="file"
                name={name}
                defaultValue={filePath}
                className={classes.file}
                disabled={disabled}
                accept={accept}
                {...dropManager.getInputProps()}
              />
              <Grid container justify="center">
                <BrowserView>
                  <span className={classes.title}>
                    {translateKey("fileInput.dragHereOr")}
                    <strong className={classes.button}>
                      {translateKey("fileInput.select")}
                    </strong>
                  </span>
                </BrowserView>
                <MobileView>
                  <span className={classes.title}>
                    {translateKey("fileInput.press")}
                    <strong className={classes.button}>
                      {translateKey("fileInput.here")}
                    </strong>
                    {translateKey("fileInput.toAttach")}
                  </span>
                </MobileView>
              </Grid>
              <Grid container justify="center">
                {imageExample ? (
                  <Link
                    href="#section"
                    component="button"
                    onClick={this.handleShowModal}
                  >
                    {translateKey("fileInput.seeDocumentExample")}
                  </Link>
                ) : (
                  <span className={classes.subtitle}>
                    {translateKey("fileInput.lessThan2Mb")}
                  </span>
                )}
              </Grid>
            </Grid>
            <Grid item md={1} className={classes.iconContainer}>
              <Tooltip
                arrow
                title={title}
                classes={{
                  tooltip: classes.tooltip,
                  arrow: classes.arrow,
                }}
                placement="bottom-end"
              >
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
            </Grid>
          </Grid>
        )}
      </Dropzone>
    );
  };

  renderFileLoaded = () => {
    const { classes, disabled } = this.props;
    const { file } = this.state;
    return (
      <Grid
        container
        justify="center"
        alignContent="center"
        className={classnames(
          classes.fileLoaded,
          disabled ? classes.disabled : "",
        )}
        data-test="file-loaded"
      >
        <Grid item xs={2} container justify="center" alignContent="center">
          <InsertDriveFileIcon className={classes.fileIcon} />
        </Grid>
        <Grid item xs={8}>
          <span className={classes.fileName}>{file.name}</span>
          <span className={classes.fileSize}>
            {`${(file.size / 1024).toFixed(2)} KB`}
          </span>
        </Grid>
        <Grid item xs={2} container justify="center" alignContent="center">
          <HighlightOffTwoToneIcon
            data-test="remove-file"
            className={classes.removeIcon}
            onClick={this.removeFile}
          />
        </Grid>
      </Grid>
    );
  };

  renderFileLoading = () => {
    const { classes } = this.props;
    const { file, progress } = this.state;
    return (
      <Grid
        container
        justify="center"
        alignContent="center"
        className={classes.fileLoading}
      >
        <Grid item xs={12}>
          <LinearProgress
            variant="determinate"
            value={progress}
            className={classes.progressBar}
            data-test="file-upload-progress"
          />
          <span className={classes.fileProgressText}>
            {`${(this.toKiloBytes(file.size) * (progress / 100)).toFixed(
              2,
            )} KB ${translateKey("fileInput.of")} ${this.toKiloBytes(
              file.size,
            ).toFixed(2)} KB (${progress}% ${translateKey(
              "fileInput.completed",
            )})`}
          </span>
        </Grid>
      </Grid>
    );
  };

  renderByCurrentState = () => {
    const { file, progress } = this.state;
    if (!file) return this.renderFileDropZone();
    return progress === 100
      ? this.renderFileLoaded()
      : this.renderFileLoading();
  };

  render() {
    const { name, classes, placeholder, imageExample } = this.props;
    const { showModal } = this.state;
    return (
      <>
        <FieldDescriptor title={placeholder} />
        <Grid container className={classes.root} data-test={name}>
          {this.renderByCurrentState()}
        </Grid>
        <ModalDocumentExample
          showModal={showModal}
          closeCallback={this.handleCloseModal}
          image={imageExample}
          buttonText={translateKey("form.inbound.modal.buttonText")}
        />
      </>
    );
  }
}

FileInput.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  shareManager: PropTypes.func,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  minSizes: PropTypes.array,
  tooltip: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  imageExample: PropTypes.string,
  showModal: PropTypes.bool,
};

FileInput.defaultProps = {
  shareManager: () => {},
  error: null,
  tooltip: null,
  value: null,
  disabled: false,
  imageExample: null,
  showModal: false,
  minSizes: DEFAULT_ACCEPT_DIMENSIONS,
};

export default FileInput;
