import React from "react";
import PropTypes from "prop-types";
import { Grid, withStyles } from "@material-ui/core";
import { ErrorMessage } from "formik";
import { GoogleApiWrapper } from "google-maps-react";
import Select from "../../atoms/Select";
import TextField from "../../atoms/TextField";
import PlaceField from "../PlaceField";
import { translateKey } from "../../../utils/translate";
import { getCoverage } from "../../../api/coverage";
import styles from "./NewLead.style";
import { onHandlerNumber } from "../../../schemasAndConstraints/utils/constraintBuilders";

const onPlaceChange = (
  fieldName,
  values,
  setValues,
  notifier,
) => async placeInfo => {
  let isWithinCoverage;
  const { name, lat, lng } = placeInfo;
  const notifierProps = { preventDuplicate: true };
  const isNameField = fieldName === "name";
  const isAddressField = fieldName === "address";

  setValues({
    ...values,
    data: {
      ...values.data,
      name: isNameField ? name : values.data.name,
      address: isAddressField ? name : values.data.address,
      google_place_id: null,
    },
  });
  if (!lat || !lng) return;

  if(
    !placeInfo.postalCode && !placeInfo.type.includes("sublocality") 
  && !placeInfo.subArea && !placeInfo.type.includes("bar") 
  && !placeInfo.type.includes("food") && !placeInfo.type.includes("restaurant")
  && !placeInfo.type.includes("meal_delivery")
  ){
    setValues({ ...values, data: { ...values.data, name: "", address: "" } });
    notifier.error(translateKey("errors.invalid.address"), notifierProps);
    return;
  }

  try {
    isWithinCoverage = await getCoverage(values.country, lat, lng);
  } catch {
    setValues({ ...values, data: { ...values.data, name: "", address: "" } });
    notifier.error(translateKey("errors.http.unexpectedError"), notifierProps);
    return;
  }


  if (!isWithinCoverage) {
    setValues({ ...values, data: { ...values.data, name: "", address: "" } });
    notifier.error(translateKey("errors.hasntCoverage"), notifierProps);
  } else {
    setValues({
      ...values,
      data: {
        ...values.data,
        name: isAddressField ? values.data.name : name,
        latitude: lat,
        longitude: lng,
        google_place_id: placeInfo.placeId,
        address: placeInfo.fullAddress,
        external_id: placeInfo.placeId,
        zone: placeInfo.city,
        country: placeInfo.country,
        type: placeInfo.type,
        review_avg: placeInfo.avg,
        total_reviews: placeInfo.reviews,
        price_level: placeInfo.priceLevel,
      },
    });
  }
};

const NewLeadForm = ({
  values,
  classes,
  handleChange,
  setFieldTouched,
  setValues,
  touched,
  errors,
  google,
  teamOptions,
  answerOptions,
  salePointsOptions,
  notifier,
  taxonomies,
  countries,
  roleOptions,
  resetForm,
}) => (
  <Grid container style={{ padding: "24px 0 24px 0" }}>
    <Grid item xs={12} md={6}>
      <Grid item xs={12} className={classes.field}>
        <Select
          title={translateKey("form.inbound.selectCountry")}
          name="country"
          value={values.country}
          onChange={evt => {
            resetForm();
            handleChange(evt);
          }}
          onBlur={() => setFieldTouched("country", true)}
          placeholder={translateKey("form.select")}
          options={countries}
          error={touched.country && errors.country ? errors.country : ""}
        />
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <Select
          title={translateKey("form.newLeadsForm.role")}
          name="data.role"
          value={values.data.role || "none"}
          onChange={handleChange}
          onBlur={() => setFieldTouched("data.role", true)}
          placeholder={translateKey("form.newLeadsForm.enter.select")}
          options={roleOptions[values.country] || roleOptions.other}
          error={
            touched.data && errors.data && touched.data.role && errors.data.role
              ? errors.data.role
              : ""
          }
        />
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <PlaceField
          editable
          title={translateKey("form.newLeadsForm.restaurantName")}
          google={google}
          name="data.name"
          country={values.country}
          onChange={onPlaceChange("name", values, setValues, notifier)}
          onBlur={() => setFieldTouched("data.name", true)}
          placeholder={translateKey("form.newLeadsForm.enter.select")}
          preservedValue={values.data.name}
        />
      </Grid>

      <Grid item xs={12} className={classes.field}>
        <PlaceField
          editable
          google={google}
          name="data.address"
          title={translateKey("form.newLeadsForm.restaurantAddress")}
          country={values.country}
          onChange={onPlaceChange("address", values, setValues, notifier)}
          onBlur={() => setFieldTouched("data.address", true)}
          placeholder={translateKey("form.newLeadsForm.enter.address")}
          preservedValue={values.data.address}
        />
        <ErrorMessage
          name="data.address"
          key="data.address"
          component="div"
          className={classes.error}
        />
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <Select
          name="data.categories"
          title={translateKey("form.newLeadsForm.foodCategory")}
          value={values.data.categories || "none"}
          onChange={handleChange}
          onBlur={() => setFieldTouched("data.categories", true)}
          placeholder={translateKey("form.newLeadsForm.enter.select")}
          options={taxonomies[values.country]}
          error={
            touched.data &&
            errors.data &&
            touched.data.categories &&
            errors.data.categories
              ? errors.data.categories
              : ""
          }
        />
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <TextField
          title={translateKey("form.newLeadsForm.instagramAccount")}
          name="data.instagram_account"
          value={values.data.instagram_account}
          placeholder={translateKey("form.newLeadsForm.enter.instagramAccount")}
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <TextField
          title={translateKey("form.newLeadsForm.restaurantNumber")}
          name="data.phone"
          value={values.data.phone}
          placeholder={translateKey("form.newLeadsForm.enter.phone")}
          handleChange={handleChange}
          onInput={onHandlerNumber}
          setFieldTouched={setFieldTouched}
        />
      </Grid>
    </Grid>
    <Grid item xs={12} md={6}>
      <Grid item xs={12} className={classes.field}>
        <Select
          title={translateKey("form.newLeadsForm.nSalePoints")}
          name="data.total_sales_point"
          value={values.data.total_sales_point || "none"}
          onChange={handleChange}
          onBlur={() => setFieldTouched("data.total_sales_point", true)}
          placeholder={translateKey("form.newLeadsForm.enter.select")}
          options={salePointsOptions}
          error={
            touched.data &&
            errors.data &&
            touched.data.total_sales_point &&
            errors.data.total_sales_point
              ? errors.data.total_sales_point
              : ""
          }
        />
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <Select
          title={translateKey("form.newLeadsForm.questions.isFranchise")}
          name="data.franchised"
          value={
            values.data.franchised === null ||
            values.data.franchised === undefined
              ? "none"
              : Boolean(values.data.franchised)
          }
          onChange={handleChange}
          onBlur={() => setFieldTouched("data.franchised", true)}
          placeholder={translateKey("form.newLeadsForm.enter.select")}
          options={answerOptions[values.country] || answerOptions.other}
          error={
            touched.data &&
            errors.data &&
            touched.data.franchised &&
            errors.data.franchised
              ? errors.data.franchised
              : ""
          }
        />
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <TextField
          title={translateKey("form.inbound.referralCode")}
          name="data.referral_code"
          value={values.data.referral_code}
          placeholder={translateKey("form.writeHere")}
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <TextField
          title={translateKey("form.newLeadsForm.hunterEmail")}
          name="data.email_hunter"
          value={values.data.email_hunter}
          placeholder={translateKey("form.newLeadsForm.enter.email")}
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <Select
          title={translateKey("form.newLeadsForm.team")}
          name="data.team"
          value={values.data.team || "none"}
          onChange={handleChange}
          onBlur={() => setFieldTouched("data.team", true)}
          placeholder={translateKey("form.newLeadsForm.enter.select")}
          options={teamOptions[values.country] || teamOptions.other}
          error={
            touched.data && errors.data && touched.data.team && errors.data.team
              ? errors.data.team
              : ""
          }
        />
      </Grid>
      <Grid item xs={12} className={classes.field}>
        <TextField
          title={translateKey("form.newLeadsForm.leaderEmail")}
          name="data.email_leader"
          value={values.data.email_leader}
          placeholder={translateKey("form.newLeadsForm.enter.email")}
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </Grid>
    </Grid>
  </Grid>
);

NewLeadForm.propTypes = {
  classes: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  google: PropTypes.object.isRequired,
  teamOptions: PropTypes.array.isRequired,
  answerOptions: PropTypes.string.isRequired,
  salePointsOptions: PropTypes.array.isRequired,
  taxonomies: PropTypes.array.isRequired,
  notifier: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  roleOptions: PropTypes.array.isRequired,
  resetForm: PropTypes.func.isRequired,
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  libraries: ["places"],
})(withStyles(styles)(NewLeadForm));
