import { object, string, bool } from "yup";
import getPhoneSchema from "../../../schemasAndConstraints/phones/schemas";
import isAddressTypeValid from "../../../utils/isAddressTypeValid";
import { translateKey } from "../../../utils/translate";

const FIELD_BINDINGS = {
  phone: "phone_prefix",
};

const validationSchema = object().shape({
  data: object().shape({
    owners_name: string().required(),
    owners_last_name: string().required(),
    phone_prefix: string().required(),
    phone: getPhoneSchema(null, FIELD_BINDINGS.phone),
    name: string().required(),
    email: string()
      .email()
      .required(),
    address: string()
      .required(translateKey("form.required"))
      .when("google_place_id", (value, schema) =>
        schema.test(
          "hasPlaceId",
          translateKey("inboundPage.form.errors.selectAddressFromSuggestions"),
          () => Boolean(value),
        ),
      )
      .when("type", (value, schema) =>
        schema.test(
          "isTypeValid",
          translateKey("inboundPage.form.errors.invalidAddressType"),
          () => isAddressTypeValid(value, ", "),
        ),
      ),
    total_sales_point: string().required(),
    categories: string().required(),
  }),
  contact_agreement: bool()
    .required()
    .oneOf([true]),
});

export { FIELD_BINDINGS };
export default validationSchema;
