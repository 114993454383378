import { makeStyles } from "@material-ui/core";
import { fontSizes } from "../../InboundPage.style";

const useStyles = makeStyles(
  theme => ({
    highlightCard: {
      overflow: "visible",
      backgroundColor: theme.palette.background.main,
      marginBottom: 20,
      "& .MuiCardMedia-root, .MuiCardContent-root": {
        padding: 0,
      },
      [theme.breakpoints.up("md")]: {
        padding: 30,
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "1em",
        "& p": {
          ...fontSizes.detail,
        },
      },
    },
    expandBottomMargin: {
      marginBottom: 50,
      [theme.breakpoints.down("xs")]: {
        marginBottom: 20,
      },
    },
    keepBottomMargin: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: 50,
      },
    },
    rappiIcon: {
      position: "relative",
      [theme.breakpoints.down("md")]: {
        width: 85,
      },
    },
    appImgsWrapper: {
      position: "relative",
      top: "-15%",
      whiteSpace: "nowrap",
      textAlign: "center",
      [theme.breakpoints.up("lg")]: {
        top: "-15%",
      },
    },
    appImgsContainer: {
      width: "75%",
      display: "inline-block",
      position: "relative",
      verticalAlign: "bottom",
      left: "-10%",
      "& img": {
        width: "45%",
        marginRight: "10%",
      },
    },
    cardImage: {
      width: "80%",
      marginBottom: "5%",
    },
    backgroundWrapper: {
      width: "70%",
      position: "absolute",
      top: "0",
      right: "-40%",
      "& > :after": {
        backgroundColor: theme.typography.palette.subtitle,
      },
      [theme.breakpoints.down("xl")]: {
        top: "-5%",
        width: "65%",
      },
      [theme.breakpoints.down("lg")]: {
        top: "10%",
      },
      [theme.breakpoints.down("md")]: {
        width: "75%",
        top: "20%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "110%",
        top: "35%",
      },
    },
    appPrimeMockup: {
      width: "70%",
      position: "absolute",
      top: "15%",
      right: "36%",
      [theme.breakpoints.down("md")]: {
        width: "65%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "50%",
        top: "20%",
        right: "45%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "65%",
        top: "30%",
        right: "30%",
      },
    },
    bullets: {
      marginTop: 20,
      paddingRight: 40,
      lineHeight: "1.2em",
      "& img": {
        marginBottom: 0,
      },
      "& > div": {
        marginBottom: 20,
        justifyContent: "unset",
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 0,
      },
    },
  }),
  { name: "RappiHighlights", index: 1 },
);

export default useStyles;
