import axios from "axios";
import moment from "moment";
import {
  firstStepRequest,
  fourthStepRequest,
  secondStepRequest,
  thirdStepRequest,
  uploadFile,
} from "../../api/steps";
import { toSocialReason } from "../../mappers/Business";
import { toBankCertOCR, toBankInfo } from "../../mappers/BankInfo";
import { toSchedules, toTerms } from "../../mappers/Store";
import generateSignedTerms from "./ContractSignedGenerator";
import { toSearchEntities } from "../../api/ocr/validateEntities";
import {
  EntitiesNationalDocument,
  IdValidationAction,
} from "../../api/ocr/nationalDocument/nationalDocument";
import {
  OCR_ATTEMPTS_BANK_CERT,
  verifyBankAccountCertification,
} from "../../api/ocr/bank-account-certification/bankAccountCertification";
import CustomHttpException, {
  CustomizedError,
} from "../../utils/CustomHttpException";
import { OCR_ATTEMPTS_NATIONAL_ID } from "../../api/ocr/nationalDocument/ocrNationalDocumentStatus";

const COUNTRY_IP_DETECTOR_URL = "https://api.ipify.org/?format=json";
const COUNTRY_IP_DETECTOR_URL_TRY_TWO = "https://jsonip.com/";
const COUNTRY_IP_DETECTOR_URL_TRY_THREE = "https://ipapi.co/json/";
const ALLOWED_COUNTRIES_TO_USE_OCR = [];
const ALLOWED_FILES_TO_USE_OCR = ["jpg", "jpeg", "png"];

const getExtension = url => {
  if (url) {
    const extensionArray = url.split(".");
    if (extensionArray && extensionArray.length > 0) {
      return extensionArray[extensionArray.length - 1];
    }
  }
  return null;
};

const shouldValidateBankCertificate = (country, url) => {
  const extension = getExtension(url);
  return (
    extension &&
    ALLOWED_COUNTRIES_TO_USE_OCR.includes(country) &&
    ALLOWED_FILES_TO_USE_OCR.includes(extension.toLowerCase())
  );
};

const getImageFront = (info, store, additionalInfo) => {
  if (
    additionalInfo &&
    additionalInfo.files &&
    additionalInfo.files.identificationDocument &&
    additionalInfo.files.identificationDocument.front
  ) {
    return additionalInfo.files.identificationDocument.front;
  }
  return info.identification;
};

const getImageBack = additionalInfo => {
  if (
    additionalInfo &&
    additionalInfo.files &&
    additionalInfo.files.identificationDocument &&
    additionalInfo.files.identificationDocument.back
  ) {
    return additionalInfo.files.identificationDocument.back;
  }
  return null;
};

const getImagesToAnalise = (info, store, additionalData) => {
  const frontImage = getImageFront(info, store, additionalData);
  const backImage = getImageBack(additionalData);

  if (frontImage && backImage) {
    return [
      { image: frontImage, type: "url" },
      { image: backImage, type: "url" },
    ];
  }
  return [{ image: frontImage, type: "url" }];
};

const getIdentificationNumber = store =>
  store.social_reason.legal_agent.identification;

const shouldValidateNationalDocument = (info, store, additionalInfo) => {
  const actionToValidateDocument = IdValidationAction.get(
    store.country,
    store.social_reason.legal_agent.identification_type,
  );
  const entitiesAction = EntitiesNationalDocument.get(
    store.country,
    store.social_reason.legal_agent.identification_type,
  );

  if (actionToValidateDocument && entitiesAction) {
    const extFrontFile = getExtension(
      getImageFront(info, store, additionalInfo),
    );
    const extBackFile = getExtension(getImageBack(additionalInfo));

    if (
      extFrontFile &&
      extBackFile &&
      ALLOWED_FILES_TO_USE_OCR.includes(extFrontFile) &&
      ALLOWED_FILES_TO_USE_OCR.includes(extBackFile)
    ) {
      return true;
    }

    return !!(extFrontFile && ALLOWED_FILES_TO_USE_OCR.includes(extFrontFile));
  }
  return false;
};

const isUrl = str => {
  try {
    const url = new URL(str);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (_) {
    return false;
  }
};

const getIp = async () =>
  axios
    .get(COUNTRY_IP_DETECTOR_URL)
    .then(res => res.data.ip)
    .catch(() =>
      axios
        .get(COUNTRY_IP_DETECTOR_URL_TRY_TWO)
        .then(res => res.data.ip)
        .catch(() =>
          axios.get(COUNTRY_IP_DETECTOR_URL_TRY_THREE).then(res => res.data.ip),
        ),
    );

const firstStep = async (info, token) => {
  const payload = {};
  payload.social_reason = toSocialReason(info);
  payload.schedules = toSchedules(info.schedules);
  payload.additional_fields = info.additionalFields;
  return firstStepRequest(payload, token);
};

const uploadSignedTerms = async (template, token) => {
  if (isUrl(template.version)) {
    return template.version;
  }
  const termsSigned = await generateSignedTerms(template);
  const data = new window.FormData();
  const blob = new Blob([termsSigned], {
    type: "application/pdf",
  });
  data.append("file", blob, "file.pdf");
  return uploadFile("contract", data, token);
};

// eslint-disable-next-line camelcase
const secondStep = async (info, token, { social_reason: { legal_agent } }) => {
  const payload = {};
  const ip = await getIp();
  const templateValues = {
    version: info.version,
    name: `${legal_agent.name}\n${legal_agent.last_name}`,
    date: moment().format("DD-MM-YYYY"),
    email: legal_agent.email,
    ip,
  };
  const signedTerms = await uploadSignedTerms(templateValues, token);
  payload.terms = await toTerms(ip, {
    ...info,
    version: signedTerms,
  });
  payload.delivery_method = info.deliveryMethod;
  payload.additional_fields = info.additionalFields;
  return secondStepRequest(payload, token);
};

const thirdStep = async (info, token, store, additionalInfo) => {
  const payload = {
    menu_url: info.menuUrl,
    bank_certification_url: info.bankCertification,
    social_reason_id_url: info.socialReason,
    juridic_social_reason_url: info.juridicSocialReason,
    legal_agent_url: info.identification,
    legal_agent_power_url: info.legalAgentPower,
    rappi_menu_url: info.rappiMenu,
    additional_fields: info.additionalFields,
  };

  if (shouldValidateNationalDocument(info, store, additionalInfo)) {
    const actionToValidateDocument = IdValidationAction.get(
      store.country,
      store.social_reason.legal_agent.identification_type,
    );
    const entitiesAction = EntitiesNationalDocument.get(
      store.country,
      store.social_reason.legal_agent.identification_type,
    );

    const request = toSearchEntities(
      getImagesToAnalise(info, store, additionalInfo),
      entitiesAction.call(this, getIdentificationNumber(store)),
    );

    if (!OCR_ATTEMPTS_NATIONAL_ID.isValidToTryAgain()) {
      throw new CustomHttpException({
        customError: CustomizedError.OCR_ID_FAILED,
        responseBody: {},
      });
    } else {
      return actionToValidateDocument
        .call(this, request, token)
        .then(OCRResponse => {
          payload.additional_fields = {
            ...payload.additional_fields,
            ...OCRResponse,
          };
          return thirdStepRequest(payload, token, OCRResponse);
        });
    }
  }
  return thirdStepRequest(payload, token);
};

const fourthStep = async (info, token, store) => {
  const payload = toBankInfo(info);

  payload.additional_fields = info.additionalFields;

  if (
    shouldValidateBankCertificate(store.country, store.bank_certification_url)
  ) {
    if (!OCR_ATTEMPTS_BANK_CERT.isValidToTryAgain()) {
      throw new CustomHttpException({
        customError: CustomizedError.OCR_BANK_CERT_FAILED,
        responseBody: {},
      });
    } else {
      return verifyBankAccountCertification(
        toBankCertOCR(info, store),
        token,
      ).then(OCRResponse => {
        payload.additional_fields = {
          ...payload.additional_fields,
          ...OCRResponse,
        };
        return fourthStepRequest(payload, token);
      });
    }
  }
  return fourthStepRequest(payload, token);
};

export default [firstStep, secondStep, thirdStep, fourthStep];
