export const mergeWithDefault = (initialValues, defaultValues) => {
  const values = { ...initialValues };
  Object.keys(defaultValues).forEach(key => {
    if (initialValues[key] === null || initialValues[key] === undefined) {
      values[key] = defaultValues[key];
    }
  });
  return values;
};

export const addIds = array =>
  array.map((item, index) => ({ ...item, id: index + 1 }));
