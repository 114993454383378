import { translateKey } from "../../../utils/translate";

export const fileUploadNames = {
  bankCertification: "bank_certification",
  socialReason: "social_reason",
  identification: "identification",
  identificationBack: "identification_back",
  juridicSocialReason: "juridic_social_reason",
  legalAgentPower: "legal_agent_power",
  rappiMenu: "rappi_menu",
};

export const lightFlowByCountry = country => {
  const countries = ["BR"];

  return countries.includes(country);
};

export const fileConfiguration = {
  default: {
    bankCertification: true,
    socialReason: true,
    identification: true,
    identificationBack: true,
    juridicSocialReason: true,
    legalAgentPower: true,
    rappiMenu: false,
  },
  BR: {
    bankCertification: false,
    socialReason: false,
    identification: true,
    identificationBack: true,
    juridicSocialReason: false,
    legalAgentPower: false,
    rappiMenu: false,
  },
};

export const personTypesToJuridicFile = [
  "persona juridica",
  "pessoa jurídica",
  "persona moral",
];

export const idFileTypes = {
  BR: {
    type: "Documento de identidade do representante legal (Documento com foto)",
    spec: "RG ou CNH frente e verso",
  },
};

export const tributaryFileTypes = {
  CO: {
    type: "RUT",
    spec: `Se debe adjuntar RUT completo, legible y con vigencia entre el año 2019 o el más reciente al año 2020.`,
  },
  CL: { type: "e-RUT", spec: "" },
  UY: { type: "RUT", spec: "" },
  MX: {
    type: "Cédula de identificación fiscal",
    spec: `No puede tener una vigencia anterior al 2019. Debe estar activo (según código QR) y contener el número del CURP, número RFC, nombre del titular, nombre de la razón social o dirección. En caso de persona moral debe contener las 3 páginas.`,
  },
  PE: { type: "RUC", spec: "" },
  AR: { type: "CUIT", spec: "" },
  BR: {
    type: "Cartão CNPJ da Receita Federal",
    spec:
      "A empresa deve estar no ramo alimentício e ativo na receita federal.",
  },
  CR: { type: "RUC", spec: "" },
  EC: { type: "RUC", spec: "" },
};

export const juridicSocialReasonFileTypes = {
  CO: {
    type: "Camara de comercio",
    spec: `Vigencia 2019+. Debe contener tipo de societario, nombre del representante legal, nombre de la empresa y/o firma.`,
  },
  MX: {
    type: "Acta Constitutiva",
    spec: `Debe contener tipo de societario, nombre del representante legal, nombre de la empresa y/o firma.`,
  },
  CR: {
    type: "Personería Jurídica",
    spec: "",
  },
  AR: {
    type: "Estatuto Social / Contrato Social",
  },
  UY: {
    type: "Estatuto Social / Contrato Social",
  },
};

export const legalAgentPowerFileType = {
  MX: {
    type: "Poder del representante legal",
  },
  EC: {
    type: "Nombramiento de representante legal",
  },
  CL: {
    type: "Poder del representante legal",
    formats: ".pdf",
  },
};

const bankCertificationLocale = translateKey(
  "form.attachFilesForm.bankCertification",
);
export const bankCertificationFileTypes = {
  CO: {
    type: bankCertificationLocale,
    spec: `Se debe adjuntar certificado de cuenta expedido por la entidad bancaria, completo y vigente, donde se logre validar: Nombre del banco, número de cuenta y titular de la cuenta.`,
  },
  AR: {
    type: bankCertificationLocale,
    spec: "",
  },
  MX: {
    type: "Carátula Bancaria",
    spec: `Debe contener clabe interbancaria, número de cuenta y nombre del titular (el cual debe coincidir con el documento de identidad).`,
  },
  // BR: {
  //   type: "Comprovante de dados bancários",
  //   spec:
  //     "Certificação bancária válida (print de aplicativo, extrato bancário, cheque ou cartão da conta) Na Certificação deve constar (agência, conta e dígito, banco, CPF/CNPJ ou Nome da razão social)",
  // },
  PE: {
    type: bankCertificationLocale,
    spec:
      "Incluir algún documento / pantallazo emitido por el Banco donde se pueda verificar el titular de la cuenta bancaria (que debe ser la razón social contratante), el número de cuenta bancaria y el código interbancario (CCI).",
  },
  CL: {
    type: bankCertificationLocale,
    spec:
      "Puede ser un pantallazo o cualquier otro documento que acredite el titular de la cuenta.",
  },
  UY: {
    type: bankCertificationLocale,
    spec: "",
  },
  default: {
    type: bankCertificationLocale,
    spec: "",
  },
};

export const imageByCountryAndType = {
  CO: {
    bankCertification:
      "document-examples/colombia/bank-account-certification.jpg",
    tributaryIdentification: "document-examples/colombia/rut.jpg",
    identification: "document-examples/colombia/identification-document.jpg",
    juridicSocialReason: "document-examples/colombia/camara-comercio.jpg",
  },
  BR: {
    bankCertification:
      "document-examples/brasil/bank-account-certification.jpg",
    tributaryIdentification: "document-examples/brasil/cnpj.jpg",
    identification: "document-examples/brasil/identification-document.jpg",
  },
  MX: {
    bankCertification:
      "document-examples/mexico/bank-account-certification.jpg",
    tributaryIdentification: "document-examples/mexico/rfc.jpg",
    identification: "document-examples/mexico/identification-document.jpg",
    juridicSocialReason: "document-examples/mexico/acta-constitutiva.jpg",
  },
  PE: {
    bankCertification: "document-examples/peru/bank-account-certification.jpg",
    tributaryIdentification: "document-examples/peru/ruc.jpg",
    identification: "document-examples/peru/identification-document.jpg",
  },
  CL: {
    bankCertification: "document-examples/chile/bank-account-certification.jpg",
    tributaryIdentification: "document-examples/chile/e-rut.jpg",
    identification: "document-examples/chile/identification-document.jpg",
    juridicSocialReason: "document-examples/chile/certificado-de-estatuto.jpg",
  },
  AR: {
    bankCertification:
      "document-examples/argentina/bank-account-certification.jpg",
    tributaryIdentification: "document-examples/argentina/cuil.jpg",
    identification: "document-examples/argentina/identification-document.jpg",
    juridicSocialReason: "document-examples/argentina/bramotologia.jpg",
  },
};
