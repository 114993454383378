import React, { useEffect } from "react";
import { Dialog, Typography, LinearProgress, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import classnames from "classnames";
import VideoPlayer from "../../atoms/VideoPlayer";
import Button from "../../atoms/Button";
import { translateKeyWithHtml, translateKey } from "../../../utils/translate";
import logEvent from "../../../utils/EventLogger";
import sendInboundDataLayer from "../../../containers/InboundForm/sendInboundDataLayer";
import useInboundPageStyles from "../../pages/NewInbound/InboundPage.style";
import useModalStyles from "../../pages/NewInbound/PageComponents/Modal/Modal.style";
import useStyles from "./InboundLoadingModal.style";

const InboundLoadingModal = ({ open, country, value, link, onVideoEnd }) => {
  const classes = useStyles();
  const modalClasses = useModalStyles();
  const pageClasses = useInboundPageStyles();

  const onButtonClick = () => {
    sendInboundDataLayer("CONTINUE_BTN_CLICKED");
    logEvent("INBOUND_REDIRECT_SUCCESS");
    window.location.href = link;
  };

  useEffect(() => {
    sendInboundDataLayer("MODAL_OPENED");
  }, []);

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.paper }}
      data-test="inbound-loading-modal"
    >
      <div className={classnames(modalClasses.wrapper, classes.wrapper)}>
        <Typography variant="h1" className={classes.title}>
          {translateKey("inboundPage.loadingModal.thanks")}
        </Typography>
        <Grid container justify="center" className={classes.body} spacing={2}>
          <Grid item xs={11}>
            {link ? (
              <Button
                onClick={onButtonClick}
                data-test="redirect-self-onboarding"
              >
                {translateKey("inboundPage.loadingModal.continue")}
                {` `}
                &gt;&gt;
              </Button>
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography
                    className={classnames(pageClasses.dim, classes.subText)}
                  >
                    {translateKeyWithHtml(
                      "inboundPage.loadingModal.validatingInfo",
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  className={classes.progress}
                  spacing={2}
                  wrap="nowrap"
                >
                  <Grid item xs="auto" className={classes.percentage}>
                    {`${value}%`}
                  </Grid>
                  <Grid item xs={11}>
                    <LinearProgress
                      variant="determinate"
                      className={classes.progressBar}
                      value={value}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <VideoPlayer
          url={country !== "BR" ? "videos/rappi.mp4" : "videos/rappi_br.mp4"}
          onEnded={onVideoEnd}
        />
      </div>
    </Dialog>
  );
};

InboundLoadingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  value: PropTypes.number,
  country: PropTypes.string.isRequired,
  link: PropTypes.string,
  onVideoEnd: PropTypes.func.isRequired,
};

InboundLoadingModal.defaultProps = {
  link: "",
  value: 0,
};

export default InboundLoadingModal;
