import setFileExtension from "./setFileExtension";

const pngToJpeg = async inputFile =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = evt => {
      const img = new Image();
      img.src = evt.target.result;
      img.onload = () => {
        const { width, height } = img;
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0);
        ctx.canvas.toBlob(
          blob => {
            const name = setFileExtension(inputFile.name, "png");
            const file = new File([blob], name, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            resolve(file);
          },
          "image/jpeg",
          1,
        );
      };
    };
    reader.onerror = reject;
    reader.readAsDataURL(inputFile);
  });

export default pngToJpeg;
