import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { ErrorMessage } from "formik";
import PlaceField from "../PlaceField";
import Checkbox from "../../atoms/Checkbox/Checkbox";
import { GridFull } from "../../../utils/Grids";
import { LgTextField as TextField } from "../../atoms/TextField";
import { LgSelect as Select } from "../../atoms/Select";
import { LgCombinedField as CombinedField } from "../../molecules/CombinedField";
import { getCoverage } from "../../../api/coverage";
import { translateKey } from "../../../utils/translate";
import { countriesTaxonomies, salesPoints } from "./Inbound.constants";
import sendInboundDataLayer from "../../../containers/InboundForm/sendInboundDataLayer";
import { onHandlerNumber } from "../../../schemasAndConstraints/utils/constraintBuilders";

let modifiedFields = {};

const onPlaceChange = (
  fieldName,
  values,
  setValues,
  notifier,
) => async placeInfo => {
  let isWithinCoverage = true;
  const { name, lat, lng, fullAddress } = placeInfo;
  const notifierProps = { preventDuplicate: true };
  const isNameField = fieldName === "name";
  const isAddressField = fieldName === "address";

  setValues({
    ...values,
    data: {
      ...values.data,
      name: isNameField ? name : values.data.name,
      address: isAddressField ? name : values.data.address,
      google_place_id: null,
    },
  });

  if (!lat || !lng) return;

  try {
    isWithinCoverage = await getCoverage(values.country, lat, lng);
  } catch {
    setValues({ ...values, data: { ...values.data, name: "", address: "" } });
    notifier.error(translateKey("errors.http.unexpectedError"), notifierProps);
    return;
  }

  const restName = isAddressField ? values.data.name : name;

  if (isWithinCoverage) {
    setValues({
      ...values,
      data: {
        ...values.data,
        name: restName,
        latitude: lat,
        longitude: lng,
        address: fullAddress,
        google_place_id: placeInfo.placeId,
        external_id: placeInfo.placeId,
        zone: placeInfo.city,
        country: placeInfo.country,
        type: placeInfo.type,
        review_avg: placeInfo.avg,
        total_reviews: placeInfo.reviews,
        price_level: placeInfo.priceLevel,
      },
    });
  } else {
    setValues({ ...values, data: { ...values.data, name: "", address: "" } });
    sendInboundDataLayer("NOT_IN_COVERAGE", placeInfo);
    notifier.error(translateKey("errors.hasntCoverage"), notifierProps);
  }

  if (!modifiedFields.address && !modifiedFields.name) {
    modifiedFields = { address: fullAddress, name: restName };
    sendInboundDataLayer("FIELD_MODIFIED", {
      field: "address",
      value: fullAddress,
    });
    sendInboundDataLayer("FIELD_MODIFIED", { field: "name", value: restName });
  }
};

const LandingFormFirstStep = ({
  values,
  classes,
  handleChange,
  setFieldTouched,
  setValues,
  touched,
  errors,
  google,
  phonePrefixes,
  countries,
  notifier,
}) => (
  <Grid container justify="center">
    <GridFull item className={classes.fieldWrapper}>
      <Select
        name="country"
        value={values.country}
        onChange={handleChange}
        onBlur={() => setFieldTouched("country", true)}
        placeholder={translateKey("form.select")}
        options={countries}
        error={touched.country && errors.country ? errors.country : ""}
      />
    </GridFull>
    <GridFull item container spacing={1}>
      <Grid item xs={12} sm={6} md={12} lg={6} xl={6}>
        <Grid item className={classes.fieldWrapper}>
          <TextField
            name="data.owners_name"
            value={values.data.owners_name}
            placeholder={translateKey("form.inbound.ownerName")}
            handleChange={handleChange}
            setFieldTouched={setFieldTouched}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={12} lg={6} xl={6}>
        <Grid item className={classes.fieldWrapper}>
          <TextField
            name="data.owners_last_name"
            value={values.data.owners_last_name}
            placeholder={translateKey("form.inbound.ownerLastName")}
            handleChange={handleChange}
            setFieldTouched={setFieldTouched}
          />
        </Grid>
      </Grid>
    </GridFull>
    <GridFull item className={classes.fieldWrapper}>
      <CombinedField
        showErrors
        fieldNames={["data.phone_prefix", "data.phone"]}
        leftField={
          <Select
            name="data.phone_prefix"
            value={values.data.phone_prefix}
            onChange={handleChange}
            onBlur={() => setFieldTouched("data.phone_prefix", true)}
            options={phonePrefixes}
          />
        }
        rightField={
          <TextField
            name="data.phone"
            value={values.data.phone}
            placeholder={translateKey("form.inbound.typeInYourNumber")}
            onInput={onHandlerNumber}
            handleChange={handleChange}
            setFieldTouched={setFieldTouched}
            showError={false}
          />
        }
      />
    </GridFull>
    <GridFull item className={classes.fieldWrapper}>
      <TextField
        name="data.email"
        value={values.data.email}
        placeholder={translateKey("form.inbound.ownerEmail")}
        handleChange={handleChange}
        setFieldTouched={setFieldTouched}
      />
    </GridFull>
    <GridFull item className={classes.fieldWrapper}>
      <PlaceField
        editable
        textFieldComponent={TextField}
        google={google}
        name="data.name"
        country={values.country}
        onChange={onPlaceChange("name", values, setValues, notifier)}
        onBlur={() => setFieldTouched("data.name", true)}
        placeholder={translateKey("form.inbound.restaurantName")}
        preservedValue={values.data.name}
        data-test="name"
      />
    </GridFull>
    <GridFull item className={classes.fieldWrapper}>
      <PlaceField
        editable
        textFieldComponent={TextField}
        google={google}
        name="data.address"
        country={values.country}
        onChange={onPlaceChange("address", values, setValues, notifier)}
        onBlur={() => setFieldTouched("data.address", true)}
        placeholder={translateKey("form.inbound.restaurantAddress")}
        preservedValue={values.data.address}
        data-test="address"
      />
      <ErrorMessage
        name="data.address"
        key="data.address"
        component="div"
        className={classes.error}
      />
    </GridFull>
    <GridFull item className={classes.fieldWrapper}>
      <Select
        name="data.total_sales_point"
        value={values.data.total_sales_point || "none"}
        onChange={handleChange}
        onBlur={() => setFieldTouched("data.total_sales_point", true)}
        placeholder={translateKey("form.inbound.nSalePoints")}
        options={salesPoints}
        error={
          touched.data &&
          errors.data &&
          touched.data.total_sales_point &&
          errors.data.total_sales_point
            ? errors.data.total_sales_point
            : ""
        }
      />
    </GridFull>
    <GridFull item className={classes.fieldWrapper}>
      <Select
        name="data.categories"
        value={values.data.categories || "none"}
        onChange={handleChange}
        onBlur={() => setFieldTouched("data.food_category", true)}
        placeholder={translateKey("form.inbound.foodCategory")}
        options={countriesTaxonomies[values.country]}
        error={
          touched.data &&
          errors.data &&
          touched.data.categories &&
          errors.data.categories
            ? errors.data.categories
            : ""
        }
      />
    </GridFull>
    <GridFull item className={classes.agreementWrapper}>
      <Checkbox
        classes={{}}
        name="contact_agreement"
        handleChange={handleChange}
        value={values.contact_agreement}
        label={translateKey("inboundPage.form.agreement.contact")}
      />
    </GridFull>
  </Grid>
);

LandingFormFirstStep.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  google: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  phonePrefixes: PropTypes.array.isRequired,
  notifier: PropTypes.object.isRequired,
};

export default LandingFormFirstStep;
