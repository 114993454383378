import React from "react";
import * as PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Divider } from "@material-ui/core";
import classNames from "classnames";
import { translateKey } from "../../../utils/translate";
import Button from "../../atoms/Button";
import VideoPlayer from "../../atoms/VideoPlayer";

const InitialStep = ({ classes, onInitClick, userInfo }) => (
  <Grid item container xs={12} justify="center">
    <Grid item xs={12}>
      <h5 className={classes.welcome}>
        {translateKey("form.initialStep.welcome").replace(
          ":name",
          userInfo.owners_name,
        )}
      </h5>
    </Grid>
    <Grid item xs={12}>
      <h2 className={classes.process}>
        {translateKey("form.initialStep.title")}
      </h2>
    </Grid>
    <Grid container className={classes.stepsContainer} spacing={4}>
      <Grid item xs={12} md={3}>
        <div className={classes.step}>
          <span className={classes.stepNumber}>01. </span>
          <span
            className={classes.stepText}
            title={translateKey("form.initialStep.first")}
          >
            {translateKey("form.initialStep.first")}
          </span>
        </div>
      </Grid>
      <Grid item xs={12} md={3}>
        <div className={classes.step}>
          <span className={classes.stepNumber}>02. </span>
          <span
            className={classes.stepText}
            title={translateKey("form.initialStep.second")}
          >
            {translateKey("form.initialStep.second")}
          </span>
        </div>
      </Grid>
      <Grid item xs={12} md={3}>
        <div className={classes.step}>
          <span className={classes.stepNumber}>03. </span>
          <span
            className={classes.stepText}
            title={translateKey("form.initialStep.third")}
          >
            {translateKey("form.initialStep.third")}
          </span>
        </div>
      </Grid>
      <Grid item xs={12} md={3}>
        <div className={classes.step}>
          <span className={classes.stepNumber}>04. </span>
          <span
            className={classes.stepText}
            title={translateKey("form.initialStep.fourth")}
          >
            {translateKey("form.initialStep.fourth")}
          </span>
        </div>
      </Grid>
    </Grid>
    <div className={classes.buttonContainer}>
      <Button fullWidth onClick={onInitClick} data-test="initial-step">
        {translateKey("form.initialStep.button")}
      </Button>
    </div>
    <Divider className={classes.divider} />
    <Grid item xs={12}>
      <h2 className={classes.process}>
        {translateKey("form.initialStep.subtitle")}
      </h2>
    </Grid>
    <p className={classNames(classes.paragraph, classes.videoDescription)}>
      {translateKey("form.initialStep.subtitleDescription")}
    </p>
    <div className={classes.video}>
      <VideoPlayer
        url={
          userInfo.country !== "BR" ? "videos/rappi.mp4" : "videos/rappi_br.mp4"
        }
      />
    </div>
  </Grid>
);

InitialStep.propTypes = {
  classes: PropTypes.object.isRequired,
  onInitClick: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
};

export default InitialStep;
