import { translateKey } from "./translate";
import logEvent from "./EventLogger";
import { CustomizedError } from "./CustomHttpException";

const getReasonByStatus = status => {
  switch (status) {
    case 401:
      return "errors.http.unauthorized";
    case 400:
      return "errors.http.badRequest";
    case 404:
      return "errors.http.notFound";
    case CustomizedError.OCR_BANK_CERT_FAILED.code:
      return "errors.http.bankCertificationDoesNotMatch";
    case CustomizedError.OCR_NATIONAL_DOCUMENT_FAILED.code:
      return "errors.http.nationalDocumentDoesNotMatch";
    case CustomizedError.OCR_FRONT_DOCUMENT_FAILED.code:
      return "errors.http.frontDocumentDoesNotMatch";
    case CustomizedError.OCR_BACK_DOCUMENT_FAILED.code:
      return "errors.http.backDocumentDoesNotMatch";
    case CustomizedError.OCR_ID_FAILED.code:
      return "errors.http.idDocumentDoesNotMatch";
    case 406:
      return "errors.hasntCoverage";
    default:
      return "errors.http.unexpectedError";
  }
};

const getRequestIfPossible = errorResponse => {
  try {
    return JSON.parse(errorResponse.config.data);
  } catch (e) {
    return {};
  }
};

const notifyByError = (notifier, step) => error => {
  const {
    response = {
      status: -1,
    },
  } = error;
  const reason = getReasonByStatus(
    error.customError ? error.customError.code : response.status,
  );
  notifier.error(translateKey(reason));
  const request = getRequestIfPossible(response);

  logEvent(error.customError ? error.customError.type : "STEP_ERROR", {
    reason,
    response,
    step,
    request,
    error,
  });
};

export default notifyByError;
