import { makeStyles } from "@material-ui/core";
import baseStyleInjector from "../../../assets/styles/FormBase.style";

const flexCentering = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const overflowVisible = {
  position: "relative",
  overflow: "visible",
  "& img": {
    position: "absolute",
  },
};

export const fontSizes = {
  main: { fontSize: 64 },
  sub: { fontSize: 40 },
  sub2: { fontSize: 32 },
  sub4: { fontSize: 24 },
  sub5: { fontSize: 18 },
  text: { fontSize: 16 },
  detail: { fontSize: 14 },
  detail2: { fontSize: 12 },
};

const useStyles = makeStyles(
  theme => ({
    ...baseStyleInjector(theme),
    flexCentering,
    overflowVisible,
    detail: fontSizes.detail,
    highlighted: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    dim: {
      color: theme.typography.palette.subtitle,
    },
    elevated1: {
      zIndex: 1,
    },
    elevated2: {
      zIndex: 2,
    },
    positionRelative: {
      position: "relative",
    },
    sub2: {
      ...fontSizes.sub2,
      [theme.breakpoints.down("xs")]: {
        ...fontSizes.sub4,
      },
    },
    strongMain: {
      "& strong": {
        color: theme.palette.primary.main,
        fontWeight: "bold",
      },
    },
    strongDetail: {
      "& strong": {
        ...fontSizes.detail,
        fontStyle: "normal",
      },
    },
    fullWidth: {
      width: "100%",
    },
    fullHeight: {
      height: "100%",
    },
    bounded: {
      maxWidth: "80%",
      margin: "0 auto",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "90%",
      },
    },
    aspectRatio_1x1: {
      width: "100%",
      paddingTop: "100%",
      position: "relative",
    },
    oddEven: {
      "&:nth-child(odd)": {
        backgroundColor: theme.palette.background.main,
      },
      "&:nth-child(even)": {
        backgroundColor: theme.palette.background.dark,
      },
    },
    paddedTop: {
      paddingTop: 75,
      [theme.breakpoints.down("sm")]: {
        paddingTop: 50,
      },
    },
    paddedBottom: {
      paddingBottom: 75,
      [theme.breakpoints.down("sm")]: {
        paddingBottom: 50,
      },
    },
    paddedVertical: {
      paddingTop: 75,
      paddingBottom: 75,
      "&:last-child": {
        paddingBottom: 0,
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: 50,
        paddingBottom: 50,
      },
    },
    disableMarginAtXs: {
      [theme.breakpoints.down("xs")]: {
        margin: 0,
      },
    },
    inboundPage: {
      overflow: "hidden",
      color: theme.typography.palette.content,
      backgroundColor: theme.palette.background.dark,
      fontFamily: theme.typography.fontFamily.regular,
      "& h3, h4, h5": {
        color: theme.typography.palette.main,
        fontWeight: "bold",
      },
      "& header, header > div": {
        height: 80,
      },
      "& header": {
        boxShadow: "0 6px 10px 0 rgba(128, 98, 96, 0.16)",
      },
      "& .MuiGrid-spacing-xs-5 > .MuiGrid-item": {
        [theme.breakpoints.down("sm")]: {
          paddingTop: 0,
        },
      },
      "& .MuiTypography-paragraph": {
        fontSize: 17,
        [theme.breakpoints.down("md")]: {
          ...fontSizes.text,
        },
      },
    },
    benefitsCardWrapper: {
      marginTop: 136,
      marginBottom: 153,
      [theme.breakpoints.down("xs")]: {
        margin: "76px auto 58px auto",
      },
    },
    cardCarousel: {
      "& .slider img": {
        width: "initial",
        verticalAlign: "middle",
      },
      "& .slide": {
        backgroundColor: "transparent",
      },
      "& .control-dots": {
        padding: 0,
        margin: 0,
        bottom: 0,
        "& .dot": {
          backgroundColor: theme.typography.palette.content,
          boxShadow: "none",
          "&:focus": {
            borderWidth: 1,
          },
        },
      },
    },
    headerBottomMargin: {
      marginBottom: 40,
    },
    perkCard: {
      ...fontSizes.sub5,
      padding: 20,
      boxSizing: "border-box",
      border: "2px solid #efece9",
      borderRadius: 15,
      color: theme.typography.palette.content,
      "& img": {
        verticalAlign: "middle",
      },
      "& span": {
        display: "inline-block",
        marginBottom: 15,
      },
      "& .MuiTypography-paragraph": {
        fontSize: 16,
        [theme.breakpoints.down("sm")]: {
          ...fontSizes.detail,
        },
      },
      [theme.breakpoints.down("sm")]: {
        ...fontSizes.text,
      },
    },
    roundedIcon: {
      display: "inline-block",
      padding: 15,
      borderRadius: "50%",
      backgroundColor: "rgba(184, 180, 180, 0.1)",
      marginBottom: 10,
      marginRight: 15,
    },
    backgroundCircleWrapper: {
      width: "150%",
      position: "relative",
      [theme.breakpoints.down("lg")]: {
        width: "200%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "115%",
      },
    },
    backgroundCircleWrapperHalf: {
      width: "150%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    backgroundCircle: {
      "&::after": {
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        opacity: 0.1,
        position: "absolute",
        top: 0,
        content: '""',
      },
    },
    circleRight: {
      "&::after": {
        top: "-5%",
        right: "-12.5%",
        backgroundColor: theme.typography.palette.subtitle,
        [theme.breakpoints.down("sm")]: {
          top: "-25%",
          right: "-50%",
        },
        [theme.breakpoints.down("xs")]: {
          top: "-40%",
          right: "-15%",
        },
      },
    },
    circleLeft: {
      "&::after": {
        top: 0,
        left: "-50%",
        backgroundColor: theme.palette.background.main,
        opacity: 1,
      },
    },
  }),
  { name: "InboundPage", classNamePrefix: "inboundPage", index: 1 },
);

export default useStyles;
