import { makeStyles } from "@material-ui/core";
import { fontSizes } from "../../InboundPage.style";

const useStyles = makeStyles(
  theme => ({
    footerColumn: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: 50,
      },
    },
    footerSubtitle: {
      ...fontSizes.detail,
      marginBottom: 40,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 20,
      },
    },
    footerLink: {
      ...fontSizes.detail2,
      textDecoration: "none",
      color: theme.typography.palette.content,
      marginBottom: 20,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 10,
      },
    },
    downloadLinks: {
      "& a": { marginRight: 10 },
    },
    socialMediaLinks: {
      "& a": { marginRight: 20 },
    },
    copyRightBottom: {
      ...fontSizes.detail2,
      borderTop: "2px solid #efece9",
      padding: "40px 0",
      "& strong": {
        color: theme.typography.palette.main,
      },
      [theme.breakpoints.down("sm")]: {
        padding: "50px 0",
      },
    },
    inlineItem: {
      display: "inline-block",
      marginRight: 20,
      marginBottom: 20,
    },
    left: {
      float: "left",
    },
    right: {
      float: "right",
      "& img": {
        verticalAlign: "middle",
        marginRight: 10,
      },
    },
  }),
  { name: "Steps", index: 1 },
);

export default useStyles;
