import {
  limitLength,
  limitLengthOnlyDigits,
} from "../utils/constraintBuilders";
import { bankAccountNumberLengths as numberLengths } from "./schemas";

const { CO, AR, BR, MX, CR } = numberLengths;

const bankAccountNumberConstraints = {
  CO: {
    number: value => limitLengthOnlyDigits(value, CO.max),
  },
  AR: {
    number: value => limitLengthOnlyDigits(value, AR.max),
  },
  BR: {
    number: value => limitLengthOnlyDigits(value, BR.max),
    agency: value => limitLengthOnlyDigits(value, 4),
    verification_code: value => limitLength(1)(value),
    account_digit: value => limitLength(1)(value),
  },
  MX: {
    number: value => limitLengthOnlyDigits(value, MX.max),
  },
  CR: {
    number: value => limitLength(value, CR.max),
  },
};

export default bankAccountNumberConstraints;
