import React from "react";
import * as PropTypes from "prop-types";
import ReactPlayer from "react-player";

const VideoPlayer = ({ url, ...props }) => (
  <ReactPlayer
    playing
    controls
    url={url}
    width="100%"
    height="100%"
    {...props}
  />
);

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
};

export default VideoPlayer;
