const { makeStyles } = require("@material-ui/core");

const stylizer = theme => ({
  paper: {
    borderRadius: "15px",
  },
  wrapper: {
    height: "auto",
    padding: "10% 5%",
    overflow: "hidden",
    boxSizing: "border-box",
    "& > *:not(:last-child)": {
      marginBottom: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  title: {
    fontSize: "2.25em",
    fontWeight: "bold",
  },
  subText: {
    fontSize: "1em",
  },
  body: {
    width: "80%",
    margin: "0 auto",
  },
  animationContainer: {
    width: "50%",
  },
  progress: {
    color: "#0cc665",
    "& .MuiLinearProgress-bar1Determinate": {
      backgroundColor: "#0cc665",
    },
    "& .MuiLinearProgress-determinate": {
      backgroundColor: "#e2e2e2",
    },
  },
});

export default makeStyles(stylizer, {
  name: "loadingModal",
  classNamePrefix: "loadingModal",
  index: 1,
});
