import { makeStyles } from "@material-ui/core";
import { fontSizes } from "../../InboundPage.style";

const useStyles = makeStyles(
  theme => ({
    benefitsCard: {
      height: 325,
      borderRadius: 22,
      boxShadow: "0 6px 10px 0 rgba(128, 98, 96, 0.16)",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },
    benefitsCardGrid: {
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap-reverse",
      },
    },
    benefitsCardTextWrapper: {
      paddingTop: 46,
      paddingLeft: 32,
      [theme.breakpoints.down("sm")]: {
        marginTop: 13,
        padding: "0 27px",
        paddingBottom: 27,
      },
    },
    benefitsCardText: {
      ...fontSizes.sub2,
      marginBottom: 37,
      [theme.breakpoints.down("md")]: {
        marginBottom: 16,
      },
      [theme.breakpoints.down("xs")]: {
        ...fontSizes.sub5,
      },
    },
    moreInformation: {
      ...fontSizes.detail,
      textDecoration: "none",
      color: theme.palette.primary.main,
      "& img": {
        verticalAlign: "middle",
      },
    },
    sampleFoodContainer: {
      "& img:nth-of-type(1)": {
        width: "60%",
        bottom: 0,
        [theme.breakpoints.down("sm")]: {
          width: "40%",
          top: "-5%",
          left: "-5%",
          transform: "rotate(180deg)",
        },
        [theme.breakpoints.down("xs")]: {
          top: 0,
          left: "-10%",
          width: "50%",
        },
      },
      "& img:nth-of-type(2)": {
        width: "35%",
        bottom: 0,
        right: 0,
        [theme.breakpoints.down("xs")]: {
          width: "40%",
        },
      },
      "& img:nth-of-type(3)": {
        width: "42%",
        top: 0,
        right: 5,
        [theme.breakpoints.down("md")]: {
          width: "55%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "25%",
          left: "40%",
        },
        [theme.breakpoints.down("xs")]: {
          left: "42%",
          width: "30%",
        },
      },
      [theme.breakpoints.down("sm")]: {
        height: 136,
        overflow: "hidden",
      },
    },
  }),
  { name: "BenefitsCard", index: 1 },
);

export default useStyles;
