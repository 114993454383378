import React from "react";
import { Typography, Container } from "@material-ui/core/";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { GoogleApiWrapper } from "google-maps-react";
import { translateKey } from "../../../utils/translate";
import LandingForm from "./LandingForm";
import Alert from "../../atoms/Alert";
import Button from "../../atoms/Button";
import useStyles from "../../pages/NewInbound/PageComponents/HeaderAndForm/HeaderAndForm.style";
import styles from "./Inbound.style";

const LandingFormWrapper = ({
  values,
  isValid,
  classes,
  handleChange,
  setFieldTouched,
  setFieldValue,
  setValues,
  touched,
  errors,
  google,
  phonePrefixes,
  countries,
  taxonomies,
  notifier,
  onSend,
}) => {
  const { header, formHeader, termsAndConditions } = useStyles();

  return (
    <>
      <Typography variant="h2" className={classNames(header, formHeader)}>
        {translateKey("inboundPage.form.header")}
      </Typography>
      <LandingForm
        values={values}
        classes={classes}
        handleChange={handleChange}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        setValues={setValues}
        touched={touched}
        errors={errors}
        google={google}
        phonePrefixes={phonePrefixes}
        countries={countries}
        taxonomies={taxonomies}
        notifier={notifier}
      />
      <Typography variant="caption" className={termsAndConditions}>
        {` ${translateKey("inboundPage.form.agreement.byClicking")} `}
        <a href="https://legal.rappi.com/colombia/terminos-y-condiciones-de-uso-de-plataforma-rappi-2/">
          {translateKey("inboundPage.form.agreement.termsAndConditions")}
        </a>
        {` ${translateKey("inboundPage.form.agreement.andThe")} `}
        <a href="https://legal.rappi.com/colombia/politica-de-proteccion-y-tratamiento-de-datos-personales-rappi-s-a-s/">
          {translateKey("inboundPage.form.agreement.privacyPolicy")}
        </a>
        .
      </Typography>
      {values.country === "AR" && (
        <Container disableGutters className={classes.fieldWrapper}>
          <Alert color="orange">
            {translateKey("inboundPage.form.optionsNotice")}
          </Alert>
        </Container>
      )}
      <Button
        fullWidth
        disabled={!isValid}
        secondary={!isValid}
        onClick={onSend}
        id="submit-inbound"
        data-test="next-button"
      >
        {translateKey("inboundPage.form.send")}
      </Button>
    </>
  );
};

LandingFormWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  isValid: PropTypes.bool,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  google: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  phonePrefixes: PropTypes.array.isRequired,
  taxonomies: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  notifier: PropTypes.object.isRequired,
  onSend: PropTypes.func,
};

LandingFormWrapper.defaultProps = {
  isValid: false,
  onSend: () => {},
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  libraries: ["places"],
})(withStyles(styles)(LandingFormWrapper));
