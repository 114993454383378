import { makeStyles } from "@material-ui/core";
import { fontSizes } from "../../InboundPage.style";
import CloseIcon from "../../../../../assets/images/InboundPage/close-material.svg";
import AddIcon from "../../../../../assets/images/InboundPage/add-material.svg";

const useStyles = makeStyles(
  theme => ({
    faqWrapper: {
      padding: 0,
      overflow: "hidden",
      "& .expandIcon": {
        width: 56,
        height: 56,
        borderRadius: "50%",
        backgroundImage: `url(${AddIcon})`,
        backgroundColor: theme.palette.disabled.main,
        backgroundSize: "50%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      },
      "& .Mui-expanded .expandIcon": {
        backgroundImage: `url(${CloseIcon})`,
        backgroundColor: theme.typography.palette.main,
      },
    },
    faqContainer: {
      padding: 10,
      borderBottom: "2px solid #efece9",
      "&:last-child": {
        borderBottom: 0,
      },
      "&.Mui-expanded": {
        marginTop: 0,
        marginBottom: 0,
      },
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    question: {
      ...fontSizes.text,
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        ...fontSizes.detail,
      },
    },
    answer: {
      ...fontSizes.detail,
      color: theme.typography.palette.content,
      [theme.breakpoints.up("sm")]: {
        ...fontSizes.text,
        maxWidth: "80%",
      },
    },
  }),
  { name: "Faq", index: 1 },
);

export default useStyles;
