import { string } from "yup";
import { buildCharactersMessage } from "../utils/messageBuilders";
import { phonePrefixes } from "../../components/organisms/InboundForm/Inbound.constants";
import { translateKey } from "../../utils/translate";

const fixedAndMobilePhoneLengths = {
  CO: {
    min: 7,
    max: 10,
  },
  BR: {
    min: 8,
    max: 14,
  },
  MX: {
    min: 10,
    max: 10,
  },
  AR: {
    min: 11,
    max: 11,
  },
  PE: {
    min: 9,
    max: 9,
  },
  UY: {
    min: 8,
    max: 9,
  },
  CL: {
    min: 9,
    max: 9,
  },
  EC: {
    min: 8,
    max: 10,
  },
  CR: {
    min: 8,
    max: 8,
  },
};

const mobilePhoneLengths = {
  CO: {
    min: 10,
    max: 10,
  },
  BR: {
    min: 10,
    max: 14,
  },
  MX: {
    min: 10,
    max: 10,
  },
  AR: {
    min: 11,
    max: 11,
  },
  PE: {
    min: 9,
    max: 9,
  },
  UY: {
    min: 9,
    max: 9,
  },
  CL: {
    min: 9,
    max: 9,
  },
  EC: {
    min: 10,
    max: 10,
  },
  CR: {
    min: 8,
    max: 8,
  },
};

const defaultPhoneSchema = string()
  .required(translateKey("form.required"))
  .matches(/^[0-9]+$/g, "Campo inválido");

const getCountryPhoneSchema = (country, schema, includeFixed) => {
  const phoneLengths =
    includeFixed === true ? fixedAndMobilePhoneLengths : mobilePhoneLengths;

  const lengths = phoneLengths[country];
  if (!lengths) {
    return schema;
  }

  const { min, max } = lengths;
  if (min === max) {
    return schema.length(max, buildCharactersMessage(max));
  }

  const message = buildCharactersMessage(
    `${min} ${country === "BR" ? "ou" : "o"} ${max}`,
  );
  return schema.min(min, message).max(max, message);
};

const getPhoneSchema = (
  country,
  binding,
  baseSchema = defaultPhoneSchema,
  includeFixed = false,
) => {
  const schema = baseSchema.clone();
  return binding
    ? schema.when(binding, (phonePrefix, schema) => {
        const { country } =
          phonePrefixes.find(item => item.value === phonePrefix) || {};
        return getCountryPhoneSchema(country, schema, includeFixed);
      })
    : getCountryPhoneSchema(country, schema, includeFixed);
};

export { mobilePhoneLengths, fixedAndMobilePhoneLengths };
export default getPhoneSchema;
