import requester from "./requester";

const filterBlurred = (
  image,
  format, // format <url, b64>
  onUploadProgress,
) =>
  requester()
    .post(
      "/api/rumio/filter_blurred",
      {
        image,
        format,
      },
      {
        onUploadProgress,
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    .then(res => res.data.status);

export default filterBlurred;
