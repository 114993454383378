import { object, string } from "yup";
import { translateKey } from "../../../utils/translate";

const NewLeadSchema = object().shape({
  country: string().required(),
  data: object().shape({
    address: string()
      .required(translateKey("form.required"))
      .when("google_place_id", (value, schema) =>
        value
          ? schema
          : schema.test(
              "hasPlaceId",
              translateKey(
                "inboundPage.form.errors.selectAddressFromSuggestions",
              ),
              () => false,
            ),
      ),
    email_hunter: string()
      .email("form.errors.invalidEmail")
      .required("form.errors.emailEnRequired"),
    email_leader: string()
      .email("form.errors.invalidEmail")
      .required("form.errors.emailEnRequired"),
    team: string().required("form.errors.teamRequired"),
    total_sales_point: string().required("form.errors.nSalePointsRequired"),
    categories: string().required("form.errors.taxonomyRequired"),
    phone: string().required("form.errors.phoneRequired"),
    franchised: string().required("form.errors.franchisedRequired"),
    name: string().required("form.errors.nameRequired"),
    referral_code: string(),
    instagram_account: string(),
  }),
});

export default NewLeadSchema;
