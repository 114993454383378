import { makeStyles } from "@material-ui/core";
import { fontSizes } from "../../InboundPage.style";

const pseudoElementStyles = {
  width: "10%",
  height: "100%",
  display: "block",
  position: "absolute",
  top: 0,
  content: '""',
  zIndex: 1,
};

const useStyles = makeStyles(
  theme => ({
    subtitle: {
      ...fontSizes.text,
    },
    gradientSides: {
      position: "relative",
      "&::before": {
        ...pseudoElementStyles,
        left: 0,
        background: `linear-gradient(90deg,
          rgba(255,255,255,0.75) 0%,
         rgba(255,255,255,0) 100%)`,
      },
      "&::after": {
        ...pseudoElementStyles,
        right: 0,
        background: `linear-gradient(90deg,
          rgba(255,255,255,0) 0%,
          rgba(255,255,255,0.75) 100%)`,
      },
    },
    brandImageWrapper: {
      display: "flex",
      position: "relative",
      alignItems: "center",
      justifyContent: "space-between",
      whiteSpace: "nowrap",
      marginTop: 100,
      [theme.breakpoints.down("xs")]: {
        marginTop: 90,
        left: "-10%",
      },
    },
    brandImageContainer: {
      width: 108,
      flexShrink: 0,
      "& img": {
        width: "100%",
        [theme.breakpoints.down("md")]: {
          width: 90,
        },
        [theme.breakpoints.down("xs")]: {
          width: 55,
        },
      },
    },
  }),
  { name: "BrandsBanner", index: 1 },
);

export default useStyles;
