import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import classnames from "classnames";
import styles from "./AttachFilesForm.style";
import FileInput from "../../atoms/FileInput";
import Alert from "../../atoms/Alert";
import FormSectionTitle from "../../atoms/FormSectionTitle";
import Select from "../../atoms/Select";
import TextField from "../../atoms/TextField";
import { translateKey } from "../../../utils/translate";
import IdFileInputs from "./AttachFilesForm.IdFileInputs";
import {
  lightFlowByCountry,
  imageByCountryAndType,
} from "./AttachFilesForm.constants";
import { onChangeIdentificationFile } from "../../../api/ocr/bank-account-certification/bankAccountCertification";
import FieldDescriptor from "../../atoms/FieldDescriptor";

const AttachFilesForm = ({
  classes,
  userInfo,
  values,
  setStepAdditionalData,
  handleChange,
  onFileError,
  idFileType,
  tributaryFileType,
  juridicSocialReasonFileType,
  legalAgentPowerFileType,
  bankCertificationFileType,
  fileDistribution: {
    bankCertification,
    socialReason,
    identification,
    juridicSocialReason,
    legalAgentPower,
  },
  additionalFields,
  uploaders,
}) => {
  const isLightFlow = lightFlowByCountry(userInfo.country);

  const onChangeBankCert = (evt, fileInputManager) => {
    onChangeIdentificationFile();
    uploaders.bankCertification(evt, fileInputManager);
  };

  const fileToTypeMap = [
    [identification, idFileType],
    [bankCertification, bankCertificationFileType],
    [socialReason, tributaryFileType],
    [juridicSocialReason, juridicSocialReasonFileType],
  ];
  const docList = fileToTypeMap.reduce((content, [file, fileToType]) => {
    const { type, spec } = fileToType || {};
    if (file && spec) {
      content.push(
        <p key={type} className={classes.alertItem}>
          <dt>{`${type}:`}</dt>
          <dd>{spec}</dd>
        </p>,
      );
    }
    return content;
  }, []);

  const getImage = documentType => {
    if (
      imageByCountryAndType[userInfo.country] &&
      imageByCountryAndType[userInfo.country][documentType]
    ) {
      return imageByCountryAndType[userInfo.country][documentType];
    }
    return null;
  };

  return (
    <Grid container style={{ padding: "12px 0 24px 0" }}>
      <FormSectionTitle
        title={translateKey("form.attachFilesForm.documents")}
      />
      <Grid
        item
        xs={12}
        className={classnames(classes.field, classes.alertContainer)}
      >
        <div className={classes.firstAlert}>
          <Alert color="red">
            <p className={classes.alertItem}>
              {translateKey("form.attachFilesForm.alert")}
            </p>
            <p className={classes.alertItem}>
              {translateKey("form.attachFilesForm.alert2")}
            </p>
            <dl className={classes.docList}>{docList}</dl>
          </Alert>
        </div>
      </Grid>
      <Grid container alignItems="flex-end" className={classes.fieldContainer}>
        {bankCertification && (
          <Grid item xs={12} md={6} className={classes.field}>
            <FileInput
              name="bankCertification"
              placeholder={bankCertificationFileType.type}
              value={values.bankCertification}
              onChange={onChangeBankCert}
              onError={onFileError}
              accept=".png, .jpg, .pdf, .jpeg"
              imageExample={getImage("bankCertification")}
            />
          </Grid>
        )}
        {socialReason && (
          <Grid item xs={12} md={6} className={classes.field}>
            <FileInput
              name="tributaryIdentification"
              placeholder={tributaryFileType.type}
              value={values.socialReason}
              onError={onFileError}
              onChange={uploaders.socialReason}
              accept=".png, .jpg, .pdf, .jpeg"
              imageExample={getImage("tributaryIdentification")}
            />
          </Grid>
        )}
        {identification && (
          <IdFileInputs
            classes={classes}
            values={values}
            onFileError={onFileError}
            uploaders={uploaders}
            images={[getImage("identification")]}
            setStepAdditionalData={setStepAdditionalData}
          />
        )}
        {juridicSocialReason && (
          <Grid item xs={12} md={6} className={classnames(classes.field)}>
            <FileInput
              name="juridicSocialReason"
              placeholder={juridicSocialReasonFileType.type}
              value={values.juridicSocialReason}
              onChange={uploaders.juridicSocialReason}
              onError={onFileError}
              accept=".png, .jpg, .pdf, .jpeg"
              imageExample={getImage("juridicSocialReason")}
            />
          </Grid>
        )}
        {legalAgentPower && (
          <Grid item xs={12} md={6} className={classes.field}>
            <FileInput
              name="legalAgentPower"
              placeholder={legalAgentPowerFileType.type}
              value={values.legalAgentPower}
              onChange={uploaders.legalAgentPower}
              onError={onFileError}
              accept=".png, .jpg, .pdf, .jpeg"
            />
          </Grid>
        )}
        {!isLightFlow &&
          additionalFields &&
          additionalFields.map(({ name, label }) => (
            <Grid item xs={12} md={6} className={classes.field} key={name}>
              <FileInput
                name={`additionalFields.${name}`}
                placeholder={label}
                value={values.additionalFields[name]}
                onChange={uploaders[`additionalFields.${name}`]}
                onError={onFileError}
                accept=".png, .jpg, .pdf, .jpeg"
              />
            </Grid>
          ))}
        {!isLightFlow && (
          <Grid item xs={12} md={6} className={classes.field}>
            <FieldDescriptor noWrap className={classes.block}>
              {translateKey("form.attachFilesForm.rappiMenu")}
            </FieldDescriptor>
            <FileInput
              name="rappiMenu"
              value={values.rappiMenu}
              onChange={uploaders.rappiMenu}
              onError={onFileError}
              accept=".png, .jpg, .pdf, .jpeg"
            />
          </Grid>
        )}
      </Grid>
      <FormSectionTitle
        title={translateKey("form.attachFilesForm.extraInfo")}
      />
      <Grid item xs={12} md={12} className={classes.field}>
        <div className={classes.paragraph}>
          {translateKey("form.attachFilesForm.extraInfoSubtitle")}
        </div>
      </Grid>
      <Grid item xs={12} md={6} className={classes.field}>
        <Select
          name="alreadyOnDelivery"
          placeholder={translateKey("form.select")}
          value={
            values.alreadyOnDelivery === null
              ? "none"
              : values.alreadyOnDelivery
          }
          onChange={handleChange}
          title={translateKey("form.attachFilesForm.deliveryField")}
          options={[
            { label: translateKey("form.attachFilesForm.yes"), value: true },
            { label: translateKey("form.attachFilesForm.no"), value: false },
          ]}
        />
      </Grid>
      {values.alreadyOnDelivery && (
        <Grid item xs={12} md={6} className={classes.field}>
          <TextField
            name="menuUrl"
            title={translateKey("form.attachFilesForm.competencyUrl")}
            value={values.menuUrl}
            placeholder={translateKey("form.writeHere")}
            handleChange={handleChange}
          />
        </Grid>
      )}
    </Grid>
  );
};

AttachFilesForm.defaultProps = {
  additionalFields: [],
  idFileType: {},
  legalAgentPowerFileType: {},
  juridicSocialReasonFileType: {},
  bankCertificationFileType: {
    type: translateKey("form.attachFilesForm.bankCertification"),
    spec: "",
  },
};

AttachFilesForm.propTypes = {
  classes: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setStepAdditionalData: PropTypes.func.isRequired,
  idFileType: PropTypes.object,
  bankCertificationFileType: PropTypes.object,
  tributaryFileType: PropTypes.object.isRequired,
  fileDistribution: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  onFileError: PropTypes.func.isRequired,
  uploaders: PropTypes.object.isRequired,
  additionalFields: PropTypes.array,
  juridicSocialReasonFileType: ({ fileDistribution, ...props }, propName) => {
    if (fileDistribution.juridicSocialReason) {
      const type = typeof props[propName];
      return type === "object"
        ? null
        : new Error(
            `Invalid prop '${propName}' supplied to 'AttachFilesForm'. 
            Expected 'string' but received '${type}'. Validation failed.`,
          );
    }
    return null;
  },
  legalAgentPowerFileType: ({ fileDistribution, ...props }, propName) => {
    if (fileDistribution.legalAgentPower) {
      const type = typeof props[propName];
      return type === "object"
        ? null
        : new Error(
            `Invalid prop '${propName}' supplied to 'AttachFilesForm'. 
            Expected 'string' but received '${type}'. Validation failed.`,
          );
    }
    return null;
  },
};

export default withStyles(styles)(AttachFilesForm);
