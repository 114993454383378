export const CustomizedError = {
  DEFAULT: {
    type: "STEP_ERROR",
    code: -1,
    error: "UNKNOWN",
    message: "An error has occurred",
  },
  OCR_BANK_CERT_FAILED: {
    type: "OCR_FAILED",
    code: 40401,
    error: "BANK_CERTIFICATION",
    message: "Bank Account does not match",
  },
  OCR_NATIONAL_DOCUMENT_FAILED: {
    type: "OCR_FAILED",
    code: 40402,
    error: "NATIONAL_DOCUMENT",
    message: "The national document does not match",
  },
  OCR_FRONT_DOCUMENT_FAILED: {
    type: "OCR_FAILED",
    code: 40403,
    error: "NATIONAL_DOCUMENT",
    message: "The front image document does not match",
  },
  OCR_BACK_DOCUMENT_FAILED: {
    type: "OCR_FAILED",
    code: 40404,
    error: "NATIONAL_DOCUMENT",
    message: "The back image document does not match",
  },
  OCR_ID_FAILED: {
    type: "OCR_FAILED",
    code: 40405,
    error: "NATIONAL_DOCUMENT",
    message: "The identification number does not match",
  },
};

class CustomHttpException extends Error {
  customError = CustomizedError.DEFAULT;

  response = null;

  constructor(error) {
    super(error.customError.message);
    this.customError = error.customError;
    this.response = error.responseBody;
  }
}

export default CustomHttpException;
