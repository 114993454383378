import React from "react";
import { Formik } from "formik";
import * as PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import NewLeadForm from "./NewLeadForm";
import validationSchema from "./NewLeadSchema";
import withNotifier from "../../../hocs/notifications/withNotifier";
import {
  answerOptions,
  countriesTaxonomies,
  salePointsOptions,
  teamOptions,
  roleOptions,
  countries,
  leaderOptions,
} from "./NewLead.constants";

export const loadRequirements = async () => ({
  taxonomies: countriesTaxonomies,
  roleOptions,
  teamOptions,
  salePointsOptions,
  answerOptions,
  countries,
  leaderOptions,
});

const initialValuesValid = values => {
  try {
    return !isEmpty(values) && !!validationSchema.validateSync(values);
  } catch (e) {
    return false;
  }
};

const defaultValues = () => ({
  date: "",
  classification: "newLead",
  city: "",
  country: "CO",
  data: {
    source: "newLead",
    country: "",
    address: "",
    franchised: null,
    phone: "",
    name: "",
    role: "",
    email_hunter: "",
    email_leader: "",
    team: "",
    categories: "",
    referral_code: "",
    instagram_account: "",
  },
});

const ConfiguredForm = ({
  initialValues,
  onFormChange,
  taxonomies,
  notifier,
  teamOptions,
  roleOptions,
  answerOptions,
  salePointsOptions,
  countries,
  leaderOptions,
}) => (
  <Formik
    initialValues={defaultValues()}
    isInitialValid={initialValuesValid(initialValues)}
    validationSchema={validationSchema}
    enableReinitialize
    render={props => {
      const { role } = props.values.data;
      onFormChange(props);
      return (
        <NewLeadForm
          {...props}
          role={role}
          taxonomies={taxonomies}
          notifier={notifier}
          roleOptions={roleOptions}
          teamOptions={teamOptions}
          answerOptions={answerOptions}
          salePointsOptions={salePointsOptions}
          countries={countries}
          leaderOptions={leaderOptions}
        />
      );
    }}
  />
);

ConfiguredForm.defaultProps = {
  initialValues: { ...defaultValues },
};

ConfiguredForm.propTypes = {
  values: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  onFormChange: PropTypes.func.isRequired,
  notifier: PropTypes.object.isRequired,
  teamOptions: PropTypes.array.isRequired,
  roleOptions: PropTypes.array.isRequired,
  answerOptions: PropTypes.object.isRequired,
  salePointsOptions: PropTypes.array.isRequired,
  city: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
  taxonomies: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  leaderOptions: PropTypes.array.isRequired,
};

export default withNotifier(ConfiguredForm);
