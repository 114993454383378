import { PageSizes, PDFDocument, StandardFonts } from "pdf-lib";
import { translateKey } from "../../utils/translate";

const copyTemplateOnBase = async (base, template) => {
  const pageCount = template.getPageCount();
  const indices = [...Array(pageCount).keys()];
  const copiedPages = await base.copyPages(template, indices);
  copiedPages.forEach(page => base.addPage(page));
};

export default async templateValues => {
  const existingPdfBytes = await fetch(templateValues.version).then(res =>
    res.arrayBuffer(),
  );
  const base = await PDFDocument.create();
  const template = await PDFDocument.load(existingPdfBytes);
  await copyTemplateOnBase(base, template);
  const page = base.addPage(PageSizes.A4);
  const { height, width } = page.getSize();
  const helvetica = await base.embedFont(StandardFonts.Helvetica);
  const helveticaBold = await base.embedFont(StandardFonts.HelveticaBold);

  page.drawText(translateKey("contract.title1"), {
    x: width * 0.1,
    y: height - height * 0.07,
    font: helveticaBold,
    size: 11,
  });

  page.drawText(translateKey("contract.title2"), {
    x: width * 0.25,
    y: height - height * 0.09,
    font: helveticaBold,
    size: 11,
  });

  page.drawText(translateKey("contract.subtitle1"), {
    x: width * 0.08,
    y: height - height * 0.13,
    font: helvetica,
    size: 11,
  });

  page.drawText(translateKey("contract.subtitle2"), {
    x: width * 0.08,
    y: height - height * 0.15,
    font: helvetica,
    size: 11,
  });

  page.drawText(translateKey("contract.doc"), {
    x: width * 0.07,
    y: height - height * 0.21,
    font: helveticaBold,
    size: 11,
  });

  page.drawText(translateKey("contract.IP"), {
    x: width * 0.3,
    y: height - height * 0.21,
    font: helveticaBold,
    size: 11,
  });

  page.drawText(translateKey("contract.ally"), {
    x: width * 0.4,
    y: height - height * 0.2,
    font: helveticaBold,
    size: 11,
    lineHeight: 12,
  });

  page.drawText(translateKey("contract.email"), {
    x: width * 0.6,
    y: height - height * 0.2,
    font: helveticaBold,
    size: 11,
    lineHeight: 12,
  });

  page.drawText(translateKey("contract.date"), {
    x: width * 0.8,
    y: height - height * 0.2,
    font: helveticaBold,
    size: 11,
    lineHeight: 12,
  });

  page.drawText(translateKey("contract.doc1"), {
    x: width * 0.07,
    y: height - height * 0.24,
    font: helvetica,
    size: 11,
    lineHeight: 12,
  });

  page.drawText(templateValues.ip, {
    x: width * 0.25,
    y: height - height * 0.25,
    font: helvetica,
    size: 11,
  });

  page.drawText(templateValues.name, {
    x: width * 0.4,
    y: height - height * 0.25,
    font: helvetica,
    size: 11,
    lineHeight: 12,
  });

  page.drawText(templateValues.email, {
    x: width * 0.57,
    y: height - height * 0.25,
    font: helvetica,
    size: 11,
    lineHeight: 10,
  });

  page.drawText(templateValues.date, {
    x: width * 0.8,
    y: height - height * 0.25,
    font: helvetica,
    size: 11,
    lineHeight: 10,
  });

  page.drawText(translateKey("contract.doc2"), {
    x: width * 0.07,
    y: height - height * 0.31,
    font: helvetica,
    size: 11,
    lineHeight: 12,
  });

  page.drawText(templateValues.ip, {
    x: width * 0.25,
    y: height - height * 0.32,
    font: helvetica,
    size: 11,
  });

  page.drawText(templateValues.name, {
    x: width * 0.4,
    y: height - height * 0.32,
    font: helvetica,
    size: 11,
    lineHeight: 12,
  });

  page.drawText(templateValues.email, {
    x: width * 0.57,
    y: height - height * 0.32,
    font: helvetica,
    size: 11,
    lineHeight: 10,
  });

  page.drawText(templateValues.date, {
    x: width * 0.8,
    y: height - height * 0.32,
    font: helvetica,
    size: 11,
    lineHeight: 10,
  });

  return base.save();
};
