import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Stepper, Step, StepLabel } from "@material-ui/core";
import classNames from "classnames";
import RappiBar from "../../molecules/RappiBar";
import Button from "../../atoms/Button";
import InitialStep from "./InitialStep";
import { translateKey } from "../../../utils/translate";
import { PageAlert } from "../../atoms/Alert";
import WhatsAppHelp from "../../molecules/WhatsappHelp";

const renderSteps = (steps, higherUploadedStep, goToStep, classes) =>
  steps.map((s, index) => {
    const title = s.getTitle();
    const isLessOrEqual = index <= higherUploadedStep;

    return (
      <Step
        key={`stepper-step-${title}`}
        onClick={() => (isLessOrEqual ? goToStep(index) : null)}
      >
        <StepLabel
          className={classNames(
            {
              [classes.clickableStep]: isLessOrEqual,
            },
            isLessOrEqual ? classes.stepLabel : classes.uncompleteStepLabel,
          )}
        >
          {title}
        </StepLabel>
      </Step>
    );
  });

const StepperForm = ({
  classes,
  activeStep,
  higherUploadedStep,
  steps = [],
  userInfo,
  stepsValues,
  setStepAdditionalData,
  nextStep,
  previousStep,
  goToStep,
  onInit,
  alert,
}) => {
  const [isValidStep, setValid] = useState(false);
  return (
    <Grid container className={classes.root}>
      <RappiBar />
      {alert && (
        <PageAlert
          message={translateKey("alert.declined").replace(":reason", alert)}
          color="red"
        />
      )}
      <Grid container justify="center" className={classes.content}>
        {activeStep === null ? (
          <InitialStep
            classes={classes}
            onInitClick={onInit}
            userInfo={userInfo}
          />
        ) : (
          <Grid container alignContent="center" justify="center">
            {steps.length > 1 && (
              <Grid
                item
                xs={12}
                sm={10}
                md={10}
                lg={11}
                className={classes.stepperContainer}
              >
                <Stepper
                  alternativeLabel
                  orientation="horizontal"
                  className={classes.stepper}
                  activeStep={activeStep}
                >
                  {renderSteps(steps, higherUploadedStep, goToStep, classes)}
                </Stepper>
              </Grid>
            )}
            <Grid item xs={12} className={classes.formWrapper}>
              {steps[activeStep].render(
                userInfo,
                stepsValues,
                setStepAdditionalData,
                setValid,
              )}
              <Grid
                item
                container
                justify="space-between"
                xs={12}
                className={classes.buttonSection}
              >
                {activeStep > 0 && (
                  <Grid item xs={3}>
                    <Button fullWidth onClick={previousStep} back>
                      {translateKey("form.back")}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={activeStep > 0 ? 8 : 12}>
                  <Button
                    onClick={nextStep}
                    disabled={!isValidStep}
                    secondary={!isValidStep}
                    style={{ width: "100%" }}
                    data-test="next-step"
                  >
                    {activeStep >= steps.length - 1
                      ? translateKey("form.finish")
                      : translateKey("form.next")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <WhatsAppHelp country={userInfo.country} />
    </Grid>
  );
};

StepperForm.propTypes = {
  classes: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
  stepsValues: PropTypes.object.isRequired,
  setStepAdditionalData: PropTypes.func.isRequired,
  activeStep: PropTypes.number,
  higherUploadedStep: PropTypes.number,
  nextStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired,
  alert: PropTypes.string,
};

StepperForm.defaultProps = {
  alert: null,
  activeStep: null,
  higherUploadedStep: null,
};

export default StepperForm;
