import TagManager from "react-gtm-module";

const sendInboundDataLayer = (event, payload = {}) => {
  let data = {};

  switch (event) {
    case "SESSION_STARTED":
    case "MODAL_OPENED":
    case "CONTINUE_BTN_CLICKED":
    case "FORM_SUBMITTED":
      data.event = event;
      break;
    case "NOT_IN_COVERAGE":
    case "FIELD_MODIFIED":
      data = { ...payload, event };
      break;
    default:
      return;
  }

  TagManager.dataLayer({
    dataLayer: data,
  });
};

export default sendInboundDataLayer;
