import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import OnboardingForm from "./OnboardingForm";
import OnboardingStep from "./OnboardingStep";
import withLoader from "../../hocs/loader/withLoader";
import withNotifier from "../../hocs/notifications/withNotifier";
import LegalInformationForm, {
  loadRequirements as getLegalRequirements,
  getInitialValues as getLegalInitValues,
} from "../../components/organisms/GeneralInformationForm";
import ServiceModelForm, {
  loadRequirements as getServiceModelRequirements,
  getInitialValues as getServiceModelInitValues,
} from "../../components/organisms/ServiceModelForm";
import BankInfoForm, {
  loadRequirements as getBankInfoRequirements,
  getInitialValues as getBankInfoInitValues,
} from "../../components/organisms/BankInfoForm";
import AttachFilesForm, {
  loadRequirements as getFileRequirements,
  getInitialValues as getFileInitValues,
} from "../../components/organisms/AttachFilesForm";
import uploader from "./StepUploader";
import { loadPreviousInfo } from "../../api/steps";
import { translateKey } from "../../utils/translate";

const UNEDITABLE_STEPS = [];

const steps = [
  new OnboardingStep(
    LegalInformationForm,
    translateKey("form.infoForm.stepTitle"),
    "generalInfo",
    getLegalRequirements,
    getLegalInitValues,
  ),
  new OnboardingStep(
    ServiceModelForm,
    translateKey("form.serviceModel.stepTitle"),
    "serviceModel",
    getServiceModelRequirements,
    getServiceModelInitValues,
  ),
  new OnboardingStep(
    AttachFilesForm,
    translateKey("form.attachFilesForm.stepTitle"),
    "attachFiles",
    getFileRequirements,
    getFileInitValues,
  ),
  new OnboardingStep(
    BankInfoForm,
    translateKey("form.bankInfoForm.stepTitle"),
    "bankInfo",
    getBankInfoRequirements,
    getBankInfoInitValues,
  ),
];

const stepUploader = [...uploader];

const removeUneditableSteps = () => {
  UNEDITABLE_STEPS.forEach(i => {
    steps.splice(i, 1);
    stepUploader.splice(i, 1);
  });
};

const ConfiguredComponent = ({ loader, ...rest }) => {
  let stepsToEdit = steps;
  let uploaders = stepUploader;
  const { token } = useParams();
  const [previousInfo, setPreviousInfo] = useState(null);

  if (previousInfo && previousInfo.pending_modifications) {
    const { steps: editableSteps } = previousInfo.pending_modifications;

    stepsToEdit = [];
    uploaders = [];
    editableSteps
      .sort((a, b) => a - b)
      .forEach(step => {
        stepsToEdit.push(steps[step - 1]);
        uploaders.push(stepUploader[step - 1]);
      });
  }

  useEffect(() => {
    loader.load();
    loadPreviousInfo(token)
      .then(info => setPreviousInfo(info))
      .catch(OnboardingForm.logAndRedirect)
      .finally(loader.stop);
    // eslint-disable-next-line
  }, []);

  return (
    previousInfo && (
      <OnboardingForm
        {...rest}
        loader={loader}
        previousInfo={previousInfo}
        allSteps={steps}
        steps={stepsToEdit}
        stepUploader={uploaders}
        removeUneditableSteps={removeUneditableSteps}
      />
    )
  );
};

ConfiguredComponent.propTypes = {
  loader: PropTypes.object.isRequired,
};

export default withNotifier(withLoader(ConfiguredComponent));
