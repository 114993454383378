import { string } from "yup";
import { validateRfc, validateCnpj, validateCpf } from "./validators";
import {
  buildCharactersMessage,
  buildInvalidMessage,
  buildDigitsMessage,
} from "../utils/messageBuilders";
import { translateKey } from "../../utils/translate";

const idLengths = {
  CO: {
    nit: { min: 9, max: 11 },
  },
  AR: {
    dni: { min: 7, max: 8 },
    cuit: { max: 11 },
    cuil: { min: 10, max: 11 },
  },
  BR: {
    cpf: { max: 11 },
    cnpj: { max: 14 },
  },
  MX: {
    rfc: { min: 12, max: 13 },
  },
  PE: {
    ce: { max: 11 },
    dni: { max: 8 },
    ruc: { max: 11 },
  },
  EC: {
    cedula: { max: 10 },
    ruc: { max: 13 },
  },
  CR: {
    cedula: { max: 9 },
    ruc: { max: 10 },
  },
  UY: {
    ci: { min: 7, max: 8 },
    rut: { min: 10, max: 12 },
  },
};

const defaultIdSchema = string()
  .ensure()
  .required(translateKey("form.required"));

const idSchemas = {
  CO: (type, schema) => {
    const {
      nit: { min, max },
    } = idLengths.CO;
    const message = buildCharactersMessage(`${min} u ${max}`);
    switch (type) {
      case "nit":
        return schema.min(min, message).max(max, message);
      // .test("isNitValid", buildInvalidMessage("NIT"), validateNit);
      default:
        return schema;
    }
  },
  AR: (type, schema) => {
    let min;
    let max;
    let message;
    const { dni, cuit, cuil } = idLengths.AR;
    switch (type) {
      case "dni":
        ({ min, max } = dni);
        message = buildCharactersMessage(`${min} o ${max}`);
        return schema.min(min, message).max(max, message);
      case "cuit":
        return schema.length(cuit.max, buildCharactersMessage(cuit.max));
      case "cuil":
        ({ min, max } = cuil);
        message = buildCharactersMessage(`${min} u ${max}`);
        return schema.min(min, message).max(max, message);
      default:
        return schema;
    }
  },
  BR: (type, schema) => {
    const { cpf, cnpj } = idLengths.BR;
    switch (type) {
      case "cpf":
        return schema
          .length(cpf.max, buildCharactersMessage(cpf.max))
          .test("isCpfValid", buildInvalidMessage("CPF"), validateCpf);
      case "cnpj":
        return schema
          .length(cnpj.max, buildDigitsMessage(cnpj.max))
          .test("isCnpjValid", buildInvalidMessage("CNPJ"), validateCnpj);
      default:
        return schema;
    }
  },
  MX: (type, schema) => {
    const {
      rfc: { min, max },
    } = idLengths.MX;
    const message = buildCharactersMessage(`${min} o ${max}`);
    switch (type) {
      case "rfc":
        return schema
          .min(min, message)
          .max(max, message)
          .test("isRfcValid", buildInvalidMessage("RFC"), validateRfc);
      default:
        return schema;
    }
  },
  PE: (type, schema) => {
    const { dni, ruc } = idLengths.PE;
    switch (type) {
      case "dni":
        return schema.length(dni.max, buildDigitsMessage(dni.max));
      case "ruc":
        return schema.length(ruc.max, buildDigitsMessage(ruc.max));
      default:
        return schema;
    }
  },
  EC: (type, schema) => {
    const { cedula, ruc } = idLengths.EC;
    switch (type) {
      case "cedula":
        return schema.length(cedula.max, buildDigitsMessage(cedula.max));
      case "ruc":
        return schema.length(ruc.max, buildDigitsMessage(ruc.max));
      default:
        return schema;
    }
  },
  CR: (type, schema) => {
    const { cedula, ruc } = idLengths.CR;
    switch (type) {
      case "cedula":
        return schema.length(cedula.max, buildDigitsMessage(cedula.max));
      case "ruc":
        return schema.length(ruc.max, buildDigitsMessage(ruc.max));
      default:
        return schema;
    }
  },
  UY: (type, schema) => {
    let min;
    let max;
    let message;
    const { ci, rut } = idLengths.UY;
    switch (type) {
      case "ci":
        ({ min, max } = ci);
        message = buildCharactersMessage(`${min} u ${max}`);
        return schema.min(min, message).max(max, message);
      case "rut":
        ({ min, max } = rut);
        message = buildCharactersMessage(`${min} u ${max}`);
        return schema.min(min, message).max(max, message);
      default:
        return schema;
    }
  },
};

const getIdSchema = (country, binding, baseSchema = defaultIdSchema) => {
  const schema = baseSchema.clone();
  const whenBuilder = idSchemas[country];
  return whenBuilder ? schema.when(binding, whenBuilder) : schema;
};

export { idLengths };
export default getIdSchema;
