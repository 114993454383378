import { banksPerCountry } from "../components/organisms/BankInfoForm/BankInfoForm.constants";

const getPhonePrefix = phoneNumber => {
  const index = phoneNumber.indexOf(" ");
  return phoneNumber.substring(0, index);
};

const getPhoneNumber = phoneNumber => {
  const index = phoneNumber.indexOf(" ") + 1;
  return phoneNumber.substring(index);
};

export const toOwner = info => ({
  name: info.ownerName,
  last_name: info.ownerLastName,
  identification: info.ownerId,
  identification_type: info.ownerIdType,
  phone_number: `${info.phonePrefix} ${info.ownerTelephone}`,
  email: info.ownerEmail,
});

export const toBankInfo = info => ({
  bank_id: info.bankId,
  address: info.billingAddress,
  fiscal_address: info.fiscalAddress,
  account_number: info.number,
  type: info.typeId,
  owner: toOwner(info),
});

// eslint-disable-next-line camelcase
export const fromOwner = ({ account_owner = {} }) => ({
  ownerName: account_owner.name,
  ownerLastName: account_owner.last_name,
  ownerId: account_owner.identification,
  ownerIdType: account_owner.identification_type,
  phonePrefix: account_owner.phone_number
    ? getPhonePrefix(account_owner.phone_number)
    : null,
  ownerTelephone: account_owner.phone_number
    ? getPhoneNumber(account_owner.phone_number)
    : null,
  ownerEmail: account_owner.email,
});

export const fromBankInfo = bankInfo => ({
  bankId: bankInfo.bank_id,
  billingAddress: bankInfo.address,
  fiscalAddress: bankInfo.fiscal_address,
  number: bankInfo.account_number,
  typeId: bankInfo.account_type,
  ...fromOwner(bankInfo),
});

export const toBankCertOCR = (bankInfo, storeInfo) => ({
  data: {
    document_number: bankInfo.ownerId,
    first_name: bankInfo.ownerName,
    last_name: bankInfo.ownerLastName,
    bank_account_number: bankInfo.number,
    bank_account_name: banksPerCountry.getDescriptionByCountryCode(
      storeInfo.country,
      bankInfo.bankId,
    ),
    url: storeInfo.bank_certification_url,
  },
});
