import React, { useMemo } from "react";
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core/";
import PropTypes from "prop-types";
import classNames from "classnames";
import { translateKeyWithHtml } from "../../../../../utils/translate";
import useStyles from "./Faq.style";

const Steps = ({ pageClasses }) => {
  const classes = useStyles();

  const faqs = useMemo(
    () =>
      Array(8)
        .fill(0)
        .map((_, index) => {
          const basePath = `inboundPage.faq.list.qa_${index + 1}`;
          return (
            <ExpansionPanel className={classes.faqContainer} key={basePath}>
              <ExpansionPanelSummary
                expandIcon={<div className="expandIcon" />}
                className={classes.question}
              >
                {translateKeyWithHtml(`${basePath}.question`)}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.answer}>
                {translateKeyWithHtml(`${basePath}.answer`)}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        }),
    [classes],
  );

  return (
    <>
      <Typography
        variant="h3"
        className={classNames(pageClasses.sub2, pageClasses.headerBottomMargin)}
      >
        Preguntas Frecuentes
      </Typography>
      <div className={classNames(pageClasses.perkCard, classes.faqWrapper)}>
        {faqs}
      </div>
    </>
  );
};

Steps.propTypes = {
  pageClasses: PropTypes.object.isRequired,
};

export default Steps;
