import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { Grid, useMediaQuery } from "@material-ui/core/";
import classNames from "classnames";
import { translateKey } from "../../../utils/translate";
import Button from "../../atoms/Button";
import Slider from "../../organisms/Slider";
import RappiBar from "../../molecules/RappiBar";
import Carousel1 from "../../../assets/images/inbound-carousel-1.png";
import Carousel2 from "../../../assets/images/inbound-carousel-2.png";

const InboundPage = ({
  classes,
  loaded,
  form,
  onButtonClick,
  buttonDisabled,
}) => {
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const [isValid, setValid] = useState(false);

  return (
    <Grid container>
      {!isDownSm && (
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Slider
            items={[Carousel1, Carousel2]}
            title={translateKey("form.inbound.title")}
            subtitle={translateKey("form.inbound.subtitle")}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
        <RappiBar underLine={false} />
        <div className={classes.inboundWrapper}>
          {loaded ? form.render(loaded, setValid) : null}
        </div>
        <div className={classNames(classes.field, classes.buttonWrapper)}>
          <Button
            fullWidth
            disabled={!isValid || buttonDisabled}
            secondary={!isValid || buttonDisabled}
            onClick={onButtonClick}
          >
            {translateKey("form.sendRequest")}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

InboundPage.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object,
  onButtonClick: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  buttonDisabled: PropTypes.bool.isRequired,
};

InboundPage.defaultProps = {
  form: null,
};

export default InboundPage;
