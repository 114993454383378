import React from "react";
import { string } from "yup";
import TextField from "../components/atoms/TextField";
import { translateKey } from "../utils/translate";
import { buildInvalidMessage } from "../schemasAndConstraints/utils/messageBuilders";

export const getAdditionalComponents = (
  fields,
  values,
  handleChange,
  setFieldTouched,
) =>
  fields
    .filter(
      field => field.label && field.name && field.type && field.type === "text",
    )
    .map(field => (
      <TextField
        showError
        key={field.name}
        name={`additionalFields.${field.name}`}
        value={values.additionalFields[field.name]}
        title={field.label}
        placeholder={translateKey("form.writeHere")}
        handleChange={handleChange}
        setFieldTouched={setFieldTouched}
      />
    ));

export const makeAdditionalSchema = fields =>
  fields
    .filter(
      field => field.label && field.name && field.type && field.type === "text",
    )
    .map(field => ({
      [field.name]: field.validation_regex
        ? string()
            .matches(
              new RegExp(field.validation_regex),
              buildInvalidMessage("Campo"),
            )
            .ensure()
        : string().required(),
    }))
    .reduce((prev, next) => ({ ...prev, ...next }), {});
