import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./AttachFilesForm.style";
import FileInput from "../../atoms/FileInput";
import FieldDescriptor from "../../atoms/FieldDescriptor";
import { translateKey, translateKeyWithHtml } from "../../../utils/translate";
import mergeFilesIntoPdf from "../../../utils/mergeFilesIntoPdf";
import { onChangeIdentificationFile } from "../../../api/ocr/nationalDocument/ocrNationalDocumentStatus";

const INITIAL_FILES = {
  identification_front: { file: null },
  identification_back: { file: null },
};

const uploaderNames = {
  identification_front: "identification",
  identification_back: "identificationBack",
};

const IdMergerInputs = ({
  classes,
  values,
  onFileError,
  uploaders,
  images,
  setStepAdditionalData,
}) => {
  const id = values.identification;
  const idBack = values.identificationBack;
  const [idFileStatus, setIdFileStatus] = useState("UNMERGED");
  const [idFiles, setIdFiles] = useState(() =>
    id
      ? {
          ...INITIAL_FILES,
          identification_front: { file: { name: id } },
        }
      : INITIAL_FILES,
  );
  const { file: frontFile } = idFiles.identification_front;
  const { file: backFile } = idFiles.identification_back;

  const setManagers = (name, fileInputManager) => {
    setIdFiles(idFiles => ({
      ...idFiles,
      [name]: { ...idFiles[name], fileInputManager },
    }));
  };

  const onIdFileSelect = (evt, fileInputManager) => {
    const { name, file } = evt;
    const uploaderName = uploaderNames[name];
    if (file == null) {
      uploaders.identification({ file: null }, fileInputManager);
      uploaders[uploaderName](evt, fileInputManager);
    }
    setIdFiles(idFiles => ({ ...idFiles, [name]: { file, fileInputManager } }));
    setIdFileStatus("UNMERGED");
    onChangeIdentificationFile();
  };

  useEffect(() => {
    const {
      identification_front: { file: front, fileInputManager: frontManager },
      identification_back: { file: back, fileInputManager: backManager },
    } = idFiles;
    async function handleUploads() {
      if (!id && front) {
        setIdFileStatus("UPLOADING");
        await uploaders.identification({ file: front }, frontManager);
        setIdFileStatus("UNMERGED");
      }

      if (!idBack && back) {
        setIdFileStatus("UPLOADING");
        await uploaders.identificationBack({ file: back }, backManager);
        setIdFileStatus("UNMERGED");
      }

      if (id && idBack) {
        setIdFileStatus("UPLOADING");

        const imageData = {
          identificationDocument: { front: id, back: idBack },
        };
        setStepAdditionalData("files", imageData);

        mergeFilesIntoPdf([front, back], "identification.pdf")
          .then(file => {
            uploaders.identification(
              { name: "identification", file },
              frontManager,
            );
          })
          .catch(() => {
            setIdFileStatus("UNMERGED");
            onFileError(
              null,
              translateKey("form.attachFilesForm.identificationError"),
            );
          });
      }
    }

    if (idFileStatus !== "UPLOADED" && idFileStatus !== "UPLOADING") {
      handleUploads();
    }
    // eslint-disable-next-line
  }, [id, idBack, idFileStatus, idFiles]);

  useEffect(() => {
    if (id && idBack && id.includes(".pdf")) {
      setIdFileStatus("UPLOADED");
    }
  }, [id, idBack]);

  return (
    <>
      <Grid item xs={12} md={6} className={classes.field}>
        <FieldDescriptor noWrap className={classes.block}>
          {translateKeyWithHtml("form.attachFilesForm.identification")}
        </FieldDescriptor>
        <FileInput
          name="identification_front"
          placeholder=""
          value={frontFile ? frontFile.name : null}
          disabled={idFileStatus === "UPLOADING"}
          onChange={onIdFileSelect}
          onError={onFileError}
          accept=".png, .jpg, .pdf, .jpeg"
          shareManager={setManagers}
          imageExample={images[0]}
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.field}>
        <FieldDescriptor noWrap className={classes.block}>
          {translateKeyWithHtml("form.attachFilesForm.identificationBack")}
        </FieldDescriptor>
        <FileInput
          name="identification_back"
          placeholder=""
          value={backFile ? backFile.name : null}
          disabled={idFileStatus === "UPLOADING" || !frontFile}
          onChange={onIdFileSelect}
          onError={onFileError}
          accept=".png, .jpg, .pdf, .jpeg"
          shareManager={setManagers}
        />
      </Grid>
    </>
  );
};

IdMergerInputs.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onFileError: PropTypes.func.isRequired,
  uploaders: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
  setStepAdditionalData: PropTypes.func.isRequired,
};

export default withStyles(styles)(IdMergerInputs);
