import React from "react";
import { Grid, Typography, Card } from "@material-ui/core/";
import PropTypes from "prop-types";
import classNames from "classnames";
import { GridHalfAtMd } from "../../../../../utils/Grids";
import { translateKeyWithHtml } from "../../../../../utils/translate";
import Dish1 from "../../../../../assets/images/InboundPage/plato-1@2x.png";
import Dish2 from "../../../../../assets/images/InboundPage/plato-2@2x.png";
import Dish3 from "../../../../../assets/images/InboundPage/plato-3@2x.png";
import useStyles from "./BenefitsCard.style";

const BenefitsCard = ({ pageClasses }) => {
  const classes = useStyles();

  return (
    <Card className={classes.benefitsCard}>
      <Grid
        container
        className={classNames(pageClasses.fullHeight, classes.benefitsCardGrid)}
      >
        <GridHalfAtMd item className={classes.benefitsCardTextWrapper}>
          <div className={pageClasses.flexCentering}>
            <div>
              <Typography
                variant="h3"
                className={classNames(
                  classes.benefitsCardText,
                  pageClasses.strongMain,
                )}
              >
                {translateKeyWithHtml("inboundPage.discoverBenefits.title")}
              </Typography>
            </div>
          </div>
        </GridHalfAtMd>
        <GridHalfAtMd
          item
          className={classNames(
            pageClasses.overflowVisible,
            classes.sampleFoodContainer,
          )}
        >
          <img src={Dish1} alt="" />
          <img src={Dish2} alt="" />
          <img src={Dish3} alt="" />
        </GridHalfAtMd>
      </Grid>
    </Card>
  );
};

BenefitsCard.propTypes = {
  pageClasses: PropTypes.object.isRequired,
};

export default BenefitsCard;
