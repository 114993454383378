import React, { Component } from "react";
import PropTypes from "prop-types";
import StepperForm from "../../components/pages/StepperForm";
import notifyByError from "../../utils/notifyByError";
import { loadPreviousInfo } from "../../api/steps";
import logEvent from "../../utils/EventLogger";
import FinalStep from "../../components/organisms/FinalStep";
import setStoreStatusPending from "../../api/storeStatus";

class OnboardingForm extends Component {
  static logAndRedirect(error) {
    logEvent("ERROR_STARTING_ONBOARDING", error);
    window.location = "/inbound";
  }

  state = {
    activeStep: null,
    higherUploadedStep: null,
    store: {},
    storeProcessInfo: {},
    hadModifications: false,
    stepsValues: {
      additionalData: {},
    },
  };

  async componentDidMount() {
    document.title = "Restaurant Onboarding";

    const {
      previousInfo,
      match: {
        params: { token },
      },
    } = this.props;

    if (previousInfo.pending_modifications) {
      try {
        const {
          reason: declinedReason,
          steps,
        } = previousInfo.pending_modifications;
        await this.setStoreProcess(previousInfo, token, true);
        await this.loadRequirementsTillStep(Math.max(...steps));
        this.setState({
          declinedReason,
          higherUploadedStep: 0,
          activeStep: 0,
          stepsValues: this.getStepsFormInitialValues(),
        });
      } catch (error) {
        OnboardingForm.logAndRedirect(error);
      }
    } else {
      this.setStoreProcess(previousInfo, token)
        .then(info => {
          const stepsValues = this.getStepsFormInitialValues();
          this.setState({ stepsValues });
          return info;
        })
        .then(this.setCurrentStep)
        .catch(OnboardingForm.logAndRedirect);
    }
  }

  setStoreProcess = (info, token, hadModifications) => {
    const steps = info.steps.reduce(
      (prev, next) => ({ ...prev, [next.step_number]: next.fields }),
      {},
    );
    return new Promise(resolve => {
      this.setState(
        {
          hadModifications,
          store: info.store,
          storeProcessInfo: {
            token,
            steps,
            countryCode: info.store.country,
            deliveryTypes: info.delivery_types,
          },
        },
        () => resolve(info),
      );
    });
  };

  setCurrentStep = async info => {
    const activeStep = this.getActiveStep(info);
    const higherUploadedStep = this.getHigherUploadedStep(info);
    await this.loadRequirementsTillStep(higherUploadedStep);
    if (activeStep == null) {
      logEvent("STARTING_ONBOARDING", {});
    } else {
      logEvent("REVISIT_ONBOARDING", { step: activeStep });
    }
    return new Promise(resolve => {
      this.setState({ activeStep, higherUploadedStep }, () =>
        resolve(higherUploadedStep + 1),
      );
    });
  };

  getActiveStep = ({ transitions, steps }) => {
    const lastTransition = transitions[transitions.length - 1] || {};
    const step = steps.find(step => step.id === lastTransition.step_id);
    if (!step) {
      return this.getHigherUploadedStep({ transitions, steps }) || null;
    }
    return step.step_number || 0;
  };

  getHigherUploadedStep = ({ transitions, steps }) => {
    if (transitions.length === 0) return null;
    const stepMap = steps.reduce(
      (map, step) => ({
        ...map,
        [step.id]: step,
      }),
      {},
    );

    const stepTransitionsNumber = transitions.map(t => {
      const stepId = t.step_id;
      const step = stepMap[stepId];
      return !step ? 0 : step.step_number;
    });

    const max = Math.max(...stepTransitionsNumber);
    return max >= steps.length ? max : max;
  };

  loadRequirementsTillStep = async step => {
    const { store, storeProcessInfo } = this.state;
    const { steps } = this.props;
    const promises = [];
    const to = step >= steps.length ? steps.length - 1 : step;
    for (let i = 0; i <= to; i += 1) {
      promises.push(steps[i].load(store, storeProcessInfo));
    }
    return Promise.all(promises);
  };

  getStepsFormInitialValues = () => {
    const { allSteps } = this.props;
    const { store } = this.state;
    const initialStepsValues = allSteps.reduce(
      (values, step) => ({
        ...values,
        [step.getName()]: step.getInitialValues(store),
      }),
      {},
    );

    return { ...initialStepsValues, additionalData: {} };
  };

  setStepAdditionalData = (name, data) => {
    const { stepsValues } = this.state;
    const { additionalData } = stepsValues;
    this.setState({
      stepsValues: {
        ...stepsValues,
        additionalData: { ...additionalData, [name]: data },
      },
    });
  };

  uploadStep = (stepInfo, step) => {
    const nextStep = step + 1;
    const {
      store,
      stepsValues,
      storeProcessInfo,
      higherUploadedStep,
    } = this.state;
    const { token } = storeProcessInfo;
    const { steps, loader, notifier, stepUploader } = this.props;
    const { values: stepValues } = stepInfo;

    loader.load();
    stepUploader[step](stepValues, token, store, stepsValues.additionalData)
      .then(() => loadPreviousInfo(token))
      .then(async info => {
        await steps[nextStep].load(info.store, storeProcessInfo);
        this.setState({
          activeStep: nextStep,
          higherUploadedStep: Math.max(higherUploadedStep, nextStep),
          store: info.store,
          stepsValues: { ...stepsValues, [stepInfo.getName()]: stepValues },
        });
        logEvent(`STEP[${step + 1}]-FINISHED`, {});
      })
      .catch(notifyByError(notifier, step))
      .finally(loader.stop);
  };

  uploadLastStep = (stepInfo, step) => {
    const {
      store,
      stepsValues,
      storeProcessInfo,
      declined,
      hadModifications,
    } = this.state;
    const { token } = storeProcessInfo;
    const { notifier, loader, stepUploader, steps } = this.props;
    const { values: stepValues } = stepInfo;

    loader.load();
    stepUploader[step](stepInfo.values, token, store)
      .then(async () => {
        if (hadModifications) await setStoreStatusPending(store.deal_id);
        this.setState({
          activeStep: steps.length,
          higherUploadedStep: steps.length,
          stepsValues: { ...stepsValues, [stepInfo.getName()]: stepValues },
        });
        logEvent(`STEP[${step + 1}]-FINISHED`, {});
        if (!declined) logEvent(`ONBOARDING_FINISHED`, {});
      })
      .catch(notifyByError(notifier, step))
      .finally(loader.stop);
  };

  nextStep = () => {
    const { steps } = this.props;
    const { activeStep, declined } = this.state;
    const isLastStep = activeStep === steps.length - 1;
    const isEndProcessForDeclined = isLastStep && declined;
    if (steps[activeStep].isValid() || isEndProcessForDeclined) {
      if (isLastStep) {
        this.uploadLastStep(steps[activeStep], activeStep);
      } else {
        steps[activeStep].simulateSubmitForm();
        this.uploadStep(steps[activeStep], activeStep);
      }
    } else {
      steps[activeStep].simulateSubmitForm();
    }
  };

  previousStep = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep > 0 ? activeStep - 1 : activeStep,
    });
  };

  goToStep = step => {
    const { steps, notifier } = this.props;
    const { store, storeProcessInfo } = this.state;
    steps[step]
      .load(store, storeProcessInfo)
      .then(() => {
        this.setState({ activeStep: step });
      })
      .catch(notifyByError(notifier, -1));
  };

  initForm = () => {
    const { store, storeProcessInfo } = this.state;
    const { steps, loader, notifier } = this.props;
    loader.load();
    steps[0]
      .load(store, storeProcessInfo)
      .then(() => {
        this.setState({ activeStep: 0 });
      })
      .catch(notifyByError(notifier, -1))
      .finally(loader.stop);
  };

  render() {
    const { steps } = this.props;
    const {
      activeStep,
      higherUploadedStep,
      declinedReason,
      store,
      hadModifications,
      stepsValues,
    } = this.state;
    const isFinished = higherUploadedStep >= steps.length;

    return isFinished ? (
      <FinalStep hadModifications={hadModifications} userInfo={store} />
    ) : (
      <StepperForm
        userInfo={store}
        activeStep={activeStep}
        higherUploadedStep={higherUploadedStep}
        steps={steps}
        stepsValues={stepsValues}
        setStepAdditionalData={this.setStepAdditionalData}
        previousStep={this.previousStep}
        nextStep={this.nextStep}
        goToStep={this.goToStep}
        onInit={this.initForm}
        alert={declinedReason}
      />
    );
  }
}

OnboardingForm.defaultProps = {
  previousInfo: null,
};

OnboardingForm.propTypes = {
  previousInfo: PropTypes.object,
  allSteps: PropTypes.array.isRequired,
  steps: PropTypes.array.isRequired,
  loader: PropTypes.object.isRequired,
  notifier: PropTypes.object.isRequired,
  stepUploader: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
};

export default OnboardingForm;
