import requester from "./requester";

export const getCnpjData = cnpj =>
  requester()
    .get("/api/rs-self-onboarding/social-reason/validate", {
      params: {
        country: "BR",
        type: "CNPJ",
        number: cnpj,
      },
    })
    .then(res => res.data);

export default getCnpjData;
