export const limitLength = (string, length) => string.slice(0, length);

export const onlyDigits = string => string.replace(/[^\d]+/g, "");

export const noDigits = string => string.replace(/[0-9]/g, "");

export const limitLengthOnlyDigits = (string, length) =>
  limitLength(onlyDigits(string), length);

export const onHandlerNumber = e => {
  e.target.value = e.target.value.replace(/[^0-9]/g, "");
};
