import React from "react";
import withLoader from "../../hocs/loader/withLoader";
import withNotifier from "../../hocs/notifications/withNotifier";
import Inbound from "./Inbound";
import InboundForm from "./Landing";
import Form, { loadRequirements } from "../../components/organisms/InboundForm";

const form = new Inbound(
  Form,
  loadRequirements,
  "form.titles.newLead",
  "rappi_inbound_form",
);

const ConfiguredComponent = props => <InboundForm {...props} form={form} />;

export default withNotifier(withLoader(ConfiguredComponent));
