import { translateKey } from "../../../../../utils/translate";
import { addIds } from "../../../../../utils/functions";

const categoriesBasePath = "inboundPage.footer.categories.list";
const categoryArray = [
  {
    url: "https://www.rappi.com.co/restaurantes",
    text: translateKey(`${categoriesBasePath}.restaurants`),
  },
  {
    url: "https://www.rappi.com.co/tiendas/tipo/express-big",
    text: translateKey(`${categoriesBasePath}.marketExpress`),
  },
  {
    url: "https://www.rappi.com.co/mall",
    text: translateKey(`${categoriesBasePath}.rappiMall`),
  },
  {
    url: "#0",
    text: translateKey(`${categoriesBasePath}.cravings`),
  },
  {
    url: "#0",
    text: translateKey(`${categoriesBasePath}.rappiFavor`),
  },
  {
    url: "#0",
    text: translateKey(`${categoriesBasePath}.atm`),
  },
  {
    url: "https://www.rappi.com.co/tiendas/tipo/vinos-y-licores",
    text: translateKey(`${categoriesBasePath}.liquors`),
  },
  {
    url: "https://www.rappi.com.co/tiendas/tipo/super-farma",
    text: translateKey(`${categoriesBasePath}.drugStores`),
  },
  {
    url: "https://www.rappi.com.co/tiendas/tipo/pets",
    text: translateKey(`${categoriesBasePath}.pets`),
  },
  {
    url: "https://www.rappi.com.co/tiendas/tipo/baby",
    text: translateKey(`${categoriesBasePath}.babyWorld`),
  },
  {
    url: "https://viajes.rappi.com.co/",
    text: translateKey(`${categoriesBasePath}.travel`),
  },
  {
    url: "https://www.rappi.com.co/tiendas/tipo/floristeria2",
    text: translateKey(`${categoriesBasePath}.flowerShops`),
  },
  {
    url: "#0",
    text: translateKey(`${categoriesBasePath}.carWash`),
  },
  {
    url: "https://www.rappi.com.co/search?store_type=all&query=manicure",
    text: translateKey(`${categoriesBasePath}.manicures`),
  },
];

const interestingInfoBasePath = "inboundPage.footer.interestingInfo.list";
const interestingInfoArray = [
  {
    url:
      "https://legal.rappi.com/colombia/terminos-y-condiciones-de-uso-de-plataforma-rappi-2/",
    text: translateKey(`${interestingInfoBasePath}.termsAndConditions`),
  },
  {
    url:
      "https://legal.rappi.com/colombia/politica-de-proteccion-y-tratamiento-de-datos-personales-rappi-s-a-s/",
    text: translateKey(`${interestingInfoBasePath}.personalData`),
  },
  {
    url: "#0",
    text: translateKey(`${interestingInfoBasePath}.aboutRappi`),
  },
  {
    url:
      "https://soyrappi.com/?utm_source=appuser&utm_medium=applk&utm_campaign=rt",
    text: translateKey(`${interestingInfoBasePath}.iWannaDeliver`),
  },
  {
    url: "#0",
    text: translateKey(`${interestingInfoBasePath}.helpCenter`),
  },
  {
    url: "https://www.rappi.com/jobs/",
    text: translateKey(`${interestingInfoBasePath}.workWithUs`),
  },
  {
    url: "https://blog.rappi.com/",
    text: translateKey(`${interestingInfoBasePath}.blog`),
  },
  {
    url: "#0",
    text: translateKey(`${interestingInfoBasePath}.privacyPolicy`),
  },
];

export const categories = addIds(categoryArray);
export const interestingInfo = addIds(interestingInfoArray);
