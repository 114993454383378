import { makeStyles } from "@material-ui/core";

const modalDocumentExampleStyle = makeStyles(
  {
    container: {
      padding: "10px",
    },
    image: {
      minWidth: "340px",
      overflow: "scroll",
    },
  },
  { name: "modal", classNamePrefix: "modal", index: 1 },
);

export default modalDocumentExampleStyle;
