/* eslint-disable func-names */
import * as defaultSchemas from "../default/schemas";
import getAccountVerificationDigit from "./calculators";
import { buildDigitsMessage } from "../../../../utils/messageBuilders";
import { translateKey } from "../../../../../utils/translate";

const ACCOUNT_LENGTH = { min: 2, max: 8 };
const AGENCY_LENGTH = 4;

const { min, max } = ACCOUNT_LENGTH;
const requiredMessage = translateKey("form.bankInfoForm.required");

export const bankAccountNumber = defaultSchemas.bankAccountNumber
  .clone()
  .required(requiredMessage)
  .min(min, buildDigitsMessage(min, "min"))
  .max(max, buildDigitsMessage(max, "max"));

const bankAccountVerificationDigit = defaultSchemas
  .getAccountVerificationDigitSchema()
  .clone();

export const getAccountVerificationDigitSchema = accountNumber =>
  bankAccountVerificationDigit.test(
    "accountAndDigitMatch",
    "Dígito não corresponde à conta",
    function() {
      const { agency, account_digit: ad } = this.parent;
      return getAccountVerificationDigit(accountNumber, agency) === ad;
    },
  );

export const agency = defaultSchemas.agency
  .clone()
  .required(requiredMessage)
  .length(AGENCY_LENGTH, buildDigitsMessage(AGENCY_LENGTH));

export const agencyVerificationDigit = defaultSchemas.agencyVerificationDigit.clone();
