/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import filterBlurred from "../api/filterBlurred";
import pngToJpeg from "./pngToJpeg";

const fileChecks = {
  blurryness: (image, onUploadProgress) =>
    filterBlurred(image, "b64", onUploadProgress),
};

const runFileChecks = (file, checks = [], progressUpdater = () => {}) =>
  new Promise((resolve, reject) => {
    const { type } = file;

    if (checks.length <= 0 || !type.startsWith("image/")) {
      resolve([{ status: "APPROVED" }]); // TODO: might remove this later
    }

    const checkPct = Math.floor(100 / (checks.length + 1)) / 100;
    const format = type.slice(type.indexOf("/") + 1);
    const reader = new FileReader();
    const updateProgress = ({ loaded, total }) => {
      const pct = Math.round((loaded * 100 * checkPct) / total);
      progressUpdater(pct);
    };

    reader.onload = async evt => {
      const results = [];
      const base64 = evt.target.result.replace(/data:[\w]+\/[\w]+;base64,/, "");
      const checksToRun = checks.map(check => ({
        name: check,
        fn: fileChecks[check],
      }));

      for (const check of checksToRun) {
        let result;
        try {
          result = await check.fn(base64, updateProgress);
          results.push({ name: check.name, status: result });
          if (result !== "APPROVED") {
            break;
          }
        } catch (error) {
          reject(error);
        }
      }

      resolve(results);
    };

    switch (format) {
      case "png":
        pngToJpeg(file).then(image => reader.readAsDataURL(image));
        break;
      case "jpeg":
        reader.readAsDataURL(file);
        break;
      default:
        reject(Error(`Image format (${format}) not supported`));
    }
  });

export default runFileChecks;
