const stylizer = theme => ({
  carousel: {
    "& .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover": {
      background: theme.palette.secondary.main,
    },
  },
  image: {
    [theme.breakpoints.down("xl")]: {
      height: "100vh",
    },
    [theme.breakpoints.down("lg")]: {
      height: "100vh",
    },
    [theme.breakpoints.down("md")]: {
      height: "100vh",
    },
    [theme.breakpoints.down("sm")]: {
      height: "50vh",
    },
    [theme.breakpoints.down("xs")]: {
      height: "50vh",
    },
    background: "transparent no-repeat center",
    backgroundSize: "cover",
  },
  text: {
    position: "absolute",
    top: "14%",
    padding: "10vh",
    zIndex: 999,
    color: "#ffffff",
  },
  title: {
    fontFamily: theme.typography.fontFamily.bold,
    lineHeight: "60px",
    fontSize: 50,
  },
  subtitle: {
    fontFamily: theme.typography.fontFamily.light,
    fontWeight: 100,
    fontSize: 20,
  },
});

export default stylizer;
