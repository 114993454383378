import CustomHttpException, {
  CustomizedError,
} from "../../../utils/CustomHttpException";

const MAX_ATTEMPTS = 2;
export const OCR_ATTEMPTS_NATIONAL_ID = {
  /**
   * This flag means that the user tried to change the image or bank account number.
   */
  userChangedTheInfo: true,
  attempts: 0,
  isReachedTheMaxAttempts() {
    return this.attempts >= MAX_ATTEMPTS;
  },
  isValidToTryAgain() {
    return this.userChangedTheInfo && !this.isReachedTheMaxAttempts();
  },
  plus() {
    this.userChangedTheInfo = false;
    this.attempts += 1;
  },
};

export const updateOCRStatus = () => {
  OCR_ATTEMPTS_NATIONAL_ID.userChangedTheInfo = false;
  OCR_ATTEMPTS_NATIONAL_ID.plus();
};

export const resetOCRStatus = () => {
  OCR_ATTEMPTS_NATIONAL_ID.userChangedTheInfo = true;
  OCR_ATTEMPTS_NATIONAL_ID.attempts = 0;
};

export const throwIdentificationNumberFailedException = response => {
  throw new CustomHttpException({
    customError: CustomizedError.OCR_ID_FAILED,
    responseBody: response,
  });
};

export const identificationNumberFailedResponse = request => ({
  ocr_identification_status: "FAILED",
  ocr_identification_description: `The identification number: ${request.entities.identification} is invalid`,
});

export const identificationNumberOKResponse = request => ({
  ocr_identification_status: "OK",
  ocr_identification_description: `The identification number: ${request.entities.identification} is valid`,
});

export const onChangeIdentificationFile = () => {
  OCR_ATTEMPTS_NATIONAL_ID.userChangedTheInfo = true;
};
