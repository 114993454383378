import React from "react";
import * as PropTypes from "prop-types";
import classNames from "classnames";
import { Container } from "@material-ui/core/";
import RappiBar from "../../molecules/RappiBar";
import HeaderAndForm from "./PageComponents/HeaderAndForm/HeaderAndForm";
import BenefitsCard from "./PageComponents/BenefitsCard/BenefitsCard";
import Perks from "./PageComponents/Perks/Perks";
import ClientQuotes from "./PageComponents/ClientQuotes/ClientQuotes";
import BrandGrowth from "./PageComponents/BrandGrowth/BrandGrowth";
import RappiHighlights from "./PageComponents/RappiHighlights/RappiHighlights";
import Steps from "./PageComponents/Steps/Steps";
import RestaurantsBanner from "./PageComponents/BrandsBanner/BrandsBanner";
import Faq from "./PageComponents/Faq/Faq";
import Footer from "./PageComponents/Footer/Footer";
import useStyles from "./InboundPage.style";

const InboundPage = ({ loaded, form, onSend }) => {
  const classes = useStyles();
  const renderedForm = loaded ? form.render({}, onSend) : null;

  return (
    <Container disableGutters maxWidth={false} className={classes.inboundPage}>
      <RappiBar
        underLine={false}
        logoAlt="Logo de Rappi. Suma tu restaurante a Rappi y aumenta tus ventas"
      />
      <HeaderAndForm pageClasses={classes} form={renderedForm} />
      <section
        className={classNames(
          classes.fullWidth,
          classes.oddEven,
          classes.paddedVertical,
        )}
      >
        <Container disableGutters maxWidth={false} className={classes.bounded}>
          <Steps pageClasses={classes} />
        </Container>
      </section>
      <section className={classes.fullWidth}>
        <Container
          disableGutters
          maxWidth={false}
          className={classNames(
            classes.flexCentering,
            classes.bounded,
            classes.benefitsCardWrapper,
          )}
        >
          <BenefitsCard pageClasses={classes} />
        </Container>
      </section>
      <section className={classNames(classes.fullWidth, classes.paddedBottom)}>
        <Container disableGutters maxWidth={false} className={classes.bounded}>
          <Perks pageClasses={classes} />
        </Container>
      </section>
      <section
        className={classNames(
          classes.fullWidth,
          classes.oddEven,
          classes.paddedTop,
        )}
      >
        <Container disableGutters maxWidth={false} className={classes.bounded}>
          <ClientQuotes pageClasses={classes} />
        </Container>
      </section>
      <section
        className={classNames(
          classes.fullWidth,
          classes.oddEven,
          classes.elevated1,
          classes.positionRelative,
          classes.paddedVertical,
        )}
      >
        <Container disableGutters maxWidth={false} className={classes.bounded}>
          <BrandGrowth pageClasses={classes} />
        </Container>
      </section>
      <section
        className={classNames(
          classes.fullWidth,
          classes.oddEven,
          classes.paddedVertical,
        )}
      >
        <Container disableGutters maxWidth={false} className={classes.bounded}>
          <RappiHighlights pageClasses={classes} />
        </Container>
      </section>
      <section
        className={classNames(
          classes.fullWidth,
          classes.oddEven,
          classes.paddedVertical,
        )}
      >
        <Container disableGutters maxWidth={false}>
          <RestaurantsBanner pageClasses={classes} />
        </Container>
      </section>
      <section
        className={classNames(
          classes.fullWidth,
          classes.oddEven,
          classes.paddedVertical,
        )}
      >
        <Container disableGutters maxWidth={false} className={classes.bounded}>
          <Faq pageClasses={classes} />
        </Container>
      </section>
      <section
        className={classNames(
          classes.fullWidth,
          classes.oddEven,
          classes.paddedVertical,
        )}
      >
        <Container disableGutters maxWidth={false} className={classes.bounded}>
          <Footer pageClasses={classes} />
        </Container>
      </section>
    </Container>
  );
};

InboundPage.propTypes = {
  form: PropTypes.object,
  onSend: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
};

InboundPage.defaultProps = {
  form: null,
};

export default InboundPage;
