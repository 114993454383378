const getModEleven = sumSeq => {
  const result = 11 - (sumSeq % 11);

  if (result === 10) {
    return "X";
  }

  if (result === 11) {
    return "0";
  }

  return result.toString();
};

export const getAccountVerificationDigit = accountNumber => {
  let sumSeq = 0;
  const numbers = accountNumber.split("");

  for (let i = 0; i < numbers.length; i += 1) {
    const seq = 9 - i;
    sumSeq += parseInt(numbers[i], 10) * seq;
  }

  return getModEleven(sumSeq);
};

export const getAgencyVerificationDigit = agencyNumber => {
  const numbers = agencyNumber.split("");
  let sumSeq = 0;

  for (let i = 0; i < numbers.length; i += 1) {
    const seq = 5 - i;
    sumSeq += parseInt(numbers[i], 10) * seq;
  }

  return getModEleven(sumSeq);
};
