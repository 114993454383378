import React from "react";
import { Dialog, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "../../../../atoms/Button";
import useInboundPageStyles from "../../InboundPage.style";
import useStyles from "./Modal.style";

const Modal = ({
  title,
  subtitle,
  buttonText,
  image,
  imageWrapper,
  open,
  onButtonClick,
  ...dialogProps
}) => {
  const classes = useStyles();
  const pageClasses = useInboundPageStyles();

  return (
    <Dialog open={open} {...dialogProps}>
      <div className={classes.wrapper}>
        {image && (
          <div className={pageClasses.flexCentering}>
            <div
              className={classNames(
                pageClasses.flexCentering,
                imageWrapper && classes.imageWrapper,
                classes[imageWrapper],
              )}
            >
              <img alt="modal" className={classes.image} src={image} />
            </div>
          </div>
        )}
        {title ? (
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
        ) : null}
        <Typography
          variant="h5"
          className={classNames(pageClasses.dim, classes.subtitle)}
        >
          {subtitle}
        </Typography>
        {buttonText && (
          <div className={classes.container}>
            <Button fullWidth onClick={onButtonClick}>
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  image: PropTypes.string,
  imageWrapper: PropTypes.string,
  open: PropTypes.bool,
  onButtonClick: PropTypes.func,
};

Modal.defaultProps = {
  open: false,
  title: null,
  subtitle: null,
  buttonText: null,
  image: null,
  imageWrapper: "",
  onButtonClick: () => {},
};

export default Modal;
