// eslint-disable-next-line import/prefer-default-export
import ArgentinianFlag from "../../../assets/images/flags/ar.svg";
import BrazilianFlag from "../../../assets/images/flags/br.svg";
import ChileanFlag from "../../../assets/images/flags/cl.svg";
import ColombianFlag from "../../../assets/images/flags/co.svg";
import MexicanFlag from "../../../assets/images/flags/mx.svg";
import PeruvianFlag from "../../../assets/images/flags/pe.svg";
import UruguayanFlag from "../../../assets/images/flags/uy.svg";
import CostaRicaFlag from "../../../assets/images/flags/cr.svg";
import EcuatorianFlag from "../../../assets/images/flags/ec.svg";
import { translateKey } from "../../../utils/translate";

export const banksPerCountry = {
  BR: [
    {
      value: 2,
      country_code: "BR",
      label: "Banco do Brasil S.A.",
    },
    {
      value: 10,
      country_code: "BR",
      label: "Banco Santander (Brasil) S.A.",
    },
    {
      value: 47,
      country_code: "BR",
      label: "Banco Bradesco S.A.",
    },
    {
      value: 56,
      country_code: "BR",
      label: "Banco Itaú S.A.",
    },
    {
      value: 32,
      country_code: "BR",
      label: "Caixa Econômica Federal",
    },
    {
      value: 647,
      country_code: "BR",
      label: "PagSeguro Internet S.A.",
    },
    {
      value: 65,
      country_code: "BR",
      label: "Banco Safra S.A.",
    },
    {
      value: 640,
      country_code: "BR",
      label: "Stone Pagamentos S.A.",
    },
    {
      value: 92,
      country_code: "BR",
      label: "Banco Votorantim S.A.",
    },
    {
      value: 3,
      country_code: "BR",
      label: "Banco da Amazônia S.A.",
    },
    {
      value: 4,
      country_code: "BR",
      label: "Banco do Nordeste do Brasil S.A.",
    },
    {
      value: 5,
      country_code: "BR",
      label: "BANESTES S.A. Banco do Estado do Espírito Santo",
    },
    {
      value: 12,
      country_code: "BR",
      label: "Banco do Estado do Pará S.A.",
    },
    {
      value: 15,
      country_code: "BR",
      label: "Banco do Estado do Rio Grande do Sul S.A.",
    },
    {
      value: 18,
      country_code: "BR",
      label: "Banco do Estado de Sergipe S.A.",
    },
    {
      value: 19,
      country_code: "BR",
      label: "Hipercard Banco Múltiplo S.A.",
    },
    {
      value: 20,
      country_code: "BR",
      label: "Banco Ibi S.A. Banco Múltiplo",
    },
    {
      value: 22,
      country_code: "BR",
      label: "BPN Brasil Banco Múltiplo S.A.",
    },
    {
      value: 23,
      country_code: "BR",
      label: "BRB – Banco de Brasília S.A.",
    },
    {
      value: 26,
      country_code: "BR",
      label: "Banco J. Safra S.A.",
    },
    {
      value: 61,
      country_code: "BR",
      label: "Banco Mercantil do Brasil S.A.",
    },
    {
      value: 63,
      country_code: "BR",
      label: "HSBC Bank Brasil S.A. – Banco Múltiplo",
    },
    {
      value: 84,
      country_code: "BR",
      label: "Banco Rendimento S.A.",
    },
    {
      value: 85,
      country_code: "BR",
      label: "Banco Triângulo S.A.",
    },
    {
      value: 88,
      country_code: "BR",
      label: "Banco Alvorada S.A.",
    },
    {
      value: 89,
      country_code: "BR",
      label: "Banco Pine S.A.",
    },
    {
      value: 93,
      country_code: "BR",
      label: "Banco Daycoval S.A.",
    },
    {
      value: 97,
      country_code: "BR",
      label: "Banco Citibank S.A.",
    },
    {
      value: 100,
      country_code: "BR",
      label: "Banco Cooperativo Sicredi S.A.",
    },
    {
      value: 105,
      country_code: "BR",
      label: "Banco Cooperativo do Brasil S.A. – BANCOOB",
    },
    {
      value: 467,
      country_code: "BR",
      label: "BANCO INTERMEDIUM S.A.",
    },
    {
      value: 469,
      country_code: "BR",
      label: "CC UNICRED NORTE DO PARANÁ",
    },
    {
      value: 471,
      country_code: "BR",
      label: "BANCO ARBI S.A.",
    },
    {
      value: 472,
      country_code: "BR",
      label: "BANCO BONSUCESSO S.A.",
    },
    {
      value: 478,
      country_code: "BR",
      label: "SUDAMERIS BRASIL S/A",
    },
    {
      value: 479,
      country_code: "BR",
      label: "BANCO CAPITAL S. A.",
    },
    {
      value: 481,
      country_code: "BR",
      label: "BANCO DE LA PROVINCIA DE BUENOS AIRES",
    },
    {
      value: 490,
      country_code: "BR",
      label: "BANCO SEMEAR S.A.",
    },
    {
      value: 569,
      country_code: "BR",
      label: "Banco Agibank S.A.",
    },
    {
      value: 601,
      country_code: "BR",
      label:
        "Cooperativa Central de Crédito Noroeste Brasileiro Ltda. (CentralCredi)",
    },
    {
      value: 602,
      country_code: "BR",
      label: "Cooperativa Central de Crédito Urbano – Cecred",
    },
    {
      value: 604,
      country_code: "BR",
      label: "Cooperativa de Crédito Rural da Região da Mogiana",
    },
    {
      value: 633,
      country_code: "BR",
      label:
        "Pólocred Sociedade de Crédito ao Microempreendedor e Empresa de Pequeno Porte",
    },
  ],
  CO: [
    {
      value: 118,
      country_code: "CO",
      label: "DAVIVIENDA",
    },
    {
      value: 119,
      country_code: "CO",
      label: "BANCOLOMBIA",
    },
    {
      value: 120,
      country_code: "CO",
      label: "AV VILLAS",
    },
    {
      value: 121,
      country_code: "CO",
      label: "DE BOGOTA",
    },
    {
      value: 122,
      country_code: "CO",
      label: "DE OCCIDENTE",
    },
    {
      value: 123,
      country_code: "CO",
      label: "POPULAR",
    },
    {
      value: 124,
      country_code: "CO",
      label: "BBVA",
    },
    {
      value: 125,
      country_code: "CO",
      label: "CITIBANK",
    },
    {
      value: 126,
      country_code: "CO",
      label: "HELM BANK S.A",
    },
    {
      value: 127,
      country_code: "CO",
      label: "CAJA SOCIAL BCSC",
    },
    {
      value: 128,
      country_code: "CO",
      label: "COLPATRIA",
    },
    {
      value: 129,
      country_code: "CO",
      label: "GNB SUDAMERIS",
    },
    {
      value: 130,
      country_code: "CO",
      label: "FALABELLA S.A.",
    },
    {
      value: 131,
      country_code: "CO",
      label: "FINANDINA S.A.",
    },
    {
      value: 132,
      country_code: "CO",
      label: "AGRARIO",
    },
    {
      value: 133,
      country_code: "CO",
      label: "CORPBANCA COLOMBIA S.A.",
    },
    {
      value: 134,
      country_code: "CO",
      label: "GNB COLOMBIA S.A.",
    },
    {
      value: 135,
      country_code: "CO",
      label: "PICHINCHA",
    },
    {
      value: 136,
      country_code: "CO",
      label: "PROCREDIT COLOMBIA",
    },
    {
      value: 137,
      country_code: "CO",
      label: "SANTANDER DE NEGOCIOS COLOMBIA S.A",
    },
    {
      value: 500,
      country_code: "CO",
      label: "JP MORGAN",
    },
    {
      value: 501,
      country_code: "CO",
      label: "BNP PARIBAS COLOMBIA",
    },
    {
      value: 502,
      country_code: "CO",
      label: "BANCO MUNDO MUJER S.A.",
    },
    {
      value: 503,
      country_code: "CO",
      label: "BANCO W S.A.",
    },
    {
      value: 504,
      country_code: "CO",
      label: "BANCO DE LAS MICROFINANZAS",
    },
    {
      value: 505,
      country_code: "CO",
      label: "BANCO COOMEVA S.A.",
    },
    {
      value: 506,
      country_code: "CO",
      label: "BANCO MULTIBANK S.A.",
    },
    {
      value: 507,
      country_code: "CO",
      label: "BANCO COOPERATIVO COOPCENTRAL",
    },
    {
      value: 508,
      country_code: "CO",
      label: "BANCO COMPARTIR S.A.",
    },
    {
      value: 509,
      country_code: "CO",
      label: "BANCO SERFINANZA S.A.",
    },
    {
      value: 510,
      country_code: "CO",
      label: "FINANCIERA JURISCOOP S.A. CF",
    },
    {
      value: 511,
      country_code: "CO",
      label: "COOP. FIN. ANTIOQUIA",
    },
    {
      value: 512,
      country_code: "CO",
      label: "COTRAFA COOPERATIVA FINANCIERA",
    },
    {
      value: 513,
      country_code: "CO",
      label: "CONFIAR",
    },
    {
      value: 514,
      country_code: "CO",
      label: "COLTEFINANCIERA SA",
    },
    {
      value: 515,
      country_code: "CO",
      label: "BANCA DIGITAL NEQUI S.A",
    },
    {
      value: 516,
      country_code: "CO",
      label: "DAVIPLATA",
    },
  ],
  MX: [
    {
      value: 138,
      country_code: "MX",
      label: "BANAMEX - BANCO NACIONAL DE MÉXICO SA",
    },
    {
      value: 139,
      country_code: "MX",
      label: "BANCOMEXT - BANCO NACIONAL DE COMERCIO EXTERIOR",
    },
    {
      value: 140,
      country_code: "MX",
      label: "BANOBRAS - BANCO NACIONAL DE OBRAS Y SERVICIOS PÚBLICOS",
    },
    {
      value: 141,
      country_code: "MX",
      label: "BBVA BANCOMER - BBVA BANCOMER SA",
    },
    {
      value: 142,
      country_code: "MX",
      label: "SANTANDER - BANCO SANTANDER (MÉXICO) SA",
    },
    {
      value: 143,
      country_code: "MX",
      label: "BANJERCITO - BANCO NACIONAL DEL EJÉRCITO",
    },
    {
      value: 144,
      country_code: "MX",
      label: "HSBC - HSBC MÉXICO SA",
    },
    {
      value: 145,
      country_code: "MX",
      label: "BAJIO - BANCO DEL BAJÍO SA",
    },
    {
      value: 146,
      country_code: "MX",
      label: "IXE - IXE BANCO SA",
    },
    {
      value: 147,
      country_code: "MX",
      label: "INBURSA - BANCO INBURSA SA",
    },
    {
      value: 148,
      country_code: "MX",
      label: "INTERACCIONES - BANCO INTERACCIONES SA",
    },
    {
      value: 149,
      country_code: "MX",
      label: "MIFEL - BANCA MIFEL SA",
    },
    {
      value: 150,
      country_code: "MX",
      label: "SCOTIABANK - SCOTIABANK INVERLAT SA",
    },
    {
      value: 151,
      country_code: "MX",
      label: "BANREGIO - BANCO REGIONAL DE MONTERREY SA",
    },
    {
      value: 152,
      country_code: "MX",
      label: "INVEX - BANCO INVEX SA",
    },
    {
      value: 153,
      country_code: "MX",
      label: "BANSI - BANSI SA",
    },
    {
      value: 154,
      country_code: "MX",
      label: "AFIRME - BANCA AFIRME SA",
    },
    {
      value: 155,
      country_code: "MX",
      label: "BANORTE - BANCO MERCANTIL DEL NORTE SA",
    },
    {
      value: 156,
      country_code: "MX",
      label: "THE ROYAL BANK - THE ROYAL BANK OF SCOTLAND MÉXICO SA",
    },
    {
      value: 157,
      country_code: "MX",
      label: "AMERICAN EXPRESS - AMERICAN EXPRESS BANK (MÉXICO) SA",
    },
    {
      value: 158,
      country_code: "MX",
      label: "BAMSA - BANK OF AMERICA MÉXICO SA",
    },
    {
      value: 159,
      country_code: "MX",
      label: "TOKYO - BANK OF TOKYO",
    },
    {
      value: 160,
      country_code: "MX",
      label: "JP MORGAN - BANCO JP MORGAN SA",
    },
    {
      value: 161,
      country_code: "MX",
      label: "BMONEX - BANCO MONEX SA",
    },
    {
      value: 162,
      country_code: "MX",
      label: "VE POR MAS - BANCO VE POR MAS SA",
    },
    {
      value: 163,
      country_code: "MX",
      label: "ING - ING BANK (MÉXICO) SA",
    },
    {
      value: 164,
      country_code: "MX",
      label: "DEUTSCHE - DEUTSCHE BANK MÉXICO SA",
    },
    {
      value: 165,
      country_code: "MX",
      label: "CREDIT SUISSE - BANCO CREDIT SUISSE (MÉXICO) SA",
    },
    {
      value: 166,
      country_code: "MX",
      label: "AZTECA - BANCO AZTECA SA",
    },
    {
      value: 167,
      country_code: "MX",
      label: "AUTOFIN - BANCO AUTOFIN MÉXICO SA",
    },
    {
      value: 168,
      country_code: "MX",
      label: "BARCLAYS - BARCLAYS BANK MÉXICO SA",
    },
    {
      value: 169,
      country_code: "MX",
      label: "COMPARTAMOS - BANCO COMPARTAMOS SA",
    },
    {
      value: 170,
      country_code: "MX",
      label: "BANCO FAMSA - BANCO AHORRO FAMSA SA",
    },
    {
      value: 171,
      country_code: "MX",
      label: "BMULTIVA - BANCO MULTIVA SA",
    },
    {
      value: 172,
      country_code: "MX",
      label: "ACTINVER - BANCO ACTINVER SA",
    },
    {
      value: 173,
      country_code: "MX",
      label: "WAL - MART",
    },
    {
      value: 174,
      country_code: "MX",
      label: "NAFIN - NACIONAL FINANCIERA",
    },
    {
      value: 175,
      country_code: "MX",
      label: "INTERBANCO - INTER BANCO SA",
    },
    {
      value: 176,
      country_code: "MX",
      label: "BANCOPPEL - BANCOPPEL SA",
    },
    {
      value: 177,
      country_code: "MX",
      label: "ABC CAPITAL - ABC CAPITAL SA",
    },
    {
      value: 178,
      country_code: "MX",
      label: "UBS BANK - UBS BANK MÉXICO SA",
    },
    {
      value: 179,
      country_code: "MX",
      label: "CONSUBANCO - CONSUBANCO SA",
    },
    {
      value: 180,
      country_code: "MX",
      label: "VOLKSWAGEN - VOLKSWAGEN BANK SA",
    },
    {
      value: 181,
      country_code: "MX",
      label: "CIBANCO - CIBANCO SA",
    },
    {
      value: 182,
      country_code: "MX",
      label: "BBASE - BANCO BASE SA",
    },
    {
      value: 183,
      country_code: "MX",
      label: "BANSEFI - BANCO DEL AHORRO NACIONAL Y SERVICIOS FINANCIEROS",
    },
    {
      value: 184,
      country_code: "MX",
      label: "HIPOTECARIA FEDERAL - SOCIEDAD HIPOTECARIA FEDERAL",
    },
    {
      value: 185,
      country_code: "MX",
      label: "MONEXCB - MONEX CASA DE BOLSA SA DE CV",
    },
    {
      value: 186,
      country_code: "MX",
      label: "GBM - GBM GRUPO BURSÁTIL MEXICANO SA DE CV",
    },
    {
      value: 187,
      country_code: "MX",
      label: "MASARI - MASARI CASA DE BOLSA SA",
    },
    {
      value: 188,
      country_code: "MX",
      label: "VALUE - VALUE SA DE CV",
    },
    {
      value: 189,
      country_code: "MX",
      label:
        "ESTRUCTURADORES - ESTRUCTURADORES DEL MERCADO DE VALORES CASA DE BOLSA SA DE CV",
    },
    {
      value: 190,
      country_code: "MX",
      label: "TIBER - CASA DE CAMBIO TIBER SA DE CV",
    },
    {
      value: 191,
      country_code: "MX",
      label: "VECTOR - VECTOR CASA DE BOLSA SA DE CV",
    },
    {
      value: 192,
      country_code: "MX",
      label: "B&B - B Y B CASA DE CAMBIO SA DE CV",
    },
    {
      value: 193,
      country_code: "MX",
      label: "ACCIVAL - ACCIONES Y VALORES BANAMEX SA DE CV",
    },
    {
      value: 194,
      country_code: "MX",
      label: "MERRILL LYNCH - MERRILL LYNCH MÉXICO SA DE CV",
    },
    {
      value: 195,
      country_code: "MX",
      label: "FINAMEX - CASA DE BOLSA FINAMEX SA DE CV",
    },
    {
      value: 196,
      country_code: "MX",
      label: "VALMEX - VALORES MEXICANOS CASA DE BOLSA SA DE CV",
    },
    {
      value: 197,
      country_code: "MX",
      label: "UNICA - UNICA CASA DE CAMBIO SA DE CV",
    },
    {
      value: 198,
      country_code: "MX",
      label: "MAPFRE - MAPFRE TEPEYAC SA",
    },
    {
      value: 199,
      country_code: "MX",
      label: "PROFUTURO - PROFUTURO GNP SA DE CV",
    },
    {
      value: 200,
      country_code: "MX",
      label: "CB ACTINVER - ACTINVER CASA DE BOLSA SA DE CV",
    },
    {
      value: 201,
      country_code: "MX",
      label: "OACTIN - OPERADORA ACTINVER SA DE CV",
    },
    {
      value: 202,
      country_code: "MX",
      label: "SKANDIA - SKANDIA VIDA SA DE CV",
    },
    {
      value: 203,
      country_code: "MX",
      label: "CBDEUTSCHE - DEUTSCHE SECURITIES SA DE CV",
    },
    {
      value: 204,
      country_code: "MX",
      label: "ZURICH - ZURICH COMPAÑÍA DE SEGUROS SA",
    },
    {
      value: 205,
      country_code: "MX",
      label: "ZURICHVI - ZURICH VIDA COMPAÑÍA DE SEGUROS SA",
    },
    {
      value: 206,
      country_code: "MX",
      label: "SU CASITA - HIPOTECARIA SU CASITA SA DE CV SOFOM ENR",
    },
    {
      value: 207,
      country_code: "MX",
      label: "CB INTERCAM - INTERCAM CASA DE BOLSA SA DE CV",
    },
    {
      value: 208,
      country_code: "MX",
      label: "CI BOLSA - CI CASA DE BOLSA SA DE CV",
    },
    {
      value: 209,
      country_code: "MX",
      label: "BULLTICK CB - BULLTICK CASA DE BOLSA SA DE CV",
    },
    {
      value: 210,
      country_code: "MX",
      label: "STERLING - STERLING CASA DE CAMBIO SA DE CV",
    },
    {
      value: 211,
      country_code: "MX",
      label: "FINCOMUN - FINCOMÚN SERVICIOS FINANCIEROS COMUNITARIOS SA DE CV",
    },
    {
      value: 212,
      country_code: "MX",
      label: "HDI SEGUROS - HDI SEGUROS SA DE CV",
    },
    {
      value: 213,
      country_code: "MX",
      label: "ORDER - ORDER EXPRESS CASA DE CAMBIO SA DE CV",
    },
    {
      value: 214,
      country_code: "MX",
      label: "AKALA - AKALA SA DE CV",
    },
    {
      value: 215,
      country_code: "MX",
      label: "CB JPMORGAN - JP MORGAN CASA DE BOLSA SA DE CV",
    },
    {
      value: 216,
      country_code: "MX",
      label: "REFORMA - OPERADORA DE RECURSOS REFORMA SA DE CV",
    },
    {
      value: 217,
      country_code: "MX",
      label: "STP - SISTEMA DE TRANSFERENCIAS Y PAGOS STP SA DE CV SOFOM ENR",
    },
    {
      value: 218,
      country_code: "MX",
      label: "TELECOMM - TELECOMUNICACIONES DE MÉXICO",
    },
    {
      value: 219,
      country_code: "MX",
      label: "EVERCORE - EVERCORE CASA DE BOLSA SA DE CV",
    },
    {
      value: 220,
      country_code: "MX",
      label: "SKANDIA - SKANDIA OPERADORA DE FONDOS SA DE CV",
    },
    {
      value: 221,
      country_code: "MX",
      label: "SEGMTY - SEGUROS MONTERREY NEW YORK LIFE SA DE CV",
    },
    {
      value: 222,
      country_code: "MX",
      label: "ASEA - SOLUCIÓN ASEA SA DE CV",
    },
    {
      value: 223,
      country_code: "MX",
      label: "KUSPIT - KUSPIT CASA DE BOLSA SA DE CV",
    },
    {
      value: 224,
      country_code: "MX",
      label: "SOFIEXPRESS - JP SOFIEXPRESS SA DE CV",
    },
    {
      value: 225,
      country_code: "MX",
      label: "UNAGRA - UNAGRA SA DE CV",
    },
    {
      value: 226,
      country_code: "MX",
      label:
        "OPCIONES EMPRESARIALES DEL NOROESTE - OPCIONES EMPRESARIALES DEL NORESTE SA DE CV",
    },
    {
      value: 227,
      country_code: "MX",
      label: "CLS - CLS BANK INTERNATIONAL",
    },
    {
      value: 228,
      country_code: "MX",
      label: "INDEVAL - SD INDEVAL SA DE CV",
    },
    {
      value: 229,
      country_code: "MX",
      label: "LIBERTAD - LIBERTAD SERVICIOS FINANCIEROS SA DE CV",
    },
    {
      value: 687,
      country_code: "MX",
      label: "SABADELL",
    },
  ],
  AR: [
    {
      value: 230,
      country_code: "AR",
      label: "BANCO COMAFI SOCIEDAD ANONIMA",
    },
    {
      value: 231,
      country_code: "AR",
      label: "BANCO CREDICOOP COOPERATIVO LIMITADO",
    },
    {
      value: 232,
      country_code: "AR",
      label: "BANCO DE GALICIA Y BUENOS AIRES S.A.",
    },
    {
      value: 233,
      country_code: "AR",
      label: "BANCO DE LA CIUDAD DE BUENOS AIRES",
    },
    {
      value: 234,
      country_code: "AR",
      label: "BANCO DE LA NACION ARGENTINA",
    },
    {
      value: 235,
      country_code: "AR",
      label: "BANCO DE LA PAMPA SOCIEDAD DE ECONOMÍA M",
    },
    {
      value: 236,
      country_code: "AR",
      label: "BANCO DE LA PROVINCIA DE BUENOS AIRES",
    },
    {
      value: 237,
      country_code: "AR",
      label: "BANCO DE LA PROVINCIA DE Córdoba S.A.",
    },
    {
      value: 238,
      country_code: "AR",
      label: "Banco de Santa Fe",
    },
    {
      value: 239,
      country_code: "AR",
      label: "BANCO DE SERVICIOS Y TRANSACCIONES S.A.",
    },
    {
      value: 240,
      country_code: "AR",
      label: "BANCO HIPOTECARIO S.A.",
    },
    {
      value: 241,
      country_code: "AR",
      label: "BANCO ICBC",
    },
    {
      value: 242,
      country_code: "AR",
      label: "BANCO INDUSTRIAL",
    },
    {
      value: 243,
      country_code: "AR",
      label: "BANCO ITAU ARGENTINA S.A.",
    },
    {
      value: 244,
      country_code: "AR",
      label: "BANCO MACRO S.A.",
    },
    {
      value: 245,
      country_code: "AR",
      label: "BANCO MUNICIPAL DE ROSARIO",
    },
    {
      value: 246,
      country_code: "AR",
      label: "BANCO PATAGONIA S.A.",
    },
    {
      value: 247,
      country_code: "AR",
      label: "Banco Piano",
    },
    {
      value: 248,
      country_code: "AR",
      label: "BANCO PROVINCIA NEUQUEN",
    },
    {
      value: 249,
      country_code: "AR",
      label: "BANCO SANTANDER RIO S.A.",
    },
    {
      value: 250,
      country_code: "AR",
      label: "Banco Santiago del Estero",
    },
    {
      value: 251,
      country_code: "AR",
      label: "BANCO SUPERVIELLE S.A.",
    },
    {
      value: 252,
      country_code: "AR",
      label: "BBVA BANCO FRANCES S.A.",
    },
    {
      value: 253,
      country_code: "AR",
      label: "HSBC BANK ARGENTINA S.A.",
    },
    {
      value: 517,
      country_code: "AR",
      label: "ABN AMRO BANK N.V.",
    },
    {
      value: 518,
      country_code: "AR",
      label: "LLOYDS BANK (BLSA) L",
    },
    {
      value: 519,
      country_code: "AR",
      label: "CITIBANK N.A.",
    },
    {
      value: 520,
      country_code: "AR",
      label: "THE BANK OF TOKYO- M",
    },
    {
      value: 521,
      country_code: "AR",
      label: "BANCO DE SAN JUAN S.",
    },
    {
      value: 522,
      country_code: "AR",
      label: "BANCO DO BRASIL S.A.",
    },
    {
      value: 523,
      country_code: "AR",
      label: "BANCO DEL TUCUMAN S.",
    },
    {
      value: 524,
      country_code: "AR",
      label: "BANCO BANSUD S.A.",
    },
    {
      value: 525,
      country_code: "AR",
      label: "BANCO REGIONAL DE CU",
    },
    {
      value: 526,
      country_code: "AR",
      label: "BANCO DEL CHUBUT S.A",
    },
    {
      value: 527,
      country_code: "AR",
      label: "BCO DE SANTA CRUZ SA",
    },
    {
      value: 528,
      country_code: "AR",
      label: "BANCO DE CORRIENTES",
    },
    {
      value: 529,
      country_code: "AR",
      label: "BCO B.I. CREDITANSTA",
    },
    {
      value: 530,
      country_code: "AR",
      label: "BANCO DE VALORES S.A",
    },
    {
      value: 531,
      country_code: "AR",
      label: "BANCO ROELA S.A.",
    },
    {
      value: 532,
      country_code: "AR",
      label: "BANCO MARIVA S.A.",
    },
    {
      value: 533,
      country_code: "AR",
      label: "NUEVO BANCO SUQUIA",
    },
    {
      value: 534,
      country_code: "AR",
      label: "BCA NAZIONALE DEL LA",
    },
    {
      value: 535,
      country_code: "AR",
      label: "BNP PARIBAS",
    },
    {
      value: 536,
      country_code: "AR",
      label: "BCO PCIA T DEL FUEGO",
    },
    {
      value: 537,
      country_code: "AR",
      label: "BCO DE LA REP.ORIENT",
    },
    {
      value: 538,
      country_code: "AR",
      label: "BANCO SAENZ S.A.",
    },
    {
      value: 539,
      country_code: "AR",
      label: "BANCO MERIDIAN SA",
    },
    {
      value: 540,
      country_code: "AR",
      label: "AMERICAN EXPRESS BAN",
    },
    {
      value: 541,
      country_code: "AR",
      label: "BANCO BANEX SA",
    },
    {
      value: 542,
      country_code: "AR",
      label: "BANCO FINANSUR S.A.",
    },
    {
      value: 543,
      country_code: "AR",
      label: "BANCO JULIO S.A.",
    },
    {
      value: 544,
      country_code: "AR",
      label: "BCO PRIVADO DE INVER",
    },
    {
      value: 545,
      country_code: "AR",
      label: "NUEVO BANCO DE LA RI",
    },
    {
      value: 546,
      country_code: "AR",
      label: "BANCO DEL SOL S.A.",
    },
    {
      value: 547,
      country_code: "AR",
      label: "NUEVO BANCO DEL CHAC",
    },
    {
      value: 548,
      country_code: "AR",
      label: "M.B.A. BCO DE INVERS",
    },
    {
      value: 549,
      country_code: "AR",
      label: "BANCO DE FORMOSA S.A",
    },
    {
      value: 550,
      country_code: "AR",
      label: "BANCO CMF SA",
    },
    {
      value: 551,
      country_code: "AR",
      label: "DEUTSCHE BANK SA",
    },
    {
      value: 552,
      country_code: "AR",
      label: "BANCO BRADESCO ARG",
    },
    {
      value: 553,
      country_code: "AR",
      label: "NVO BCO ENTRE RIOS",
    },
    {
      value: 554,
      country_code: "AR",
      label: "NVO BCO SUQUIA SA",
    },
    {
      value: 555,
      country_code: "AR",
      label: "NVO BCO BISEL SA",
    },
    {
      value: 556,
      country_code: "AR",
      label: "BANCO COLOMBIA",
    },
    {
      value: 557,
      country_code: "AR",
      label: "FORD CREDIT CIA FIN",
    },
    {
      value: 558,
      country_code: "AR",
      label: "TUTELAR COMPANIA FIN",
    },
    {
      value: 559,
      country_code: "AR",
      label: "CIA FINANC ARG SA",
    },
    {
      value: 560,
      country_code: "AR",
      label: "MONTEMAR CIA FINANC",
    },
    {
      value: 561,
      country_code: "AR",
      label: "MASVENTAS SA CIA FIN",
    },
    {
      value: 562,
      country_code: "AR",
      label: "MULTIFINANZAS SA",
    },
    {
      value: 563,
      country_code: "AR",
      label: "CAJA DE CRED CUENCA",
    },
    {
      value: 564,
      country_code: "AR",
      label: "GE CIA FINANCIERA SA",
    },
    {
      value: 565,
      country_code: "AR",
      label: "CREDILOGROS CIA FIN",
    },
  ],
  CL: [
    {
      value: 254,
      country_code: "CL",
      label: "BANCO DE CHILE / A. EDWARDS / CITIBANK N.A.",
    },
    {
      value: 255,
      country_code: "CL",
      label: "BANCO INTERNACIONAL",
    },
    {
      value: 256,
      country_code: "CL",
      label: "DRESDNER BANK LETEINAMERIKA",
    },
    {
      value: 257,
      country_code: "CL",
      label: "BANCO DEL ESTADO DE CHILE",
    },
    {
      value: 258,
      country_code: "CL",
      label: "SCOTIABANK",
    },
    {
      value: 259,
      country_code: "CL",
      label: "BANCO CRÉDITO E INVERSIONES",
    },
    {
      value: 260,
      country_code: "CL",
      label: "BANCO DO BRASIL S.A.",
    },
    {
      value: 261,
      country_code: "CL",
      label: "CORPBANCA",
    },
    {
      value: 262,
      country_code: "CL",
      label: "BANCO BICE",
    },
    {
      value: 263,
      country_code: "CL",
      label: "HSBC BANK CHILE",
    },
    {
      value: 264,
      country_code: "CL",
      label: "BANCO SANTANDER - SANTIAGO",
    },
    {
      value: 265,
      country_code: "CL",
      label: "BANCO ITAÚ",
    },
    {
      value: 266,
      country_code: "CL",
      label: "JP MORGAN CHASE BANK",
    },
    {
      value: 267,
      country_code: "CL",
      label: "BANCO DE LA NACIÓN ARGENTINA",
    },
    {
      value: 269,
      country_code: "CL",
      label: "ABN AMRO BANK (CHILE)",
    },
    {
      value: 270,
      country_code: "CL",
      label: "BANCO SECURITY",
    },
    {
      value: 271,
      country_code: "CL",
      label: "BANCO FALABELLA",
    },
    {
      value: 272,
      country_code: "CL",
      label: "DEUTSCHE BANK (CHILE)",
    },
    {
      value: 273,
      country_code: "CL",
      label: "BANCO RIPLEY",
    },
    {
      value: 274,
      country_code: "CL",
      label: "HNS BANCO",
    },
    {
      value: 275,
      country_code: "CL",
      label: "BANCO MONEX",
    },
    {
      value: 276,
      country_code: "CL",
      label: "BBVA BANCO BHIF",
    },
    {
      value: 277,
      country_code: "CL",
      label: "BANCO DEL DESARROLLO",
    },
    {
      value: 278,
      country_code: "CL",
      label: "BANCO CONOSUR",
    },
    {
      value: 279,
      country_code: "CL",
      label: "CITIBANK- CITIUYMMXXX",
    },
    {
      value: 494,
      country_code: "CL",
      label: "BCO CENTRAL DE CHILE",
    },
    {
      value: 495,
      country_code: "CL",
      label: "EDWARDS",
    },
    {
      value: 496,
      country_code: "CL",
      label: "CITIBANK N.A.",
    },
    {
      value: 497,
      country_code: "CL",
      label: "BANCO SANTIAGO",
    },
    {
      value: 498,
      country_code: "CL",
      label: "BANCO PENTA",
    },
    {
      value: 499,
      country_code: "CL",
      label: "BANCO PARIS",
    },
  ],
  UY: [
    {
      value: 280,
      country_code: "UY",
      label: "BBVA URUGUAY",
    },
    {
      value: 281,
      country_code: "UY",
      label: "BANCO DE LA REPUBLICA ORIENTAL DEL URUGUAY BROU",
    },
    {
      value: 282,
      country_code: "UY",
      label: "ITAU",
    },
    {
      value: 283,
      country_code: "UY",
      label: "HSBC BANK URUGUAY SA",
    },
    {
      value: 284,
      country_code: "UY",
      label: "SANTANDER URUGUAY",
    },
    {
      value: 288,
      country_code: "UY",
      label: "BANCO HIPOTECARIO",
    },
    {
      value: 290,
      country_code: "UY",
      label: "SCOTIABANK",
    },
    {
      value: 292,
      country_code: "UY",
      label: "HERITAGE",
    },
    {
      value: 294,
      country_code: "UY",
      label: "BANCO NACION ARGENTINA",
    },
    {
      value: 679,
      country_code: "UY",
      label: "BANCO DE SEGUROS DEL ESTADO",
    },
    {
      value: 680,
      country_code: "UY",
      label: "CITIBANK NEW YORK",
    },
    {
      value: 681,
      country_code: "UY",
      label: "DE LA PROV DE BS AS",
    },
    {
      value: 682,
      country_code: "UY",
      label: "NUMMI S.A.(MI DINERO)",
    },
    {
      value: 683,
      country_code: "UY",
      label: "DINERO ELECTRONICO ANDA",
    },
    {
      value: 684,
      country_code: "UY",
      label: "ECONSTAR S.A. (PREX)",
    },
    {
      value: 685,
      country_code: "UY",
      label: "ANDA",
    },
  ],
  PE: [
    {
      value: 295,
      country_code: "PE",
      label: "Banco de Crédito del Perú",
    },
    {
      value: 296,
      country_code: "PE",
      label: "Interbank",
    },
    {
      value: 297,
      country_code: "PE",
      label: "Citibank del Perú S.A.",
    },
    {
      value: 298,
      country_code: "PE",
      label: "Scotiabank Perú S.A.A.",
    },
    {
      value: 299,
      country_code: "PE",
      label: "Banco Continental",
    },
    {
      value: 300,
      country_code: "PE",
      label: "Banco de la Nación",
    },
    {
      value: 301,
      country_code: "PE",
      label: "Banco de Comercio",
    },
    {
      value: 302,
      country_code: "PE",
      label: "Banco Financiero del Perú",
    },
    {
      value: 303,
      country_code: "PE",
      label: "Banco Interamericano de Finanzas (BIF)",
    },
    {
      value: 304,
      country_code: "PE",
      label: "Banco GNB Perú S.A.",
    },
    {
      value: 305,
      country_code: "PE",
      label: "Caja Municipal de Ahorro y Crédito Trujillo",
    },
    {
      value: 306,
      country_code: "PE",
      label: "Caja Municipal de Ahorro y Crédito Arequipa",
    },
    {
      value: 307,
      country_code: "PE",
      label: "Caja Municipal de Ahorro y Crédito Sullana",
    },
    {
      value: 308,
      country_code: "PE",
      label: "Caja Municipal de Ahorro y Crédito Huancayo",
    },
    {
      value: 648,
      country_code: "PE",
      label: "BANCO CENTRAL DE RES",
    },
    {
      value: 649,
      country_code: "PE",
      label: "SCOTIABANK PERU SAA",
    },
    {
      value: 650,
      country_code: "PE",
      label: "BANCO DEL TRABAJO SO",
    },
    {
      value: 651,
      country_code: "PE",
      label: "MI BANCO",
    },
    {
      value: 652,
      country_code: "PE",
      label: "HSBC BANK PERU S.A.",
    },
    {
      value: 653,
      country_code: "PE",
      label: "BANCO FALABELLA",
    },
    {
      value: 654,
      country_code: "PE",
      label: "BANCO RIPLEY S.A.",
    },
    {
      value: 655,
      country_code: "PE",
      label: "SANTANDER PERU S.A.",
    },
    {
      value: 656,
      country_code: "PE",
      label: "BANCO AZTECA DEL PERU S.A.",
    },
    {
      value: 657,
      country_code: "PE",
      label: "TRANSFERENCIAS",
    },
    {
      value: 658,
      country_code: "PE",
      label: "PAGO DE HABERES",
    },
    {
      value: 659,
      country_code: "PE",
      label: "PAGO DE PROVEED",
    },
    {
      value: 660,
      country_code: "PE",
      label: "PAGO CTS CCE",
    },
    {
      value: 661,
      country_code: "PE",
      label: "BCO CENTRAL DE RESER",
    },
    {
      value: 662,
      country_code: "PE",
      label: "BANCO DE CREDITO DOL",
    },
    {
      value: 663,
      country_code: "PE",
      label: "INTERBANC DOLARES",
    },
    {
      value: 664,
      country_code: "PE",
      label: "CITIBANK DOLARES",
    },
    {
      value: 665,
      country_code: "PE",
      label: "SCOTIABANK PERU SAA",
    },
    {
      value: 666,
      country_code: "PE",
      label: "BANCO CONTINENTAL DO",
    },
    {
      value: 667,
      country_code: "PE",
      label: "BANCO DE LA NACION D",
    },
    {
      value: 668,
      country_code: "PE",
      label: "BANCO DE COMERCIO DO",
    },
    {
      value: 669,
      country_code: "PE",
      label: "BANCO FINANCIERO DOL",
    },
    {
      value: 670,
      country_code: "PE",
      label: "BCO INTERAMERICANO B",
    },
    {
      value: 671,
      country_code: "PE",
      label: "SCOTIABANK PERU SAA",
    },
    {
      value: 672,
      country_code: "PE",
      label: "BANCO DEL TRABAJO DO",
    },
    {
      value: 673,
      country_code: "PE",
      label: "BANCO SERBANCO DOLAR",
    },
    {
      value: 674,
      country_code: "PE",
      label: "SANTANDER PERU S.A.",
    },
    {
      value: 675,
      country_code: "PE",
      label: "CMAC METROPOLITANA",
    },
    {
      value: 676,
      country_code: "PE",
      label: "CAJA MUNICIPAL PIURA",
    },
    {
      value: 677,
      country_code: "PE",
      label: "CMAC CUSCO",
    },
    {
      value: 678,
      country_code: "PE",
      label: "SERVICIOS",
    },
  ],
  CR: [
    {
      value: 389,
      country_code: "CR",
      label: "Banco Central",
    },
    {
      value: 390,
      country_code: "CR",
      label: "Bac San Jose",
    },
    {
      value: 391,
      country_code: "CR",
      label: "HSBC",
    },
    {
      value: 392,
      country_code: "CR",
      label: "BCT",
    },
    {
      value: 393,
      country_code: "CR",
      label: "Lafise",
    },
    {
      value: 394,
      country_code: "CR",
      label: "CMB (Costa Rica) S.A.",
    },
    {
      value: 395,
      country_code: "CR",
      label: "Promerica",
    },
    {
      value: 396,
      country_code: "CR",
      label: "Citibank de Costa Rica S.A.",
    },
    {
      value: 397,
      country_code: "CR",
      label: "Improsa",
    },
    {
      value: 398,
      country_code: "CR",
      label: "Scotiabank",
    },
    {
      value: 399,
      country_code: "CR",
      label: "Cathay",
    },
    {
      value: 400,
      country_code: "CR",
      label: "Banco General de Costa Rica S.A.",
    },
    {
      value: 401,
      country_code: "CR",
      label: "Citibank de Costa Rica S.A.",
    },
    {
      value: 402,
      country_code: "CR",
      label: "Banco Nacional de C.R.",
    },
    {
      value: 403,
      country_code: "CR",
      label: "Banco de Costa Rica",
    },
    {
      value: 404,
      country_code: "CR",
      label: "Banco Credito Agricola de Cartago",
    },
    {
      value: 405,
      country_code: "CR",
      label: "Banco Popular de Desarrollo Comunal",
    },
    {
      value: 406,
      country_code: "CR",
      label: "Banco Hipotecario de la Vivienda",
    },
    {
      value: 407,
      country_code: "CR",
      label: "Financiera Cafsa",
    },
    {
      value: 408,
      country_code: "CR",
      label: "Financiera G&T Continental Costa Rica S.A.",
    },
    {
      value: 409,
      country_code: "CR",
      label: "Financiera Acobo",
    },
    {
      value: 410,
      country_code: "CR",
      label: "Financiera Multivalores",
    },
    {
      value: 411,
      country_code: "CR",
      label: "Financiera Comeca",
    },
    {
      value: 412,
      country_code: "CR",
      label: "Financiera Desyfin",
    },
    {
      value: 413,
      country_code: "CR",
      label: "Interbolsa Casa de Cambio   ",
    },
    {
      value: 414,
      country_code: "CR",
      label: "Citi Tarjetas de Costa Rica S.A.",
    },
    {
      value: 415,
      country_code: "CR",
      label: "ATH",
    },
    {
      value: 416,
      country_code: "CR",
      label: "Bolsa Nacional de Valores",
    },
    {
      value: 417,
      country_code: "CR",
      label: "Central American Money Market Brokers",
    },
    {
      value: 418,
      country_code: "CR",
      label: "Invers. Sama",
    },
    {
      value: 419,
      country_code: "CR",
      label: "Mercado Valores",
    },
    {
      value: 420,
      country_code: "CR",
      label: "BCT Valores",
    },
    {
      value: 421,
      country_code: "CR",
      label: "Citi Valores Accival S.A.",
    },
    {
      value: 422,
      country_code: "CR",
      label: "ACOBO",
    },
    {
      value: 423,
      country_code: "CR",
      label: "Interbolsa",
    },
    {
      value: 424,
      country_code: "CR",
      label: "INS BanCredito",
    },
    {
      value: 425,
      country_code: "CR",
      label: "Lafise Valores",
    },
    {
      value: 426,
      country_code: "CR",
      label: "HSBC Valores",
    },
    {
      value: 427,
      country_code: "CR",
      label: "Scotia Valores",
    },
    {
      value: 428,
      country_code: "CR",
      label: "Mutual Valores",
    },
    {
      value: 429,
      country_code: "CR",
      label: "Aldesa Valores",
    },
    {
      value: 430,
      country_code: "CR",
      label: "BAC San Jose",
    },
    {
      value: 431,
      country_code: "CR",
      label: "BN-Valores",
    },
    {
      value: 432,
      country_code: "CR",
      label: "Popular",
    },
    {
      value: 433,
      country_code: "CR",
      label: "BCR – Valores",
    },
    {
      value: 434,
      country_code: "CR",
      label: "Improsa Valores",
    },
    {
      value: 435,
      country_code: "CR",
      label: "IBP Pensiones Operadora de Pensiones Complementarias S.A.",
    },
    {
      value: 436,
      country_code: "CR",
      label: "BN-Vital O.P.C.",
    },
    {
      value: 437,
      country_code: "CR",
      label: "INS-O.P.C.",
    },
    {
      value: 438,
      country_code: "CR",
      label: "Vida Plena",
    },
    {
      value: 439,
      country_code: "CR",
      label: "OPC- Caja Costarricense del Seguro Social.",
    },
    {
      value: 440,
      country_code: "CR",
      label: "BAC SAN JOSe Pensiones – Operadora de Planes de Pensiones",
    },
    {
      value: 441,
      country_code: "CR",
      label: "Mideplan",
    },
    {
      value: 442,
      country_code: "CR",
      label: "Instituto de Fomento Municipal",
    },
    {
      value: 443,
      country_code: "CR",
      label: "Instituto Costarricense de Electricidad",
    },
    {
      value: 444,
      country_code: "CR",
      label: "Recope",
    },
    {
      value: 445,
      country_code: "CR",
      label: "Ministerio de Hacienda",
    },
    {
      value: 446,
      country_code: "CR",
      label: "Central de Valores",
    },
    {
      value: 447,
      country_code: "CR",
      label: "Caja Costarricense de Seguro Social",
    },
    {
      value: 448,
      country_code: "CR",
      label: "Instituto Nacional de Seguros",
    },
    {
      value: 449,
      country_code: "CR",
      label: "Junta de Proteccion Social ",
    },
    {
      value: 450,
      country_code: "CR",
      label: "Municipalidad de San Jose",
    },
    {
      value: 451,
      country_code: "CR",
      label: "Grupo Mutual Alajuela – La Vivienda",
    },
    {
      value: 452,
      country_code: "CR",
      label: "Mutual Cartago",
    },
    {
      value: 453,
      country_code: "CR",
      label: "Coocique",
    },
    {
      value: 454,
      country_code: "CR",
      label: "CoopeAlianza",
    },
    {
      value: 455,
      country_code: "CR",
      label: "COOPENAE",
    },
    {
      value: 456,
      country_code: "CR",
      label: "Cooperativa de",
    },
    {
      value: 457,
      country_code: "CR",
      label: "Coope Ande N° 1 RL",
    },
    {
      value: 458,
      country_code: "CR",
      label: "CoopeMex R.L.",
    },
    {
      value: 459,
      country_code: "CR",
      label: "Caja de ANDE",
    },
  ],
  EC: [
    {
      value: 309,
      country_code: "EC",
      label: "BANCO AMAZONAS",
    },
    {
      value: 310,
      country_code: "EC",
      label: "BANCO BOLIVARIANO",
    },
    {
      value: 311,
      country_code: "EC",
      label: "BANCO COFIEC",
    },
    {
      value: 312,
      country_code: "EC",
      label: "BANCO COMERCIAL DE MANABI",
    },
    {
      value: 313,
      country_code: "EC",
      label: "BANCO DE FOMENTO",
    },
    {
      value: 314,
      country_code: "EC",
      label: "BANCO DE GUAYAQUIL S.A",
    },
    {
      value: 315,
      country_code: "EC",
      label: "BANCO DE LA PRODUCCION",
    },
    {
      value: 316,
      country_code: "EC",
      label: "BANCO DE LOJA",
    },
    {
      value: 317,
      country_code: "EC",
      label: "BANCO DEL AUSTRO",
    },
    {
      value: 318,
      country_code: "EC",
      label: "BANCO DEL LITORAL S.A.",
    },
    {
      value: 319,
      country_code: "EC",
      label: "BANCO DEL PACIFICO",
    },
    {
      value: 320,
      country_code: "EC",
      label: "BANCO GENERAL RUMINAHUI",
    },
    {
      value: 321,
      country_code: "EC",
      label: "BANCO INTERNACIONAL",
    },
    {
      value: 322,
      country_code: "EC",
      label: "LLOYDS BANK (BLSA)",
    },
    {
      value: 323,
      country_code: "EC",
      label: "BANCO PROMERICA",
    },
    {
      value: 324,
      country_code: "EC",
      label: "BANCO MACHALA",
    },
    {
      value: 325,
      country_code: "EC",
      label: "BANCO PICHINCHA",
    },
    {
      value: 326,
      country_code: "EC",
      label: "BANCO SOLIDARIO",
    },
    {
      value: 327,
      country_code: "EC",
      label: "BANCO SUDAMERICANO",
    },
    {
      value: 328,
      country_code: "EC",
      label: "BANCO TERRITORIAL",
    },
    {
      value: 329,
      country_code: "EC",
      label: "BANCO UNIBANCO SA.",
    },
    {
      value: 330,
      country_code: "EC",
      label: "COOP.  AHORRO Y CRED. DE LA PEQUEÑA EMPRESA DE PASTAZA",
    },
    {
      value: 331,
      country_code: "EC",
      label: "COOP. AHORRO Y CREDITO 23 DE JULIO",
    },
    {
      value: 332,
      country_code: "EC",
      label: "COOP. AHORRO Y CREDITO 29 DE OCTUBRE",
    },
    {
      value: 333,
      country_code: "EC",
      label: "COOP. AHORRO Y CREDITO ANDALUCIA",
    },
    {
      value: 334,
      country_code: "EC",
      label: "COOP. AHORRO Y CREDITO COTOCOLLAO",
    },
    {
      value: 335,
      country_code: "EC",
      label: "COOP. AHORRO Y CREDITO DESARROLLO PUEBLOS",
    },
    {
      value: 336,
      country_code: "EC",
      label: "COOP. AHORRO Y CREDITO EL SAGRARIO",
    },
    {
      value: 337,
      country_code: "EC",
      label: "COOP. AHORRO Y CREDITO GUARANDA LTDA.",
    },
    {
      value: 338,
      country_code: "EC",
      label: "COOP. AHORRO Y CREDITO NACIONAL",
    },
    {
      value: 339,
      country_code: "EC",
      label: "COOP. AHORRO Y CREDITO OSCUS",
    },
    {
      value: 340,
      country_code: "EC",
      label: "COOP. AHORRO Y CREDITO PABLO MUÑOZ VEGA.",
    },
    {
      value: 341,
      country_code: "EC",
      label: "COOP. AHORRO Y CREDITO PROGRESO",
    },
    {
      value: 342,
      country_code: "EC",
      label: "COOP. AHORRO Y CREDITO RIOBAMBA",
    },
    {
      value: 343,
      country_code: "EC",
      label: "COOP. AHORRO Y CREDITO TULCAN",
    },
    {
      value: 344,
      country_code: "EC",
      label: "COOP. CACPECO  LTDA",
    },
    {
      value: 345,
      country_code: "EC",
      label: "COOP. DE AHORRO Y CREDITO ATUNTAQUI LTDA.",
    },
    {
      value: 346,
      country_code: "EC",
      label: "COOP. PREVISION AHORRO Y  DESARROLLO",
    },
    {
      value: 347,
      country_code: "EC",
      label: "MUTUALISTA AMBATO",
    },
    {
      value: 348,
      country_code: "EC",
      label: "MUTUALISTA AZUAY",
    },
    {
      value: 349,
      country_code: "EC",
      label: "MUTUALISTA IMBABURA",
    },
    {
      value: 350,
      country_code: "EC",
      label: "MUTUALISTA PICHINCHA",
    },
    {
      value: 351,
      country_code: "EC",
      label: "BANCO PROCREDIT",
    },
    {
      value: 352,
      country_code: "EC",
      label: "BANCO DELBANK",
    },
    {
      value: 353,
      country_code: "EC",
      label: "BANCO DE LA VIVIENDA",
    },
    {
      value: 354,
      country_code: "EC",
      label: "COOP.  ALIANZA DEL VALLE",
    },
    {
      value: 355,
      country_code: "EC",
      label: "COOP. LA  DOLOROSA",
    },
    {
      value: 356,
      country_code: "EC",
      label: "COOP. JUVENTUD ECUATORIANA PROGRESISTA",
    },
    {
      value: 357,
      country_code: "EC",
      label: "COOP. SAN FRANCISCO",
    },
    {
      value: 358,
      country_code: "EC",
      label: "COOP. 9 DE OCTUBRE",
    },
    {
      value: 359,
      country_code: "EC",
      label: "COOP. COOPMEGO",
    },
    {
      value: 360,
      country_code: "EC",
      label: "COOP. PADRE JULIAN LORENTE",
    },
    {
      value: 361,
      country_code: "EC",
      label: "COOP. 11 DE JUNIO",
    },
    {
      value: 362,
      country_code: "EC",
      label: "COOP. SANTA ROSA",
    },
    {
      value: 363,
      country_code: "EC",
      label: "COOP. CHONE LTDA",
    },
    {
      value: 364,
      country_code: "EC",
      label: "COOP. COMERCIO",
    },
    {
      value: 365,
      country_code: "EC",
      label: "COOP. SANTA ANA",
    },
    {
      value: 366,
      country_code: "EC",
      label: "COOP. CALCETA",
    },
    {
      value: 367,
      country_code: "EC",
      label: "COOP. JESUS DEL GRAN PODER",
    },
    {
      value: 368,
      country_code: "EC",
      label: "PACIFICARD",
    },
    {
      value: 369,
      country_code: "EC",
      label: "DINERS CLUB",
    },
    {
      value: 370,
      country_code: "EC",
      label: "BANCO CAPITAL S.A.",
    },
    {
      value: 371,
      country_code: "EC",
      label: "COOP. AHORRO Y CREDITO CAMARA DE COMERCIO DE QUITO LTDA.",
    },
    {
      value: 372,
      country_code: "EC",
      label: "FINANCIERA - FINANCORP",
    },
    {
      value: 373,
      country_code: "EC",
      label: "COOP. DE AHORRO Y CREDITO SAN FRANCISCO DE ASIS LTDA.",
    },
    {
      value: 374,
      country_code: "EC",
      label: "COOP. DE AHORRO Y CREDITO JARDIN AZUAYO",
    },
    {
      value: 375,
      country_code: "EC",
      label:
        "COOP. DE AHORRO Y CREDITO DE LA PEQUEÑA EMPRESA CACPE BIBLIAN LTDA.",
    },
    {
      value: 376,
      country_code: "EC",
      label: "COOP. DE AHORRO Y CREDITO SAN JOSE LTDA.",
    },
    {
      value: 377,
      country_code: "EC",
      label: "COOP. DE AHORRO Y CREDITO 15 DE ABRIL LTDA.",
    },
    {
      value: 378,
      country_code: "EC",
      label: "COOP.DE AHORRO Y CREDITO SAN PEDRO DE TABOADA LTDA.",
    },
    {
      value: 379,
      country_code: "EC",
      label: "COOP. DE AHORRO Y CREDITO CAMARA DE COMERCIO DE AMBATO LTDA.",
    },
    {
      value: 380,
      country_code: "EC",
      label: "COOP. DE AHORRO Y CREDITO MUSHUC RUNA LTDA.",
    },
    {
      value: 381,
      country_code: "EC",
      label: "COOP. DE AHORRO Y CREDITO EDUC.DEL TUNGURAHUA LTDA.",
    },
    {
      value: 382,
      country_code: "EC",
      label: "COOPERATIVA DE AHORRO Y CREDITO ARTESANOS LTDA.",
    },
    {
      value: 383,
      country_code: "EC",
      label: "COOPERATIVA DE AHORRO Y CREDITO SANTA ANITA LTDA.",
    },
    {
      value: 384,
      country_code: "EC",
      label:
        "COOP. AHORRO. Y CREDI. MUJERES UNIDAS TANTANAKUSHKA WARMIKUNAPAK CACMU LTDA.",
    },
    {
      value: 385,
      country_code: "EC",
      label: "COOP. AHORRO Y CREDITO ACCION RURAL",
    },
    {
      value: 386,
      country_code: "EC",
      label: "BANCO PARA LA ASISTENCIA COMUNITARIA FINCA S.A.",
    },
    {
      value: 387,
      country_code: "EC",
      label: "INTERDIN S.A.",
    },
    {
      value: 686,
      country_code: "EC",
      label: "CITIBANK",
    },
  ],
  getDescriptionByCountryCode(country, code) {
    if (this[country]) {
      const description = this[country].find(entity => entity.value === code);
      if (description) {
        return description.label;
      }
    }
    return "UNKNOWN";
  },
};

export const bankAccountNameByCountry = {
  AR: "Clave bancaria uniforme (CBU)",
  MX: "CLABE Interbancaria",
  PE: "Numero de Cuenta",
};

export const companySizes = {
  BR: {
    "213-5 - Empresário (Individual)": "MEI",
    default: "ME",
  },
};

export const phonePrefixes = [
  { label: "+54", value: "+54", icon: ArgentinianFlag, country: "AR" },
  { label: "+55", value: "+55", icon: BrazilianFlag, country: "BR" },
  { label: "+56", value: "+56", icon: ChileanFlag, country: "CL" },
  { label: "+57", value: "+57", icon: ColombianFlag, country: "CO" },
  { label: "+52", value: "+52", icon: MexicanFlag, country: "MX" },
  { label: "+51", value: "+51", icon: PeruvianFlag, country: "PE" },
  { label: "+598", value: "+598", icon: UruguayanFlag, country: "UY" },
  { label: "+506", value: "+506", icon: CostaRicaFlag, country: "CR" },
  { label: "+593", value: "+593", icon: EcuatorianFlag, country: "EC" },
];

export const civilTypes = {
  CO: [
    { label: "C.C", value: "cc" },
    { label: "C.E", value: "ce" },
    { label: "NIT", value: "nit" },
  ],
  BR: [
    { label: "CNPJ", value: "cnpj" },
    { label: "CPF", value: "cpf" },
  ],
  MX: [{ label: "RFC", value: "rfc" }],
  AR: [
    { label: "CUIT", value: "cuit" },
    { label: "CUIL", value: "cuil" },
  ],
  PE: [
    { label: "DNI", value: "dni" },
    { label: "CE", value: "ce" },
    { label: "RUC", value: "ruc" },
    { label: "Pasaporte", value: "ps" },
  ],
  UY: [
    { label: "CI", value: "ci" },
    { label: "Pasaporte", value: "ps" },
  ],
  CL: [
    { label: "Cédula", value: "ci" },
    { label: "Pasaporte", value: "ps" },
    { label: "RUT", value: "rut" },
  ],
  EC: [
    { label: "Cédula", value: "cedula" },
    { label: "Pasaporte", value: "ps" },
  ],
  CR: [
    { label: "Cédula Física", value: "cedula fisica" },
    { label: "Cédula Jurídica", value: "cedula juridica" },
    { label: "DIMEX", value: "dimex" },
  ],
};

export const civilAndPersonTypesRel = {
  BR: {
    "pessoa fisica": "cpf",
    "pessoa juridica": "cnpj",
  },
};

export const accountTypes = {
  CO: [
    { label: "Cuenta de Ahorros", value: "Cta Ahorros" },
    { label: "Cuenta Corriente", value: "Cta Corriente" },
  ],
  BR: [{ label: "Conta Corrente", value: "Cta Corriente" }],
  MX: ["CLABE Interbancaria"],
  AR: [
    { label: "Caja de Ahorros", value: "Cta Ahorros" },
    { label: "Cuenta Corriente", value: "Cta Corriente" },
  ],
  PE: [
    { label: "Cuenta de Ahorros ", value: "Cta Ahorros" },
    { label: "Cuenta Corriente", value: "Cta Corriente" },
  ],
  UY: [
    { label: "Cuenta de Ahorros ", value: "Cta Ahorros" },
    { label: "Cuenta Corriente", value: "Cta Corriente" },
  ],
  CL: [
    { label: "Cuenta Vista", value: "Cta Ahorros" },
    { label: "Cuenta Corriente", value: "Cta Corriente" },
    { label: "Cuenta de Ahorros", value: "Cta Ahorros" },
  ],
  EC: [
    { label: "Cuenta Corriente", value: "Cta Corriente" },
    { label: "Cuenta de Ahorros", value: "Cta Ahorros" },
  ],
  CR: ["Cuenta Cliente", "Cuenta IBAN"],
};

const accountNumberLocale = translateKey("form.bankInfoForm.accountNumber");
export const alertItems = {
  AR: [
    {
      type: accountNumberLocale,
      spec:
        "La cuenta bancaria que consigne debe estar a nombre de la persona jurídica y/o física titular del local comercial que dará de alta en la plataforma",
    },
  ],
  BR: [
    {
      type: accountNumberLocale,
      spec:
        "Os dados bancários podem ser conta jurídica da empresa ou conta-corrente de um dos representantes legais da empresa e não pode ser conta-poupança",
    },
  ],
  UY: [
    {
      type: accountNumberLocale,
      spec:
        "La cuenta bancaria que consigne debe estar a nombre de la persona jurídica y/o física titular del local comercial que dará de alta en la plataforma",
    },
  ],
};
