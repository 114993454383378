import React from "react";
import { Dialog, useTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "../../../../../atoms/Button";
import modalDocumentExampleStyle from "./ModalDocumentExample.style";

const ModalDocumentExample = ({
  showModal,
  buttonText,
  image,
  closeCallback,
  ...dialogProps
}) => {
  const classes = modalDocumentExampleStyle();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog open={showModal} {...dialogProps} fullScreen={fullScreen}>
      <div className={classes.container}>
        {image && (
          <div className={classes.image}>
            <img alt="modal" className={classes.image} src={image} />
          </div>
        )}
        {buttonText && (
          <div className={classes.container}>
            <Button fullWidth onClick={closeCallback}>
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

ModalDocumentExample.propTypes = {
  showModal: PropTypes.bool,
  buttonText: PropTypes.string,
  image: PropTypes.string,
  closeCallback: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
};

ModalDocumentExample.defaultProps = {
  showModal: false,
  buttonText: null,
  image: null,
  fullWidth: true,
  maxWidth: "lg",
};

export default ModalDocumentExample;
