import { object, string, array } from "yup";
import moment from "moment";
import { translateKey } from "../../../utils/translate";
import { makeAdditionalSchema } from "../../../fieldsFactory";
import getIdSchema from "../../../schemasAndConstraints/ids/schemas";
import { noDigits } from "../../../schemasAndConstraints/utils/constraintBuilders";

const FIELD_BINDINGS = {
  socialReasonTributaryId: "socialReasonTributaryType",
  legalAgentId: "legalAgentIdType",
};

const timeSchema = object({
  starts: string().required("start time cannot be empty"),
  ends: string()
    .required("end time cannot be empty")
    .test("is-greater", "ends time should be greater", function(value) {
      const { starts } = this.parent;
      return moment(value, "HH:mm").isSameOrAfter(moment(starts, "HH:mm"));
    }),
});

const validationDefaultShape = {
  commercialName: string().required(translateKey("form.infoForm.required")),
  socialReasonTributaryType: string().required(
    translateKey("form.infoForm.required"),
  ),
  socialReasonName: string().required(translateKey("form.infoForm.required")),
  socialReasonTributaryId: string()
    .ensure()
    .required(translateKey("form.infoForm.required")),
  legalType: string().required(translateKey("form.infoForm.required")),
  legalAgentId: string()
    .ensure()
    .required(translateKey("form.infoForm.required")),
  legalAgentIdType: string().required(translateKey("form.infoForm.required")),
  legalAgentName: string().required(translateKey("form.infoForm.required")),
  legalAgentLastName: string().required(translateKey("form.infoForm.required")),
  legalAgentEmail: string()
    .email(translateKey("form.infoForm.invalidEmail"))
    .required(translateKey("form.infoForm.required")),
  schedules: object()
    .shape({
      mon: array(timeSchema),
      tue: array(timeSchema),
      wed: array(timeSchema),
      thu: array(timeSchema),
      fri: array(timeSchema),
      sat: array(timeSchema),
      sun: array(timeSchema),
    })
    .test(
      "at-least-one-number",
      "you must provide at least one schedule",
      value => Object.keys(value).some(key => value[key].length > 0),
    ),
};

const buildSchema = (businessTypes, additionalFields, country) => {
  const {
    socialReasonTributaryId: tributaryIdBinding,
    legalAgentId: legalAgentIdBinding,
  } = FIELD_BINDINGS;

  const {
    socialReasonTributaryId: tributaryIdSchema,
    legalAgentId: legalAgentIdSchema,
  } = validationDefaultShape;

  return object().shape({
    ...validationDefaultShape,
    additionalFields: object().shape({
      ...makeAdditionalSchema(additionalFields),
    }),
    businessType:
      businessTypes.length === 0
        ? string().nullable()
        : string().required(translateKey("form.infoForm.required")),
    socialReasonTributaryId: getIdSchema(
      country,
      tributaryIdBinding,
      tributaryIdSchema,
    ),
    legalAgentId: getIdSchema(country, legalAgentIdBinding, legalAgentIdSchema),
  });
};

const constraints = {
  legalAgentName: noDigits,
  legalAgentLastName: noDigits,
};

export { FIELD_BINDINGS, constraints };
export default buildSchema;
