import baseStyleInjector from "../../../assets/styles/FormBase.style";
import attachFilesFormStyles from "../AttachFilesForm/AttachFilesForm.style";

const stylizer = theme => ({
  ...baseStyleInjector(theme),
  ...attachFilesFormStyles(theme),
  text: {
    fontFamily: theme.typography.fontFamily.regular,
  },
});

export default stylizer;
