const clearIntervals = ids => ids.forEach(id => clearInterval(id));
const clearTimeouts = ids => ids.forEach(id => clearTimeout(id));

const retryRequest = (
  requestFn,
  onProgress = () => {},
  {
    retries = 4,
    retryInterval = 5000,
    timeout = 21000,
    timeoutTolerance = 2000,
    progressInterval = 500,
  } = {},
  simulateProgressArray = [],
) => {
  let reqId;
  let toleranceId;
  let requestError;
  let progressTime = 0;
  let inProgress = false;
  let retryNo = 1;

  const totalTime = timeout + timeoutTolerance;
  const additionalPct = (timeoutTolerance * 100) / timeout;
  const retryPct = (100 + additionalPct) / retries;
  const lastTargetProgress = [...simulateProgressArray].pop();
  const progressCoefficients =
    simulateProgressArray.length > 0
      ? Array(retries)
          .fill(1)
          .map((_, index) => {
            const targetProgress = simulateProgressArray[index];
            const previous = simulateProgressArray[index - 1];
            const diff = targetProgress - (previous || 0);
            const coefficient =
              targetProgress && (previous || index === 0)
                ? diff / retryPct
                : (100 - lastTargetProgress) /
                  (retries - simulateProgressArray.length) /
                  retryPct;
            return coefficient;
          })
      : Array(retries).fill(1);

  return new Promise((resolve, reject) => {
    const progressId = setInterval(() => {
      progressTime +=
        progressInterval * (progressCoefficients[retryNo - 1] || 0);
      const progress = Math.round((progressTime / totalTime) * 100);
      onProgress(progress);
    }, progressInterval);

    const timeoutId = setTimeout(() => {
      if (timeoutTolerance > 0 && inProgress) {
        toleranceId = setTimeout(() => {
          clearIntervals([reqId, progressId]);
          onProgress(100);
          reject(
            new Error(
              `Last request (${retryNo}) not within tolerance. Last request error: ${requestError}`,
            ),
          );
        }, timeoutTolerance);
        return;
      }

      onProgress(100);
      clearIntervals([reqId, progressId]);
      reject(
        new Error(
          `Request polling timed out. Last request error: ${requestError}`,
        ),
      );
    }, timeout);

    reqId = setInterval(() => {
      if (retryNo > retries) {
        clearIntervals([reqId, progressId]);
        clearTimeout(timeoutId);
        reject(
          new Error(
            `Reached maximum number of retries (${retries}). Last request error: ${requestError}`,
          ),
        );
        return;
      }

      if (inProgress) {
        return;
      }

      inProgress = true;
      requestFn(retryNo)
        .then(data => {
          clearIntervals([reqId, progressId]);
          clearTimeouts([timeoutId, toleranceId]);
          onProgress(100);
          resolve(data);
        })
        .catch(e => {
          requestError = e;
          inProgress = false;
          retryNo += 1;
        });
    }, retryInterval);
  });
};

export default retryRequest;
