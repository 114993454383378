import requester from "./requester";

export const getCoverage = (country, lat, lng) =>
  requester()
    .get(`/api/rs-self-onboarding/coverage`, {
      params: { country, lat, lng },
    })
    .then(res => res.data.coverage);

export const getDeliveryTypesByPolygon = (country, lat, lng) =>
  requester()
    .get(`/api/rs-self-onboarding/coverage/deliveryTypes`, {
      params: { country, lat, lng },
    })
    .then(res => res.data.deliveryTypeByPolygon);
