import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Grid } from "@material-ui/core/";
import * as PropTypes from "prop-types";

const SWIPE_INTERVAL_MS = 9000;

const Slider = ({ classes, items, title, subtitle }) => (
  <Grid style={{ position: "relative" }}>
    <Carousel
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      useKeyboardArrows
      autoPlay
      interval={SWIPE_INTERVAL_MS}
      swipeable
      dynamicHeight
      infiniteLoop
      className={classes.carousel}
    >
      {items.map(item => (
        <div key={item}>
          <div
            className={classes.image}
            style={{ backgroundImage: `url('${item}'` }}
          />
        </div>
      ))}
    </Carousel>
    <Grid className={classes.text}>
      <p className={classes.title}>{title}</p>
      <p className={classes.subtitle}>{subtitle}</p>
    </Grid>
  </Grid>
);

Slider.propTypes = {
  items: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Slider.defaultProps = {
  title: "",
  subtitle: "",
};

export default Slider;
