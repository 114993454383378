import { makeStyles } from "@material-ui/core";
import { fontSizes } from "../../InboundPage.style";

const numberSize = 70;
const numberSizeSm = 60;
const imgSize = 165;

const useStyles = makeStyles(
  theme => ({
    number: {
      ...fontSizes.sub4,
      width: numberSize,
      height: numberSize,
      lineHeight: `${numberSize}px`,
      textAlign: "center",
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      [theme.breakpoints.down("sm")]: {
        ...fontSizes.sub5,
        width: numberSizeSm,
        height: numberSizeSm,
        lineHeight: `${numberSizeSm}px`,
      },
    },
    stepsContainer: {
      height: 400,
      [theme.breakpoints.down("md")]: {
        height: 450,
      },
      [theme.breakpoints.down("sm")]: {
        height: "initial",
      },
    },
    stepImageContainer: {
      paddingTop: 25,
      paddingBottom: 35,
      textAlign: "center",
      "& img": {
        width: "80%",
        maxWidth: imgSize,
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: 35,
        paddingBottom: 25,
      },
    },
    stepSummary: {
      padding: 0,
      fontWeight: "bold",
      color: theme.typography.palette.main,
      textAlign: "center",
    },
  }),
  { name: "Steps", index: 1 },
);

export default useStyles;
