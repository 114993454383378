const VERIFICATION_DIGIT_P = "0";
const WEIGHTS = [2, 7, 6, 5, 4, 3, 2];

const multiplyByWeight = (number, index) => number * WEIGHTS[index];

const getAccountMod = sum => {
  const module = sum % 11;

  if (module === 0) {
    return "0";
  }

  if (module === 1) {
    return VERIFICATION_DIGIT_P;
  }

  return (11 - module).toString();
};

const getAccountVerificationDigit = accountNumber => {
  let sum = 0;
  const numbers = accountNumber.split("");

  for (let i = 0; i < numbers.length; i += 1) {
    const number = parseInt(numbers[i], 10);
    sum += multiplyByWeight(number, i);
  }

  return getAccountMod(sum);
};

const getAgencyMod = sum => {
  const result = 11 - (sum % 11);

  if (result === 10) {
    return VERIFICATION_DIGIT_P;
  }

  if (result === 11) {
    return "0";
  }

  return result.toString();
};

const getAgencyVerificationDigit = agencyNumber => {
  let sum = 0;
  const numbers = agencyNumber.split("");

  for (let i = 0; i < numbers.length; i += 1) {
    const seq = 5 - i;
    sum += parseInt(numbers[i], 10) * seq;
  }

  return getAgencyMod(sum);
};

export { getAgencyVerificationDigit, getAccountVerificationDigit };
