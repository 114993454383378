import requester from "./requester";

export const loadPreviousInfo = token =>
  requester()
    .get(`/api/rs-self-onboarding/store-process/${token}`)
    .then(res => res.data);

export const getIdentificationTypesRequest = () =>
  requester()
    .get("/api/rs-onboarding/identification-types")
    .then(res => res.data);

const sendStepRequest = (step, body, token) =>
  requester()
    .post(`/api/rs-self-onboarding/step/${step}`, body, {
      headers: {
        token,
      },
    })
    .then(res => res.data);

export const firstStepRequest = (body, token) =>
  sendStepRequest("first", body, token);

export const secondStepRequest = (body, token) =>
  sendStepRequest("second", body, token);

export const thirdStepRequest = (body, token, OCRResponse) =>
  sendStepRequest("third", body, token, OCRResponse);

export const fourthStepRequest = (body, token) =>
  sendStepRequest("fourth", body, token);

export const uploadFileRequest = (
  field,
  body,
  token,
  { onUploadProgress, onSuccess, onFail },
) =>
  requester()
    .post(`/api/rs-self-onboarding/files/store/${field}`, body, {
      onUploadProgress,
      headers: {
        token,
      },
    })
    .then(res =>
      onSuccess(
        `${requester().baseURL}/api/rs-self-onboarding/files/${res.data}`,
      ),
    )
    .catch(onFail);

export const uploadFile = (field, body, token) =>
  requester()
    .post(`/api/rs-self-onboarding/files/store/${field}`, body, {
      headers: {
        token,
      },
    })
    .then(
      res => `${requester().baseURL}/api/rs-self-onboarding/files/${res.data}`,
    );

export const validatePlace = placeId =>
  requester()
    .get(`/api/rs-onboarding/steps/place-exist/${placeId}`)
    .then(res => res.data);
