const VALID_PLACE_TYPES = [
  "establishment",
  "floor",
  "food",
  "geocode",
  "restaurant",
  "point_of_interest",
  "premise",
  "subpremise",
  "street_address",
];

export default (typesString = "", separator = ", ") => {
  const types = typesString.split(separator);
  return VALID_PLACE_TYPES.some(type => types.includes(type));
};
