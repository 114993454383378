import React, { useMemo } from "react";
import { Grid, Typography, Card, useMediaQuery } from "@material-ui/core/";
import { Carousel } from "react-responsive-carousel";
import PropTypes from "prop-types";
import classNames from "classnames";
import { GridHalfAtMd } from "../../../../../utils/Grids";
import {
  translateKey,
  translateKeyWithHtml,
} from "../../../../../utils/translate";
import TopViewTable from "../../../../../assets/images/InboundPage/top-view-rest.png";
import OutsourcedDeliveryIcon from "../../../../../assets/images/InboundPage/outsourced-delivery.svg";
import AccountStateIcon from "../../../../../assets/images/InboundPage/account-state.svg";
import ProfileIcon from "../../../../../assets/images/InboundPage/profile.svg";
import AddMoneyIcon from "../../../../../assets/images/InboundPage/add-money.svg";
import ArrowUp from "../../../../../assets/images/InboundPage/arrow-up.svg";
import useStyles from "./Perks.style";

const Perks = ({ pageClasses }) => {
  const classes = useStyles();
  const isBelowMd = useMediaQuery(theme => theme.breakpoints.down("md"));
  const isBelowSm = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const perkCards = useMemo(
    () => [
      <Card
        variant="outlined"
        className={classNames(pageClasses.perkCard, classes.highlightPerk)}
      >
        <img src={ArrowUp} alt="" className={pageClasses.roundedIcon} />
        <strong
          className={classNames(pageClasses.sub2, pageClasses.highlighted)}
        >
          {`${translateKey("inboundPage.perks.list.benefit_5_percentage")} `}
        </strong>
        <span>
          {translateKeyWithHtml("inboundPage.perks.list.benefit_5")}
          {"."}
        </span>
      </Card>,
      <Card variant="outlined" className={pageClasses.perkCard}>
        <img
          src={OutsourcedDeliveryIcon}
          alt=""
          className={pageClasses.roundedIcon}
        />
        <span>
          {translateKey("inboundPage.perks.list.benefit_1")}
          {"."}
        </span>
      </Card>,
      <Card variant="outlined" className={pageClasses.perkCard}>
        <img src={AddMoneyIcon} alt="" className={pageClasses.roundedIcon} />
        <span>
          {translateKey("inboundPage.perks.list.benefit_2")}
          {"."}
        </span>
      </Card>,
      <Card variant="outlined" className={pageClasses.perkCard}>
        <img src={ProfileIcon} alt="" className={pageClasses.roundedIcon} />
        <span>
          {translateKey("inboundPage.perks.list.benefit_3")}
          {"."}
        </span>
      </Card>,
      <Card variant="outlined" className={pageClasses.perkCard}>
        <img
          src={AccountStateIcon}
          alt=""
          className={pageClasses.roundedIcon}
        />
        <span className={pageClasses.strongDetail}>
          {translateKeyWithHtml("inboundPage.perks.list.benefit_4")}
          {"."}
        </span>
      </Card>,
    ],
    [pageClasses, classes],
  );

  return (
    <Grid container spacing={5}>
      {!isBelowSm && (
        <GridHalfAtMd item>
          <img
            src={TopViewTable}
            alt=""
            className={classes.tableImageWrapper}
          />
        </GridHalfAtMd>
      )}
      <GridHalfAtMd item container>
        <div className={pageClasses.fullWidth}>
          <Typography
            variant="h3"
            className={classNames(
              pageClasses.sub2,
              classes.perksHeader,
              pageClasses.strongMain,
            )}
          >
            {translateKeyWithHtml("inboundPage.perks.title")}
          </Typography>
          {isBelowMd ? (
            <Carousel
              autoPlay
              swipeable
              infiniteLoop
              interval={9000}
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              className={pageClasses.cardCarousel}
            >
              {perkCards}
            </Carousel>
          ) : (
            <>
              {perkCards[0]}
              <Grid item container xs={12}>
                <Grid
                  item
                  container
                  wrap="nowrap"
                  className={classes.perkCardPair}
                  xs={12}
                >
                  <Grid item xs={6}>
                    {perkCards[1]}
                  </Grid>
                  <Grid item xs={6}>
                    {perkCards[2]}
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  wrap="nowrap"
                  className={classes.perkCardPair}
                  xs={12}
                >
                  <Grid item xs={6}>
                    {perkCards[3]}
                  </Grid>
                  <Grid item xs={6}>
                    {perkCards[4]}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </GridHalfAtMd>
    </Grid>
  );
};

Perks.propTypes = {
  pageClasses: PropTypes.object.isRequired,
};

export default Perks;
