export const deliveryTypes = {
  AR: [
    {
      label: "Full service (Valor cobrado por Rappi 0% los primeros 30 días)",
      value: "fullservice",
    },
    {
      label: "Marketplace (comisión 8%)",
      value: "marketplace",
    },
  ],
  PE: [
    {
      label: "Full Service (Valor cobrado por Rappi 30%)",
      value: "fullservice",
    },
    {
      label: "Marketplace (comisión 8%)",
      value: "marketplace",
    },
  ],
  UY: [
    {
      label: "Full service (Valor cobrado por Rappi 0% los primeros 30 días)",
      value: "fullservice",
    },
    {
      label: "Marketplace (comisión 8%)",
      value: "marketplace",
    },
  ],
  EC: [
    {
      label: "Full service (Valor cobrado por Rappi 0% los primeros 30 días)",
      value: "fullservice",
    },
    {
      label: "Marketplace (comisión 8%)",
      value: "marketplace",
    },
  ],
  CL: [
    {
      label: "Full service (Valor cobrado por Rappi 0% los primeros 30 días)",
      value: "fullservice",
    },
    {
      label: "Marketplace (comisión 8%)",
      value: "marketplace",
    },
  ],
  CR: [
    {
      label: "Full service (Valor cobrado por Rappi 0% los primeros 30 días)",
      value: "fullservice",
    },
    {
      label: "Marketplace (comisión 8%)",
      value: "marketplace",
    },
  ],
  MX: [
    {
      label: "Full service (Valor cobrado por Rappi 0% los primeros 30 días)",
      value: "fullservice",
    },
    {
      label: "Marketplace (comisión 10%)",
      value: "marketplace",
    },
  ],
  BR: [
    {
      label:
        "Full service (Comissão cobrado pela Rappi será 0% nos primeiros 30 días)",
      value: "fullservice",
    },
    {
      label: "Marketplace (comisión 10%)",
      value: "marketplace",
    },
  ],
  CO: [
    {
      label: "Full service (Valor cobrado por Rappi 0% los primeros 30 días)",
      value: "fullservice",
    },
    {
      label: "Marketplace (comisión 8%)",
      value: "marketplace",
    },
  ],
  default: [
    {
      label: "Full Service (Valor cobrado por Rappi 25%)",
      value: "fullservice",
    },
    {
      label: "Marketplace (comisión 8%)",
      value: "marketplace",
    },
  ],
};

export const contractsPerCountry = {
  CO: {
    fullservice: "contracts/fullservice-co-23-11-2020.pdf",
    marketplace: "contracts/marketplace-co-23-06-2020.pdf",
  },
  CL: {
    fullservice: "contracts/fullservice-cl-10-11-2020.pdf",
    marketplace: "contracts/marketplace-cl-17-07-2020.pdf",
  },
  MX: {
    fullservice: "contracts/fullservice-mx-v2-08-10-2020.pdf",
    marketplace: "contracts/marketplace-mx-01-12-2020.pdf",
  },
  AR: {
    fullservice: "contracts/fullservice-ar-13-11-2020.pdf",
    marketplace: "contracts/marketplace-ar-05-08-2020.pdf",
  },
  BR: {
    fullservice: "contracts/fullservice-br-24-10-2020.pdf",
    marketplace: "contracts/marketplace-br-13-10-2020.pdf",
  },
  PE: {
    fullservice: "contracts/fullservice-pe-08-10-2020.pdf",
    marketplace: "contracts/marketplace-pe-16-07-2020.pdf",
  },
  CR: {
    fullservice: "contracts/fullservice-cr-v2-08-10-2020.pdf",
    marketplace: "contracts/marketplace-cr-21-07-2020.pdf",
  },
  EC: {
    fullservice: "contracts/fullservice-ec-03-12-2020.pdf",
    marketplace: "contracts/marketplace-ec-14-07-2020.pdf",
  },
  UY: {
    fullservice: "contracts/fullservice-uy-13-11-2020.pdf",
    marketplace: "contracts/marketplace-uy-05-08-2020.pdf",
  },
};

export const rappiSocialReasonNamePerCountry = {
  CO: "Rappi S.A.S",
  MX: "Tecnologías Rappi S.A.P.I. de C.V.",
  AR: "Rappi Arg S.A.S.",
  BR: "RAPPI BRASIL INTERMEDIAÇÃO DE NEGÓCIOS LTDA",
  PE: "Rappi S.A.C",
  EC: "RAPPIEC S.A.",
  CR: "RAPPI PURA VIDA, S.A.",
  CL: "Rappi Chile SPA",
  UY: "Brisanier S.A.",
};
