import React, { useMemo } from "react";
import {
  Grid,
  Typography,
  Card,
  useMediaQuery,
  CardContent,
  CardMedia,
} from "@material-ui/core/";
import PropTypes from "prop-types";
import classNames from "classnames";
import { GridFull } from "../../../../../utils/Grids";
import { translateKeyWithHtml } from "../../../../../utils/translate";
import AppHomePagePrimeAngle from "../../../../../assets/images/InboundPage/mockup-prime@2x.png";
import AppHomePageVertical from "../../../../../assets/images/InboundPage/rappi-home@2x.png";
import AppEcommerce from "../../../../../assets/images/InboundPage/rappi-ecommerce@2x.png";
import RappiIcon from "../../../../../assets/images/InboundPage/icon-rappi.png";
import RappiPrimeIcon from "../../../../../assets/images/InboundPage/icon-rappiprime.png";
import Robots from "../../../../../assets/images/InboundPage/robots-rappi@2x.png";
import ProfileCircle from "../../../../../assets/images/InboundPage/profile-circle.svg";
import Favorite from "../../../../../assets/images/InboundPage/favorite.svg";
import Shop from "../../../../../assets/images/InboundPage/shop.svg";
import useStyles from "./RappiHighlights.style";

const createGridColumn = (classes, pageClasses) => ({ children }) => (
  <Grid
    item
    container
    xs={12}
    sm={6}
    md={5}
    lg={5}
    xl={5}
    spacing={0}
    className={pageClasses.elevated1}
  >
    {children}
  </Grid>
);

const RappiHighlights = ({ pageClasses }) => {
  const classes = useStyles();
  const isBelowSm = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const isBelowXs = useMediaQuery(theme => theme.breakpoints.down("xs"));

  const GridColumn = useMemo(() => createGridColumn(classes, pageClasses), [
    classes,
    pageClasses,
  ]);

  const sectionItems = useMemo(
    () => [
      <Card
        key={0}
        variant="outlined"
        className={classNames(
          pageClasses.perkCard,
          classes.highlightCard,
          classes.expandBottomMargin,
        )}
      >
        <Grid container spacing={2}>
          <GridFull item>
            <div className={classes.appImgsWrapper}>
              <img
                src={RappiIcon}
                alt=""
                className={classNames(pageClasses.elevated1, classes.rappiIcon)}
              />
              <div className={classes.appImgsContainer}>
                <img src={AppHomePageVertical} alt="" />
                <img src={AppEcommerce} alt="" />
              </div>
            </div>
          </GridFull>
          <GridFull item>
            <Typography paragraph>
              {translateKeyWithHtml("inboundPage.highlights.list.highlight_1")}
              {"."}
            </Typography>
          </GridFull>
        </Grid>
      </Card>,
      <React.Fragment key={1}>
        <Typography variant="h5">
          {translateKeyWithHtml("inboundPage.highlights.objectives.title")}
        </Typography>
        <div className={classes.bullets}>
          <div className={pageClasses.flexCentering}>
            <img
              src={ProfileCircle}
              alt=""
              className={pageClasses.roundedIcon}
            />
            <span>
              {translateKeyWithHtml(
                "inboundPage.highlights.objectives.list.objective_1",
              )}
              {"."}
            </span>
          </div>
          <div className={pageClasses.flexCentering}>
            <img src={Shop} alt="" className={pageClasses.roundedIcon} />
            <span>
              {translateKeyWithHtml(
                "inboundPage.highlights.objectives.list.objective_2",
              )}
              {"."}
            </span>
          </div>
          <div className={pageClasses.flexCentering}>
            <img src={Favorite} alt="" className={pageClasses.roundedIcon} />
            <span>
              {translateKeyWithHtml(
                "inboundPage.highlights.objectives.list.objective_3",
              )}
              {"."}
            </span>
          </div>
        </div>
      </React.Fragment>,
      <Card
        key={2}
        variant="outlined"
        className={classNames(
          pageClasses.perkCard,
          classes.highlightCard,
          classes.expandBottomMargin,
          classes.keepBottomMargin,
        )}
      >
        <CardContent>
          <Typography paragraph>
            {translateKeyWithHtml("inboundPage.highlights.list.highlight_2")}
            {"."}
          </Typography>
        </CardContent>
        <CardMedia>
          <img
            src={RappiPrimeIcon}
            alt=""
            className={classNames(pageClasses.elevated1, classes.rappiIcon)}
          />
        </CardMedia>
      </Card>,
      <Card
        key={3}
        variant="outlined"
        className={classNames(pageClasses.perkCard, classes.highlightCard)}
      >
        <CardMedia>
          <img src={Robots} alt="" className={classes.cardImage} />
        </CardMedia>
        <CardContent>
          <Typography paragraph>
            {translateKeyWithHtml("inboundPage.highlights.list.highlight_3")}
            {"."}
          </Typography>
        </CardContent>
      </Card>,
    ],
    [classes, pageClasses],
  );

  const items = isBelowXs
    ? [sectionItems[0], sectionItems[2], sectionItems[3], sectionItems[1]]
    : sectionItems;

  return (
    <div className={pageClasses.positionRelative}>
      <Typography
        variant="h3"
        className={classNames(pageClasses.sub2, pageClasses.headerBottomMargin)}
      >
        ¿Qué nos hace
        <strong className={pageClasses.highlighted}> diferentes?</strong>
      </Typography>
      <Grid
        container
        spacing={isBelowSm ? 1 : 4}
        className={pageClasses.disableMarginAtXs}
      >
        <GridColumn>
          <GridFull item>{items[0]}</GridFull>
          <GridFull item>{items[1]}</GridFull>
        </GridColumn>
        <GridColumn>
          <GridFull item>{items[2]}</GridFull>
          <GridFull item>{items[3]}</GridFull>
        </GridColumn>
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2} />
      </Grid>
      <div className={classes.backgroundWrapper}>
        <div
          className={classNames(
            pageClasses.aspectRatio_1x1,
            pageClasses.backgroundCircle,
          )}
        />
        <img
          src={AppHomePagePrimeAngle}
          alt=""
          className={classNames(classes.appPrimeMockup, pageClasses.elevated2)}
        />
      </div>
    </div>
  );
};

RappiHighlights.propTypes = {
  pageClasses: PropTypes.object.isRequired,
};

export default RappiHighlights;
