import * as defaultSchemas from "./default/schemas";
import * as bancoDoBrasil from "./bancoDoBrasil/schemas";
import * as santander from "./santander/schemas";
import * as banrisul from "./banrisul/schemas";
import * as bradesco from "./bradesco/schemas";
import * as itau from "./itau/schemas";
import * as citibank from "./citibank/schemas";

const bankSchemas = {
  defaultSchemas,
  "2": bancoDoBrasil,
  "10": santander,
  "15": banrisul,
  "47": bradesco,
  "56": itau,
  "97": citibank,
};

const getBankSchema = bankId => {
  const bankSchema = bankSchemas[bankId];
  return bankSchema || defaultSchemas;
};

export default getBankSchema;
