import JenosPizza from "../../../../../assets/images/InboundPage/brands-06-jenos-pizza@2x.png";
import Mc from "../../../../../assets/images/InboundPage/brands-03-mc@2x.png";
import PapaJohns from "../../../../../assets/images/InboundPage/brands-04-papa-johns@2x.png";
import Masa from "../../../../../assets/images/InboundPage/brands-05-masa@2x.png";
import Home from "../../../../../assets/images/InboundPage/brands-08-home@2x.png";
import ElCorral from "../../../../../assets/images/InboundPage/brands-07-el-corral@2x.png";
import Poke from "../../../../../assets/images/InboundPage/brands-01-poke@2x.png";
import Kfc from "../../../../../assets/images/InboundPage/brands-02-kfc@2x.png";
import { addIds } from "../../../../../utils/functions";

const brandImages = [
  {
    imgSrc: JenosPizza,
    imgAlt: "JenosPizza",
  },
  {
    imgSrc: Mc,
    imgAlt: "McDonald's",
  },
  {
    imgSrc: PapaJohns,
    imgAlt: "Papa John's",
  },
  {
    imgSrc: Masa,
    imgAlt: "Masa",
  },
  {
    imgSrc: Home,
    imgAlt: "Home Burgers",
  },
  {
    imgSrc: ElCorral,
    imgAlt: "El Corral",
  },
  {
    imgSrc: Poke,
    imgAlt: "Poke",
  },
  {
    imgSrc: Kfc,
    imgAlt: "KFC",
  },
];

export default addIds(brandImages);
