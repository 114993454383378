import requester from "./requester";

const setStoreStatusPending = dealId =>
  requester().put(
    `api/rs-self-onboarding/store/${dealId}/status`,
    {
      documents_status: "pending",
    },
    { headers: { "Content-Type": "application/json" } },
  );

export default setStoreStatusPending;
