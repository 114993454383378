/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import Grid from "@material-ui/core/Grid";
import { Trans } from "react-i18next";
import PropTypes from "prop-types";
import RappiBar from "../../molecules/RappiBar";
import config from "../../../config";
import Button from "../../atoms/Button";
import LastStepImage from "../../../assets/images/last-step.png";
import { translateKey } from "../../../utils/translate";

const REDIRECT_BASE_URL = `${config.PARTNERS_PORTAL}/signup`;

const FinalStep = ({ classes, hadModifications, userInfo }) => {
  const { country, owners_email: email } = userInfo;
  const redirectUrl = !hadModifications
    ? `${REDIRECT_BASE_URL}/${country}/${email}`
    : config.PARTNERS_PORTAL;

  const onButtonClick = () => {
    window.location.href = redirectUrl;
  };

  return (
    <Grid container className={classes.root}>
      <RappiBar />
      <Grid container justify="center" className={classes.content}>
        <Grid item xs={12}>
          <span className={classes.title}>
            {translateKey("lastStep.almostDone")}
          </span>
        </Grid>
        <Grid item xs={12}>
          <span className={classes.subTitle}>
            {translateKey(
              hadModifications ? "lastStep.thanksModified" : "lastStep.thanks",
            )}
          </span>
        </Grid>
        <Grid item xs={12}>
          <p className={classes.paragraph}>
            {hadModifications ? (
              <Trans i18nKey="lastStep.paragraphModified" email={email}>
                Ingresa al
                <a href={redirectUrl} rel="noopener noreferrer">
                  {" "}
                  Portal Partners{" "}
                </a>
                con tu email {{ email }}
                para que puedas terminar con el proceso de creación de menú y
                activación.
              </Trans>
            ) : (
              <Trans i18nKey="lastStep.paragraph" email={email}>
                Al pulsar en Continuar vas a poder crear tu usuario {{ email }}{" "}
                y contraseña en el
                <a href={redirectUrl} rel="noopener noreferrer">
                  {" "}
                  Portal Partners{" "}
                </a>
                donde vas a definir los últimos detalles de tu tienda/menú antes
                de comenzar a vender por Rappi.
              </Trans>
            )}
          </p>
        </Grid>
        <Button onClick={onButtonClick} style={{ width: "100%" }}>
          {translateKey("lastStep.continue")}
        </Button>
        <img
          src={LastStepImage}
          className={classes.bottomImage}
          alt="lastStepImage"
        />
      </Grid>
    </Grid>
  );
};

FinalStep.defaultProps = {
  hadModifications: false,
};

FinalStep.propTypes = {
  classes: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  hadModifications: PropTypes.bool,
};

export default FinalStep;
