/* eslint camelcase: 0 */

export const toLegalAgent = info => ({
  name: info.legalAgentName,
  last_name: info.legalAgentLastName,
  id: info.legalAgentId,
  type_id: info.legalAgentIdType,
  email: info.legalAgentEmail,
  person_type: info.legalType,
});

export const toSocialReason = info => ({
  name: info.socialReasonName,
  commercial_name: info.commercialName,
  id: info.socialReasonTributaryId,
  type_id: info.socialReasonTributaryType,
  business_type: info.businessType,
  legal_agent: toLegalAgent(info),
});

export const fromLegalAgent = (
  { legal_agent = {} },
  { owners_name, owners_last_name, owners_email },
) => ({
  legalAgentName: legal_agent.name || owners_name,
  legalAgentLastName: legal_agent.last_name || owners_last_name,
  legalAgentId: legal_agent.identification,
  legalAgentIdType: legal_agent.identification_type,
  legalAgentEmail: legal_agent.email || owners_email,
  legalType: legal_agent.person_type
    ? legal_agent.person_type.toLowerCase()
    : null,
});

export const fromSocialReason = ({ name, social_reason = {}, ...rest }) => ({
  socialReasonName: social_reason.name,
  commercialName: name,
  socialReasonTributaryId: social_reason.number,
  socialReasonTributaryType: social_reason.type,
  businessType: social_reason.regime,
  ...fromLegalAgent(social_reason, rest),
});
