import baseStyleInjector from "../../../assets/styles/FormBase.style";

const stylizer = theme => ({
  ...baseStyleInjector(theme),
  link: {
    display: "block",
    margin: "16px 0px",
    color: theme.palette.primary.main,
    fontSize: 16,
    fontFamily: theme.typography.fontFamily.regular,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
  firstAlert: {
    marginBottom: 20,
  },
  alertItem: {
    fontSize: 12,
    margin: 0,
    marginBottom: 8,
    "& dt": {
      fontWeight: "bold",
    },
    "& dd, dl": {
      margin: 0,
    },
  },
  fieldContainer: {
    marginBottom: 36,
  },
  block: {
    display: "block",
  },
  docList: {
    margin: 0,
  },
});

export default stylizer;
