import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Effect from "../../atoms/FormikEffect";
import GeneralInformationForm from "./GeneralInformationForm";
import withNotifier from "../../../hocs/notifications/withNotifier";
import withLoader from "../../../hocs/loader/withLoader";
import buildValidationSchema, {
  FIELD_BINDINGS,
  constraints,
} from "./GeneralInformationSchema";
import {
  businessTypes as BusinessTypesMap,
  personTypes,
  tributaryTypes,
  civilTypes,
} from "./GeneralInformationForm.constants";
import getCnpj from "../../../api/cnpj";
import { fromSocialReason } from "../../../mappers/Business";
import { fromSchedules } from "../../../mappers/Store";
import { mergeWithDefault } from "../../../utils/functions";
import idConstraints from "../../../schemasAndConstraints/ids/constraints";
import { useFieldConstraints as constrainFields } from "../../../hooks/useFieldConstraints";
import { translateKey } from "../../../utils/translate";

const defaultValues = {
  commercialName: "",
  socialReasonTributaryType: "",
  socialReasonTributaryId: "",
  socialReasonName: "",
  legalType: "",
  businessType: "",
  legalAgentId: "",
  legalAgentIdType: "",
  legalAgentTributaryId: "",
  legalAgentTributaryType: "",
  legalAgentName: "",
  legalAgentLastName: "",
  legalAgentEmail: "",
  schedules: {
    mon: [{ starts: "08:00", ends: "17:00" }],
    tue: [{ starts: "08:00", ends: "17:00" }],
    wed: [{ starts: "08:00", ends: "17:00" }],
    thu: [{ starts: "08:00", ends: "17:00" }],
    fri: [{ starts: "08:00", ends: "17:00" }],
    sat: [{ starts: "08:00", ends: "17:00" }],
    sun: [{ starts: "08:00", ends: "17:00" }],
  },
  additionalFields: {},
};

const defaultStatus = {
  socialReasonTributaryId: {
    isValidating: false,
    data: null,
  },
};

export const loadRequirements = async (_, { countryCode, steps }) => {
  const country = countryCode || "CO";
  const additionalFields = steps[1] || [];
  const businessTypes = BusinessTypesMap[country]
    ? BusinessTypesMap[country]
    : [];
  const validationSchema = buildValidationSchema(
    businessTypes,
    additionalFields,
    country,
  );

  return {
    country,
    personTypes: personTypes[country] || personTypes.CO,
    tributaryTypes: tributaryTypes[country] || tributaryTypes.CO,
    civilTypes: civilTypes[country] || civilTypes.CO,
    businessTypes,
    validationSchema,
    additionalFields,
  };
};

const getInitialStatus = (name, additionalData) => {
  const { socialReasonTributaryId: id } = additionalData[name] || {
    socialReasonTributaryId: null,
  };

  return id
    ? {
        socialReasonTributaryId: { data: id, isValidating: false },
      }
    : defaultStatus;
};

export const getInitialValues = userInfo => {
  const initialValues = {
    ...fromSocialReason(userInfo),
    schedules: userInfo.catalog_info
      ? fromSchedules(userInfo.catalog_info)
      : null,
    additionalFields: userInfo.additional_fields,
  };
  return mergeWithDefault(initialValues, defaultValues);
};

const setCnpjData = (
  name,
  additionalData,
  setStepAdditionalData,
  { values, setFieldValue, errors, setErrors, status, setStatus },
  { loader, notifier },
) => {
  const valueKey = "socialReasonTributaryId";
  const { data, isValidating } = status[valueKey];

  if (data || isValidating) return;

  loader.load();
  setStatus({
    socialReasonTributaryId: { ...status[valueKey], isValidating: true },
  });

  getCnpj(values[valueKey])
    .then(data => {
      const { name: socialReasonName } = data;
      setStepAdditionalData(name, {
        socialReasonTributaryId: data,
      });
      setStatus({ socialReasonTributaryId: { data, isValidating: false } });
      setFieldValue("socialReasonName", socialReasonName);
    })
    .catch(() => {
      notifier.error(translateKey("form.infoForm.cnpjValidationError"));
      setStepAdditionalData(name, { socialReasonTributaryId: null });
      setStatus(defaultStatus);
    })
    .finally(() => {
      loader.stop();
      setErrors({ ...errors, [valueKey]: "Could not validate your CNPJ" });
    });
};

const ConfiguredForm = ({
  userInfo = {},
  country,
  name,
  onFormChange,
  stepsValues,
  setStepAdditionalData,
  civilTypes,
  tributaryTypes,
  personTypes,
  businessTypes,
  validationSchema,
  additionalFields,
  notifier,
  loader,
}) => {
  const isBR = country === "BR";
  const { additionalData } = stepsValues;
  const initialStatus = getInitialStatus(name, additionalData);
  const combinedConstraints = {
    ...idConstraints[country],
    ...constraints,
  };

  let initialValues = getInitialValues(userInfo);
  defaultValues.legalAgentIdType = civilTypes ? civilTypes[0].value : "";
  defaultValues.socialReasonTributaryType = tributaryTypes
    ? tributaryTypes[0].value
    : "";

  initialValues = isEmpty(initialValues) ? defaultValues : initialValues;

  return (
    <Formik
      validateOnMount
      enableReinitialize
      onSubmit={() => {}}
      initialStatus={initialStatus}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, errors, status, setStatus, ...props }) => {
        const formProps = { values, errors, status, setStatus, ...props };
        formProps.isValid = isBR
          ? !!(formProps.isValid && status.socialReasonTributaryId.data)
          : formProps.isValid;

        const { onChange: onChangeFn } = constrainFields(
          handleChange,
          combinedConstraints,
          FIELD_BINDINGS,
        );

        const onChange = evt => {
          onChangeFn(evt, values);
        };

        onFormChange(formProps);

        return (
          <>
            <Effect
              onChange={(current, previous) => {
                const { values: currentValues } = current;
                const { values: prevValues } = previous || { values: {} };
                const { socialReasonTributaryId } = currentValues;
                if (
                  !isBR ||
                  socialReasonTributaryId === prevValues.socialReasonTributaryId
                ) {
                  return;
                }
                validationSchema
                  .validateAt("socialReasonTributaryId", currentValues)
                  .then(() => {
                    setCnpjData(
                      name,
                      additionalData,
                      setStepAdditionalData,
                      current,
                      {
                        loader,
                        notifier,
                      },
                    );
                  })
                  .catch(() => setStatus(defaultStatus));
              }}
            />
            <GeneralInformationForm
              {...formProps}
              handleChange={onChange}
              tributaryTypes={tributaryTypes}
              civilTypes={civilTypes}
              personTypes={personTypes}
              businessTypes={businessTypes}
              additionalFields={additionalFields}
            />
          </>
        );
      }}
    </Formik>
  );
};

ConfiguredForm.defaultProps = {
  userInfo: {},
  civilTypes: [],
  tributaryTypes: [],
  onFormChange: () => {},
  additionalFields: [],
  country: "CO",
};

ConfiguredForm.propTypes = {
  country: PropTypes.string,
  userInfo: PropTypes.object,
  name: PropTypes.string.isRequired,
  onFormChange: PropTypes.func,
  stepsValues: PropTypes.object.isRequired,
  setStepAdditionalData: PropTypes.func.isRequired,
  civilTypes: PropTypes.array,
  tributaryTypes: PropTypes.array,
  personTypes: PropTypes.array.isRequired,
  businessTypes: PropTypes.array.isRequired,
  validationSchema: PropTypes.object.isRequired,
  additionalFields: PropTypes.array,
  notifier: PropTypes.object.isRequired,
  loader: PropTypes.object.isRequired,
};

export default withLoader(withNotifier(ConfiguredForm));
