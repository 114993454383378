import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, withStyles } from "@material-ui/core";
import classNames from "classnames";
import styles from "./BankInfoForm.style";
import TextField from "../../atoms/TextField";
import Checkbox from "../../atoms/Checkbox";
import Select from "../../atoms/Select";
import Alert from "../../atoms/Alert";
import FormSectionTitle from "../../atoms/FormSectionTitle";
import CombinedField from "../../molecules/CombinedField";
import { translateKey } from "../../../utils/translate";
import { getAdditionalComponents } from "../../../fieldsFactory";
import { OCR_ATTEMPTS_BANK_CERT } from "../../../api/ocr/bank-account-certification/bankAccountCertification";

const BankInfoForm = ({
  classes,
  country,
  values = {},
  stepsValues,
  handleChange,
  setValues,
  setFieldTouched,
  touched,
  errors,
  civilTypes,
  civilAndPersonTypesRel,
  banks,
  accountTypes,
  bankAccountNumberName,
  phonePrefixes,
  additionalFields,
  alertItems,
}) => {
  const [personType, setPersonType] = useState("");
  const isBR = country === "BR";
  const {
    legalAgentId,
    legalAgentName,
    legalAgentLastName,
    socialReasonName,
    socialReasonTributaryId,
  } = stepsValues.generalInfo;

  const onPersonTypeChange = personType => {
    switch (personType) {
      case "pessoa fisica":
        setValues({
          ...values,
          ownerName: legalAgentName,
          ownerLastName: legalAgentLastName,
          ownerIdType: civilAndPersonTypesRel["pessoa fisica"],
          ownerId: legalAgentId,
        });
        setPersonType("pessoa fisica");
        break;
      case "pessoa juridica":
        setValues({
          ...values,
          ownerName: socialReasonName,
          ownerLastName: legalAgentLastName,
          ownerIdType: civilAndPersonTypesRel["pessoa juridica"],
          ownerId: socialReasonTributaryId,
        });
        setPersonType("pessoa juridica");
        break;
      default:
        break;
    }
  };

  const onChangeBankCert = () => {
    OCR_ATTEMPTS_BANK_CERT.userChangedTheInfo = true;
  };

  return (
    <Grid container style={{ padding: "12px 0 24px 0" }}>
      <FormSectionTitle title={translateKey("form.bankInfoForm.title")} />
      <Grid container item xs={12}>
        <Grid
          item
          xs={12}
          className={classNames(classes.field, classes.alertContainer)}
        >
          <div className={classes.firstAlert}>
            <Alert color="red">
              <p className={classes.alertItem}>
                {translateKey("form.infoForm.alert")}
              </p>
              <dl style={{ margin: 0 }}>
                {alertItems.map(({ type, spec }) => (
                  <p key={type} className={classes.alertItem}>
                    <dt>{`${type}:`}</dt>
                    <dd>{spec}</dd>
                  </p>
                ))}
              </dl>
            </Alert>
          </div>
        </Grid>
        {isBR && (
          <Grid container item xs={12}>
            <Grid item xs={12} md={6} className={classes.field}>
              <Typography variant="body1" className={classes.text}>
                Conta
              </Typography>
              <Checkbox
                disabled={values.companySize !== "MEI"}
                name="pessoa fisica"
                value={personType === "pessoa fisica"}
                handleChange={() => onPersonTypeChange("pessoa fisica")}
                label="Pessoa fisica"
              />
              <Checkbox
                name="pessoa juridica"
                value={personType === "pessoa juridica"}
                handleChange={() => onPersonTypeChange("pessoa juridica")}
                label="Pessoa juridica"
              />
            </Grid>
          </Grid>
        )}
        <Grid container item xs={12}>
          <Grid item xs={12} md={6} className={classes.field}>
            <TextField
              showError
              disabled={isBR}
              name="ownerName"
              value={values.ownerName}
              title={translateKey("form.bankInfoForm.accountOwnerName")}
              handleChange={handleChange}
              setFieldTouched={setFieldTouched}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.field}>
            {personType !== "pessoa juridica" && (
              <TextField
                showError
                disabled={isBR}
                name="ownerLastName"
                value={values.ownerLastName}
                title={translateKey("form.bankInfoForm.accountOwnerLastName")}
                handleChange={handleChange}
                setFieldTouched={setFieldTouched}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={classes.field}>
          <Select
            name="bankId"
            value={values.bankId || "none"}
            onChange={handleChange}
            onBlur={() => setFieldTouched("bankId", true)}
            title={translateKey("form.bankInfoForm.bank")}
            placeholder={translateKey("form.select")}
            options={banks}
            error={touched.bankId && errors.bankId ? errors.bankId : ""}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.field}>
          <Select
            name="typeId"
            value={values.typeId || "none"}
            onChange={handleChange}
            onBlur={() => setFieldTouched("typeId", true)}
            title={translateKey("form.bankInfoForm.accountType")}
            placeholder={translateKey("form.select")}
            options={accountTypes}
            error={touched.typeId && errors.typeId ? errors.typeId : ""}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} className={classes.field}>
        <TextField
          showError
          name="number"
          value={values.number}
          title={bankAccountNumberName}
          placeholder={translateKey("form.writeHere")}
          handleChange={handleChange}
          onInput={onChangeBankCert}
          setFieldTouched={setFieldTouched}
        />
      </Grid>
      {additionalFields &&
        getAdditionalComponents(
          additionalFields,
          values,
          handleChange,
          setFieldTouched,
        ).map((field, index) => (
          <Grid item xs={12} md={6} className={classes.field} key={index}>
            {field}
          </Grid>
        ))}
      <Grid item xs={12} md={6} className={classes.field}>
        <CombinedField
          showErrors
          fieldNames={["ownerIdType", "ownerId"]}
          title={translateKey("form.bankInfoForm.accountOwnerId")}
          leftField={
            <Select
              disabled={isBR}
              name="ownerIdType"
              value={values.ownerIdType}
              onChange={handleChange}
              onBlur={() => setFieldTouched("ownerIdType", true)}
              placeholder={translateKey("form.select")}
              options={civilTypes}
            />
          }
          rightField={
            <TextField
              disabled={isBR}
              name="ownerId"
              value={values.ownerId}
              placeholder={translateKey("form.writeHere")}
              handleChange={handleChange}
              setFieldTouched={setFieldTouched}
              showError={false}
            />
          }
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.field}>
        <TextField
          showError
          name="fiscalAddress"
          value={values.fiscalAddress}
          title={translateKey("form.bankInfoForm.fiscalAddress")}
          placeholder={translateKey("form.writeHere")}
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.field}>
        <TextField
          showError
          name="billingAddress"
          value={values.billingAddress}
          title={translateKey("form.bankInfoForm.billingAddress")}
          placeholder={translateKey("form.writeHere")}
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.field}>
        <CombinedField
          showErrors
          fieldNames={["phonePrefix", "ownerTelephone"]}
          title={translateKey("form.bankInfoForm.contactPhone")}
          leftField={
            <Select
              name="phonePrefix"
              value={values.phonePrefix}
              onChange={handleChange}
              placeholder={translateKey("form.select")}
              onBlur={() => setFieldTouched("phonePrefix", true)}
              options={phonePrefixes}
            />
          }
          rightField={
            <TextField
              name="ownerTelephone"
              type="number"
              value={values.ownerTelephone}
              placeholder={translateKey("form.writeHere")}
              handleChange={handleChange}
              setFieldTouched={setFieldTouched}
            />
          }
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.field}>
        <TextField
          showError
          name="ownerEmail"
          value={values.ownerEmail}
          title={translateKey("form.bankInfoForm.contactEmail")}
          placeholder={translateKey("form.writeHere")}
          handleChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </Grid>
    </Grid>
  );
};

BankInfoForm.defaultProps = {
  additionalFields: [],
};

BankInfoForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  stepsValues: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  civilTypes: PropTypes.array.isRequired,
  civilAndPersonTypesRel: PropTypes.object.isRequired,
  banks: PropTypes.array.isRequired,
  accountTypes: PropTypes.array.isRequired,
  bankAccountNumberName: PropTypes.string.isRequired,
  phonePrefixes: PropTypes.array.isRequired,
  additionalFields: PropTypes.array,
  alertItems: PropTypes.array.isRequired,
};

export default withStyles(styles)(BankInfoForm);
