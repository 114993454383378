import baseStyleInjector from "../../../assets/styles/FormBase.style";

const stylizer = theme => {
  const styles = {
    ...baseStyleInjector(theme),
    field: {
      padding: "8px 24px 24px 24px",
      "& .RappiTextField": {
        width: "calc(100% - 16px)",
      },
    },
    info: {
      display: "inline-block",
      fontFamily: theme.typography.fontFamily.light,
      fontSize: "12px",
      padding: "20px 0px 20px 0px",
      letterSpacing: 0.3,
      "& > span": {
        color: theme.palette.secondary.main,
      },
    },
    infoFirst: {
      display: "inline-block",
      fontFamily: theme.typography.fontFamily.light,
      fontSize: "12px",
      padding: "0px 0px 20px 0px",
      letterSpacing: 0.3,
      "& > span": {
        color: theme.palette.secondary.main,
      },
    },
    microCompanyInfo: {
      margin: "10px 0px 0",
      fontSize: "12px",
      fontFamily: theme.typography.fontFamily.light,
      letterSpacing: 0.3,
    },
    label: {
      padding: "0px 24px 32px 24px",
    },
    mapContainer: {
      height: 120,
      paddingBottom: 20,
      position: "relative",
      width: "100%",
    },
  };

  styles.dayIndicator = {
    fontFamily: theme.typography.fontFamily.light,
    letterSpacing: 0.3,
    fontSize: 15,
    color: theme.palette.disabled.dark,
    marginTop: 15,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: 15,
    },
  };

  styles.dayTip = {
    ...styles.dayIndicator,
    marginTop: 0,
    fontSize: 12,
    paddingTop: 20,
  };

  styles.lessPaddingBottom = {
    paddingBottom: "16px",
  };

  styles.helpInfo = {
    ...styles.info,
    padding: 0,
    color: theme.palette.secondary.main,
    fontSize: 12,
  };

  return styles;
};

export default stylizer;
