import React, { useEffect } from "react";
import * as PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import styles from "./ServiceModelForm.style";
import { translateKey } from "../../../utils/translate";
import Checkbox from "../../atoms/Checkbox";
import PDFViewer from "../PDFViewer";
import Select from "../../atoms/Select";
import FormSectionTitle from "../../atoms/FormSectionTitle";
import { getAdditionalComponents } from "../../../fieldsFactory";
import Alert from "../../atoms/Alert";

const ServiceModelForm = ({
  values = {},
  classes,
  handleChange,
  setFieldTouched,
  deliveryTypes,
  termsVersionPerDeliveryType,
  setFieldValue,
  additionalFields,
  rappiSocialReason,
  notifier,
}) => {
  const deliverySelectProps =
    deliveryTypes.length > 1
      ? {
          title: translateKey("form.serviceModel.deliveryType"),
        }
      : {
          title: translateKey("form.serviceModel.deliveryTypeNoQuestion"),
          inputProps: { disabled: true },
          IconComponent: () => null,
        };
  const blockHandleChange = !values.version || !values.areTermsRead;

  const handleCheckboxChange = evt => {
    if (blockHandleChange) {
      notifier.error(translateKey("form.serviceModel.acceptNotification"), {
        preventDuplicate: true,
      });
      return;
    }
    handleChange(evt);
  };

  const setValuesFromDeliveryMethod = (deliveryMethod, initChecked = false) => {
    const newVersion = termsVersionPerDeliveryType[deliveryMethod];
    setFieldValue("version", newVersion);
    setFieldValue("deliveryMethod", deliveryMethod);
    setFieldValue("isTermsChecked", initChecked);
  };

  useEffect(() => {
    if (deliveryTypes.length !== 1) {
      return;
    }

    const DEFAULT_DELIVERY_METHOD = deliveryTypes[0].value;
    const initChecked =
      values.deliveryMethod === DEFAULT_DELIVERY_METHOD &&
      values.isTermsChecked;
    setValuesFromDeliveryMethod(DEFAULT_DELIVERY_METHOD, initChecked);
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container style={{ padding: "12px 0 24px 0" }}>
      <FormSectionTitle title={translateKey("form.serviceModel.title")} />
      <Grid item xs={12} className={classNames(classes.alertContainer)}>
        <Alert message={translateKey("form.serviceModel.alert")} />
      </Grid>
      <Grid container item xs={12} className={classes.field}>
        {deliveryTypes.find(({ value }) => value === "fullservice") && (
          <p className={classes.paragraph}>
            <strong>{translateKey("form.serviceModel.fullService")}</strong>
            {translateKey("form.serviceModel.fullServiceDescription")}
          </p>
        )}
        {deliveryTypes.find(({ value }) => value === "marketplace") && (
          <p className={classes.paragraph}>
            <strong>{translateKey("form.serviceModel.marketPlace")}</strong>
            {translateKey("form.serviceModel.marketPlaceDescription")}
          </p>
        )}
      </Grid>
      <Grid container item xs={12}>
        <Grid
          item
          sm={8}
          xs={12}
          className={classNames(classes.field, classes.noWrap)}
        >
          <Select
            name="deliveryType"
            value={values.deliveryMethod || "none"}
            onChange={evt => {
              setValuesFromDeliveryMethod(evt.target.value);
            }}
            onBlur={() => setFieldTouched("deliveryType", true)}
            placeholder={translateKey("form.select")}
            options={deliveryTypes}
            {...deliverySelectProps}
            style={{
              width: "fit-content",
              minWidth: "1px",
              maxWidth: "100%",
            }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.lastField}>
        {additionalFields &&
          getAdditionalComponents(
            additionalFields,
            values,
            handleChange,
            setFieldTouched,
          ).map((field, index) => (
            <Grid item sm={6} xs={12} className={classes.field} key={index}>
              {field}
            </Grid>
          ))}
      </Grid>
      {values.version && (
        <>
          <FormSectionTitle
            title={translateKey("form.serviceModel.termsAndConditions")}
            link={{
              label: translateKey("form.serviceModel.download"),
              url: values.version,
            }}
          />
          <Grid container item xs={12}>
            <Grid
              item
              sm={12}
              xs={12}
              className={classNames(classes.field, classes.lastField)}
            >
              <PDFViewer
                src={values.version}
                onReadFinished={() =>
                  !values.areTermsRead
                    ? setFieldValue("areTermsRead", true)
                    : null
                }
              />
              <Grid item sm={12} xs={12} className={classNames(classes.radio)}>
                <Checkbox
                  className={blockHandleChange ? "disabled" : ""}
                  value={values.isTermsChecked}
                  handleChange={handleCheckboxChange}
                  label={translateKey("form.serviceModel.accept")}
                  name="isTermsChecked"
                />
                <Checkbox
                  className={blockHandleChange ? "disabled" : ""}
                  value={values.isDataChecked}
                  handleChange={handleCheckboxChange}
                  label={
                    translateKey("form.serviceModel.acceptData") +
                    rappiSocialReason
                  }
                  name="isDataChecked"
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

ServiceModelForm.defaultProps = {
  additionalFields: [],
};

ServiceModelForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  deliveryTypes: PropTypes.array.isRequired,
  additionalFields: PropTypes.array,
  termsVersionPerDeliveryType: PropTypes.object.isRequired,
  rappiSocialReason: PropTypes.string.isRequired,
  notifier: PropTypes.object.isRequired,
};

export default withStyles(styles)(ServiceModelForm);
