import baseStyleInjector from "../../../assets/styles/FormBase.style";

const stylizer = theme => ({
  ...baseStyleInjector(theme),
  radio: {
    fontFamily: theme.typography.fontFamily.regular,
    "& .MuiIconButton-root": {
      color: theme.palette.secondary.main,
    },
    paddingTop: 20,
  },
  alertContainer: {
    padding: "4px 24px 4px 24px",
  },
  noWrap: {
    "& .MuiSelect-root": {
      whiteSpace: "normal",
    },
  },
});

export default stylizer;
