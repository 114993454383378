import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  theme => ({
    tableImageWrapper: {
      marginTop: 56,
      position: "relative",
      left: "-57.5%",
      [theme.breakpoints.up("lg")]: {
        width: "140%",
        left: "-57.5%",
        top: "-10%",
      },
      [theme.breakpoints.down("lg")]: {
        width: "150%",
        top: 0,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        left: "-35%",

        marginTop: 0,
      },
      [theme.breakpoints.down("xs")]: {
        width: "85%",
        left: "-45%",
      },
    },
    highlightPerk: {
      marginBottom: 30,
    },
    perksHeader: {
      marginBottom: 60,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 25,
      },
    },
    perkCardPair: {
      minHeight: 260,
      "&:first-child": {
        marginBottom: 30,
      },
      "& > :first-child": {
        marginRight: 40,
      },
      "& div": {
        height: "100%",
      },
    },
  }),
  { name: "Perks", index: 1 },
);

export default useStyles;
