import { rfcRegexFormatter } from "./validators";
import { onlyDigits, limitLengthOnlyDigits } from "../utils/constraintBuilders";
import { idLengths } from "./schemas";

const { CO, AR, BR, PE, EC, CR, UY } = idLengths;

const idConstraints = {
  CO: {
    cc: onlyDigits,
    ce: onlyDigits,
    nit: value => limitLengthOnlyDigits(value, CO.nit.max),
  },
  BR: {
    cpf: value => limitLengthOnlyDigits(value, BR.cpf.max),
    cnpj: value => limitLengthOnlyDigits(value, BR.cnpj.max),
  },
  MX: {
    "ife/ine": onlyDigits,
    ps: null,
    rfc: value => {
      const upper = value.toUpperCase();
      return rfcRegexFormatter.test(upper) ? upper : upper.slice(0, -1);
    },
  },
  AR: {
    dni: value => limitLengthOnlyDigits(value, AR.dni.max),
    "dni extranjero": null,
    ps: null,
    cuit: value => limitLengthOnlyDigits(value, AR.cuit.max),
    cuil: value => limitLengthOnlyDigits(value, AR.cuil.max),
  },
  PE: {
    dni: value => limitLengthOnlyDigits(value, PE.dni.max),
    ce: value => limitLengthOnlyDigits(value, PE.ce.max),
    ps: null,
  },
  UY: {
    ci: value => limitLengthOnlyDigits(value, UY.ci.max),
    rut: value => limitLengthOnlyDigits(value, UY.rut.max),
    ps: null,
  },
  CL: {
    ci: null,
    ps: null,
  },
  EC: {
    cedula: value => limitLengthOnlyDigits(value, EC.cedula.max),
    ruc: value => limitLengthOnlyDigits(value, EC.ruc.max),
    id: null,
    ps: null,
  },
  CR: {
    cedula: value => limitLengthOnlyDigits(value, CR.cedula.max),
    ruc: value => limitLengthOnlyDigits(value, CR.ruc.max),
    dni: null,
    ps: null,
  },
};

export default idConstraints;
