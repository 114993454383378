import baseStyleInjector from "../../../assets/styles/FormBase.style";

const stylizer = theme => ({
  ...baseStyleInjector(theme),
  root: {
    display: "block !important",
    flexGrow: 1,
    minHeight: "100vh",
    position: "relative",
  },
  content: {
    width: "100%",
    margin: "6vh 0",
    padding: "0 20vw",
    [theme.breakpoints.down("md")]: {
      padding: "0 2vw",
    },
  },
  stepperContainer: {
    "& .MuiStepper-horizontal": {
      display: "flex",
      "& .MuiStep-horizontal": {
        display: "flex",
        justifyContent: "center",
        padding: 0,
        "& .MuiStepLabel-iconContainer": {
          paddingRight: 0,
        },
      },
    },
    "& .MuiStepIcon-root": {
      width: "1em",
      height: "1em",
      fontSize: 35,
      padding: 0,
      color: "#b8b4b4",
    },
    "& .MuiStepIcon-root > text": {
      fill: "white",
    },
    "& .MuiStepIcon-active, & .MuiStepIcon-completed": {
      border: "none",
      padding: "inherit",
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: theme.palette.primary.main,
    },
    "& .MuiStepConnector-root": {
      width: "90%",
      left: "calc(-45%);",
    },
    "&  .MuiStepConnector-completed > .MuiStepConnector-line": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    "&  .MuiStepConnector-active > .MuiStepConnector-line": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    "& .MuiStepConnector-lineHorizontal": {
      borderTopStyle: "solid",
      borderTopWidth: 4,
      borderColor: "#b8b4b4",
      marginTop: 2,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    marginBottom: 90,
  },
  stepLabel: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily.bold,
    color: theme.typography.palette.main,
    zIndex: 5,
  },
  uncompleteStepLabel: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily.regular,
    color: theme.typography.palette.subtitle,
    zIndex: 5,
  },
  formWrapper: {
    flexGrow: 1,
  },
  stepper: {
    background: "none",
    padding: "8px 24px",
  },
  stepsContainer: {
    alignItems: "center",
    padding: "60px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  step: {
    width: "100%",
    borderRadius: 8,
    display: "flex",
    minHeight: 150,
    flexDirection: "column",
    textAlign: "center",
    border: `solid 1px ${theme.palette.disabled.main}`,
    justifyContent: "center",
  },
  stepText: {
    fontSize: 15,
    fontFamily: theme.typography.fontFamily.regular,
    color: theme.typography.palette.main,
    padding: "0 20px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  arrow: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonContainer: {
    width: "100%",
    "& button": {
      width: "100%",
    },
  },
  clickableStep: {
    cursor: "pointer !important",
    "& > span > svg": {
      color: `${theme.palette.secondary.main} !important`,
      "& > text": {
        fill: "#ffffff",
      },
    },
  },
  stepNumber: {
    display: "block",
    fontSize: "18px",
    marginBottom: 10,
    fontFamily: theme.typography.fontFamily.bold,
    color: theme.palette.secondary.main,
  },
  welcome: {
    margin: "10px 0",
    textAlign: "center",
    fontWeight: 100,
    fontSize: 18,
    fontFamily: theme.typography.fontFamily.regular,
    color: theme.typography.palette.main,
  },
  process: {
    textAlign: "center",
    margin: 0,
    fontSize: 24,
    fontFamily: theme.typography.fontFamily.bold,
    color: theme.typography.palette.main,
  },
  buttonSection: {
    padding: "0 24px",
  },
  divider: {
    width: "100%",
    margin: "60px 0",
  },
  video: {
    "& > div > video": {
      borderRadius: 17,
    },
  },
  videoDescription: {
    textAlign: "center",
    marginBottom: 40,
  },
});

export default stylizer;
