import CustomHttpException, {
  CustomizedError,
} from "../../../../utils/CustomHttpException";
import { verifyEntities } from "../../validateEntities";
import {
  identificationNumberFailedResponse,
  identificationNumberOKResponse,
  OCR_ATTEMPTS_NATIONAL_ID,
  resetOCRStatus,
  throwIdentificationNumberFailedException,
  updateOCRStatus,
} from "../ocrNationalDocumentStatus";

const isValidResponse = response => response && response.data;

const isValidTheFrontDocument = response =>
  response.data.frontTitleAlternativeDocument1 === 1 ||
  response.data.frontTitleAlternativeDocument2 === 1 ||
  response.data.frontTitleAlternativeDocument3 === 1 ||
  response.data.frontTitleAlternativeDocument4 === 1 ||
  response.data.frontTitleAlternativeDocument5 === 1;

const isValidTheBackDocument = response =>
  response.data.backTitleAlternativeDocument1 === 1 ||
  response.data.backTitleAlternativeDocument2 === 1 ||
  response.data.backTitleAlternativeDocument3 === 1 ||
  response.data.backTitleAlternativeDocument4 === 1 ||
  response.data.backTitleAlternativeDocument5 === 1 ||
  response.data.backTitleAlternativeDocument6 === 1;

const isValidIdentificationNumber = response =>
  response.data.identification === 1;

const handleResponse = (request, response) => {
  if (!isValidResponse(response)) {
    throw new CustomHttpException({
      customError: CustomizedError.OCR_NATIONAL_DOCUMENT_FAILED,
      responseBody: response,
    });
  }

  if (!isValidTheFrontDocument(response)) {
    throw new CustomHttpException({
      customError: CustomizedError.OCR_FRONT_DOCUMENT_FAILED,
      responseBody: response,
    });
  }

  if (!isValidTheBackDocument(response)) {
    throw new CustomHttpException({
      customError: CustomizedError.OCR_BACK_DOCUMENT_FAILED,
      responseBody: response,
    });
  }

  updateOCRStatus();
  if (!isValidIdentificationNumber(response)) {
    if (!OCR_ATTEMPTS_NATIONAL_ID.isReachedTheMaxAttempts()) {
      throwIdentificationNumberFailedException(response);
    } else {
      return identificationNumberFailedResponse(request);
    }
  }
  resetOCRStatus();
  return identificationNumberOKResponse(request);
};

export const toPEDNIEntities = identificationNumber => ({
  frontTitleAlternativeDocument1: "Republica",
  frontTitleAlternativeDocument2: "Documento Nacional",
  frontTitleAlternativeDocument3: "DNI",
  frontTitleAlternativeDocument4: "Registro Nacional",
  frontTitleAlternativeDocument5: "Calidad Migratoria",
  backTitleAlternativeDocument1: "Departamento",
  backTitleAlternativeDocument2: "Provincia",
  backTitleAlternativeDocument3: "Distrito",
  backTitleAlternativeDocument4: "Direccion",
  backTitleAlternativeDocument5: "Grupo de Votacion",
  backTitleAlternativeDocument6: "Emision",
  identification: identificationNumber,
});

export const verifyPeruNationalDocument = (body, tokenValue) =>
  verifyEntities(body, tokenValue).then(response =>
    handleResponse(body, response),
  );
