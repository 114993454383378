import { useCallback, useMemo } from "react";

const getFieldConstraints = (inputConstraints, fieldBindings = {}) => {
  const fieldConstraints = Object.entries(fieldBindings).reduce(
    (constraints, [field, binding]) => ({
      ...constraints,
      [field]: (value, { [binding]: bindingValue }) =>
        inputConstraints[bindingValue]
          ? inputConstraints[bindingValue](value)
          : value,
    }),
    {},
  );

  return { ...inputConstraints, ...fieldConstraints };
};

const useFieldConstraints = (handleChange, constraints, fieldBindings) => {
  const fieldConstraints = useMemo(
    () => getFieldConstraints(constraints, fieldBindings),
    [constraints, fieldBindings],
  );

  const onChange = useCallback(
    (evt, values) => {
      const constraint = fieldConstraints[evt.target.name];
      const { value } = evt.target;
      // eslint-disable-next-line no-param-reassign
      evt.target.value = constraint ? constraint(value, values) : value;
      handleChange(evt);
    },
    [fieldConstraints, handleChange],
  );

  return { fieldConstraints, onChange };
};

export { useFieldConstraints };
export default getFieldConstraints;
