import React, { useMemo } from "react";
import { Grid, Typography, useMediaQuery } from "@material-ui/core/";
import PropTypes from "prop-types";
import classNames from "classnames";
import { GridHalfAtMd } from "../../../../../utils/Grids";
import { translateKeyWithHtml } from "../../../../../utils/translate";
import AppRestPlates from "../../../../../assets/images/InboundPage/rest-plates.png";
import useStyles from "./BrandGrowth.style";

const BrandGrowth = ({ pageClasses }) => {
  const classes = useStyles();
  const isBelowSm = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const growthSectionItems = useMemo(
    () => [
      <GridHalfAtMd item md={6} lg={6} xl={6} key={0}>
        <div className={pageClasses.overflowVisible}>
          <div
            className={
              (pageClasses.backgroundCircleWrapper,
              pageClasses.backgroundCircleWrapperHalf)
            }
          >
            <div
              className={classNames(
                pageClasses.aspectRatio_1x1,
                pageClasses.backgroundCircle,
                pageClasses.circleLeft,
              )}
            />
          </div>
          <img src={AppRestPlates} alt="" className={classes.appRestPhone} />
        </div>
      </GridHalfAtMd>,
      <GridHalfAtMd
        item
        md={6}
        lg={6}
        xl={6}
        className={pageClasses.elevated1}
        key={1}
      >
        <div
          className={classNames(
            pageClasses.flexCentering,
            pageClasses.fullHeight,
          )}
        >
          <div>
            <Typography
              variant="h3"
              className={classNames(
                pageClasses.sub2,
                pageClasses.headerBottomMargin,
                pageClasses.strongMain,
              )}
            >
              {translateKeyWithHtml("inboundPage.brandGrowth.title")}
            </Typography>
            <Typography paragraph>
              {translateKeyWithHtml(
                "inboundPage.brandGrowth.textContent.paragraph_1",
              )}
              {"."}
              <br />
              <br />
              {translateKeyWithHtml(
                "inboundPage.brandGrowth.textContent.paragraph_2",
              )}
              {"."}
              <br />
              <br />
              {translateKeyWithHtml(
                "inboundPage.brandGrowth.textContent.paragraph_3",
              )}
              {"."}
            </Typography>
          </div>
        </div>
      </GridHalfAtMd>,
    ],
    [classes, pageClasses],
  );

  return (
    <Grid container spacing={0}>
      {isBelowSm ? [...growthSectionItems].reverse() : growthSectionItems}
    </Grid>
  );
};

BrandGrowth.propTypes = {
  pageClasses: PropTypes.object.isRequired,
};

export default BrandGrowth;
